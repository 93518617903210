import React, { isValidElement, cloneElement } from "react";

import { makeStyles } from "@material-ui/core/styles";
import theme from "theme";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  statCard: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap"
  },
  statList: {
    listDisplayType: "none",
    padding: 0,
    margin: 16,
    marginBottom: 0,
    borderBottom: theme.buttonStyles.borderDisabled
  },
  statItem: {
    display: "flex",
    paddingTop: 12,
    paddingBottom: 12,
    borderTop: theme.buttonStyles.borderDisabled
  },
  statLabel: {
    fontWeight: 400
  },
  statValue: {
    marginLeft: "auto",
    color: theme.palette.text.light
  },
  statActions: {
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 24
  }
});

export const StatList = ({ children }) => {
  const classes = useStyles();
  return <ul className={classes.statList}>{children}</ul>;
};

export const StatListItem = ({ StatLabel, text, label, loading }) => {
  const classes = useStyles();
  const CustomLabel = isValidElement(StatLabel) && cloneElement(StatLabel);

  return (
    <li className={classes.statItem}>
      {CustomLabel || <b className={classes.statLabel}>{label}</b>}
      {loading && (
        <Skeleton
          style={{ marginLeft: "auto" }}
          variant="rect"
          width={80}
          height={16}
        />
      )}
      {!loading && <span className={classes.statValue}>{text}</span>}
    </li>
  );
};

export const StatsBlock = ({ Actions, children, title }) => {
  const classes = useStyles();
  return (
    <Card className={classes.statCard}>
      <CardContent>
        {title && (
          <Typography gutterBottom component="h2">
            {title}
          </Typography>
        )}
        <StatList>{children}</StatList>
      </CardContent>
      {React.isValidElement(Actions) && (
        <CardActions className={classes.statActions}>
          {React.cloneElement(Actions)}
        </CardActions>
      )}
    </Card>
  );
};

export default StatsBlock;
