import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from "react-admin";
import { GET_CURRENT_USER } from "hooks/useCurrentUser";
import { getPermissions } from "resources/users/roles";
import gql from "graphql-tag";
import client from "apolloClient";
import _get from "lodash.get";

export const CHECK_CURRENT_USER = gql`
  query CheckCurrentUser {
    user: loggedInUserQuery {
      id
      role
      email
    }
  }
`;

export const LOGIN_REQUEST_MUTATION = gql`
  mutation LoginAdmin($email: String, $password: String) {
    loginAdmin(email: $email, password: $password) {
      id
      jwt
      role
      organizationId
    }
  }
`;

/**
 * Makes a loginAdmin mutation request to the API.
 *
 * @param {String} email Login email address.
 * @param {String} password Login password.
 * @returns {Promise} API result object with id, email, jwt and validationSummary.
 */
export const loginRequest = (email, password) => {
  try {
    return client.mutate({
      mutation: LOGIN_REQUEST_MUTATION,
      variables: {
        email,
        password
      }
    });
  } catch (err) {
    throw new Error(err);
  }
};

/**
 * Authenticates the provided email and password.
 * A successful check will return an empty resolved Promise and set a JWT that is
 * then sent with every addtional network request. (see dataProvider.js)
 * A failed check will return a rejected Promise containing an error message string.
 *
 * @param {*} params Object containing the input username and password.
 * @param {String} password Login password.
 * @returns {Promise} Empty on resolved or error message on reject.
 */
export const login = async params => {
  const { username, password } = params;
  const {
    data: {
      loginAdmin: { jwt, role }
    }
  } = await loginRequest(username, password);

  if (!jwt || !role || role === "user") {
    return Promise.reject("auth.invalid");
  }

  localStorage.setItem("token", jwt);
  localStorage.setItem("role", role);

  try {
    await client.query({
      query: GET_CURRENT_USER,
      fetchPolicy: "network-only"
    });
  } catch (error) {
    return Promise.reject("auth.invalid");
  }

  return Promise.resolve(true);
};

/**
 * Logs the user out and returns a promise containing
 * a logout message response based on the type.
 *
 * @param {String} Auth type contstant from  React-Admin.
 */
export const logout = type => {
  localStorage.removeItem("token");
  localStorage.removeItem("role");
  client.clearStore();

  if (type === AUTH_ERROR) {
    return Promise.reject("auth.denied");
  }
  return Promise.resolve("auth.logout");
};

export const authCheck = async () => {
  if (!localStorage.getItem("token")) {
    return Promise.reject(false);
  }

  try {
    const data = await client.query({
      query: CHECK_CURRENT_USER,
      fetchPolicy: "network-only"
    });
    const id = _get(data, "data.user.id", -1);

    return id > 0 ? Promise.resolve(true) : Promise.reject(false);
  } catch (error) {
    return Promise.reject(false);
  }
};

/**
 * Authenticattion provider expected by React-Admin.
 */
export default (type, params) => {
  if (type === AUTH_LOGIN) {
    return login(params);
  }

  if (type === AUTH_LOGOUT) {
    return logout();
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      return logout(AUTH_ERROR);
    }
    return Promise.resolve(true);
  }

  if (type === AUTH_CHECK) {
    return authCheck();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const roleName = localStorage.getItem("role");
    const permissions = getPermissions(roleName);
    return roleName ? Promise.resolve(permissions) : Promise.reject([]);
  }

  return Promise.reject("auth.unkown");
};
