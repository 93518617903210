import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

import { StyledSystemMessage as SystemMessage } from "systemMessage/SystemMessage";

import useTranslate from "hooks/useTranslate";
import useFormStyles from "hooks/useFormStyles";

const useStyles = makeStyles(theme => ({
  actionsBox: {
    justifyContent: "center",
    padding: 12,

    "& button": {
      minWidth: 145,
      margin: "0 8px"
    }
  }
}));

export const DeleteButton = ({ onDelete, ...props }) => {
  const [confirm, setConfirm] = useState(false);
  const { dangerText } = useFormStyles();
  const styles = useStyles();
  const classes = { ...styles, dangerText };
  const onClose = () => setConfirm(false);
  const { translate } = useTranslate();

  return (
    <>
      <Button
        variant="text"
        className={classes.dangerText}
        onClick={() => setConfirm(true)}
        {...props}
      >
        {translate("ra.action.delete")}
      </Button>
      <SystemMessage
        variant="caution"
        disableBackdropClick={false}
        title={"resources.organizations.confirm_delete_org"}
        open={confirm}
        onClose={onClose}
      >
        <DialogActions className={classes.actionsBox}>
          <Button
            className={classes.cautionBtn}
            onClick={onDelete}
            variant="outlined"
            color="inherit"
          >
            {translate("ra.action.confirm")}
          </Button>
          <Button
            className={classes.cautionBtn}
            onClick={onClose}
            variant="outlined"
            color="inherit"
          >
            {translate("ra.action.cancel")}
          </Button>
        </DialogActions>
      </SystemMessage>
    </>
  );
};

export default DeleteButton;
