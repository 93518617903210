import React from "react";
import _get from "lodash.get";

import BulkActionButton from "ui/buttons/BulkActionButton";
import ConfirmButton from "ui/buttons/ConfirmButton";

import useBulkAction from "hooks/useBulkAction";
import useTranslate from "hooks/useTranslate";

export const BulkUpdate = ({
  confirmMsg,
  Dialog,
  Icon,
  label,
  mutation,
  message,
  selectedRows,
  refetch,
  successMsg,
  disabled
}) => {
  const { translate } = useTranslate();
  const [bulkUpdate] = useBulkAction({
    refetch,
    successMsg: successMsg ? translate(successMsg) : undefined
  });
  const ids = selectedRows.map(({ id }) => id);
  const count = selectedRows.length;
  const btnLabel = translate(label, {
    smart_count: count
  });

  return (
    <ConfirmButton
      Button={
        <BulkActionButton
          Icon={Icon}
          title={`${btnLabel} (${count})`}
          disabled={!count || disabled}
        />
      }
      confirmLabel={btnLabel}
      Dialog={Dialog}
      onConfirm={props => {
        bulkUpdate({
          selectedIds: ids,
          mutation,
          variables: _get(props, "variables"),
          mergeData: _get(props, "mergeData")
        });
      }}
      message={message}
      title={
        confirmMsg
          ? translate(confirmMsg, {
              smart_count: count
            })
          : undefined
      }
    />
  );
};

BulkUpdate.defaultProps = {
  selectedRows: []
};

export default BulkUpdate;
