import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import InfoIcon from "./icons/InfoIcon";
import CheckMarkIcon from "./icons/CheckMarkIcon";
import CautionIcon from "./icons/CautionIcon";
import WarningIcon from "./icons/WarningIcon";
import FlagIcon from "./icons/FlagIcon";

const typeIcon = {
  success: CheckMarkIcon,
  caution: CautionIcon,
  info: InfoIcon,
  warning: WarningIcon
};

export const NotificationIcon = ({ className, classes, type }) => {
  const Icon = typeIcon[type] || FlagIcon;

  return (
    <span
      className={classnames(classes.wrap, classes[`${type}Wrap`], className)}
    >
      <Icon className={classnames(classes.icon, classes[`${type}Icon`])} />
    </span>
  );
};

NotificationIcon.defaultProps = {
  classes: {
    wrap: "wrap",
    infoWrap: "infoWrap",
    successWrap: "successWrap",
    cautionWrap: "cautionWrap",
    warningWrap: "warningWrap",
    icon: "icon",
    infoIcon: "infoIcon",
    successIcon: "successIcon",
    cautionIcon: "cautionIcon",
    warningIcon: "warningIcon"
  }
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  wrap: {
    position: "relative",
    display: "block",
    width: "1.5em",
    height: "1.5em",
    minWidth: 20,

    "&::after": {
      content: '""',
      position: "absolute",
      zIndex: 1,
      top: "-.4rem",
      bottom: "-.4rem",
      left: "-.4rem",
      right: "-.4rem",
      borderRadius: "50%",
      backgroundColor: theme.buttonStyles.disabled
    }
  },
  infoWrap: {
    "&::after": {
      backgroundColor: theme.palette.info.main
    }
  },
  successWrap: {
    "&::after": {
      backgroundColor: theme.palette.success.main
    }
  },
  cautionWrap: {
    "&::after": {
      backgroundColor: theme.palette.caution.main
    }
  },
  warningWrap: {
    "&::after": {
      backgroundColor: theme.palette.error.main
    }
  },
  icon: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    fill: theme.palette.primary.light,
    color: theme.palette.common.white,
    zIndex: 2
  },
  infoIcon: {
    fill: theme.palette.info.border
  },
  successIcon: {
    fill: theme.palette.success.border
  },
  cautionIcon: {
    fill: theme.palette.caution.border
  },
  warningIcon: {
    fill: theme.palette.error.border
  }
});

export default withStyles(styles, { name: "NotificationIcon" })(
  NotificationIcon
);
