import React from "react";
import { withStyles } from "@material-ui/core/styles";

export const Divider = ({ classes }) => <hr className={classes.root} />;

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  root: {
    marginTop: 8 * 3,
    marginBottom: 8 * 3,
    border: theme.buttonStyles.borderDisabled,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0
  }
});

export default withStyles(styles, { name: "Divider" })(Divider);
