import React from "react";
import compose from "recompose/compose";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { translate } from "react-admin";

export const PageHeader = ({
  classes,
  deleteAction,
  editAction,
  extraAction,
  title,
  translate,
  translateTitle
}) => (
  <div className={classes.root}>
    <Typography variant="h1" component="h1" className={classes.pageTitle}>
      {(translateTitle && translate(title)) || title}
    </Typography>
    {editAction && (
      <Button
        variant="outlined"
        className={classes.editButton}
        onClick={editAction}
      >
        {translate("ra.action.edit")}
      </Button>
    )}
    {deleteAction && (
      <Button
        variant="text"
        className={classes.deleteButton}
        onClick={deleteAction}
      >
        {translate("ra.action.delete")}
      </Button>
    )}
    {extraAction && React.cloneElement(extraAction)}
  </div>
);

PageHeader.defaultProps = {
  translateTitle: true
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 8 * 2.5,
    borderBottom: theme.buttonStyles.border,
    marginBottom: 8 * 3
  },
  pageTitle: {
    marginRight: 8 * 2
  },
  editButton: {
    padding: 0,
    minWidth: 8 * 10,
    minHeight: 8 * 3,
    marginRight: 8 * 2
  },
  deleteButton: {
    marginLeft: 8 * 0.5,
    textDecoration: "underline",
    color: theme.palette.error.dark,
    "&:hover": {
      color: theme.palette.error.dark,
      textDecoration: "none"
    }
  }
});

const enhance = compose(withStyles(styles, { name: "PageHeader" }), translate);

export default enhance(PageHeader);
