import React from "react";
import gql from "graphql-tag";

import DownloadCSVButton from "ui/buttons/DownloadCSVButton";
import useTranslate from "hooks/useTranslate";

export const EXPORT_GROUPS = gql`
  query {
    allGroups(page: 1, perPage: 100000) {
      name
      usersCount
      lessonsCompletedAverage
    }
  }
`;

export const ExportGroupsButton = () => {
  const { translate } = useTranslate();

  return (
    <DownloadCSVButton
      query={EXPORT_GROUPS}
      filename={"groups-export"}
      name={"groups"}
      fields={{
        name: {
          label: translate(`fields.name`)
        },
        usersCount: {
          label: translate(`fields.usersCount`)
        },
        lessonsCompletedAverage: {
          label: translate(`fields.lessonsCompletedAverage`)
        }
      }}
    />
  );
};

export default ExportGroupsButton;
