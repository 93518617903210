import buildGraphQLProvider, {
  buildQuery as raSimpleQuery
} from "ra-data-graphql-simple";
import ApolloClient from "apolloClient";
import { resourceTypeName } from "resources/types";
import { customDataProivderQueries } from "queries";

export const introspectionOptions = {
  include: ["User", "Organization", "Group"]
};

export const offsetPagination = ({ pagination, ...params }) => {
  if (pagination) {
    return {
      ...params,
      pagination: { ...pagination, page: pagination.page + 1 }
    };
  }

  return params;
};

export const customQueryBuilder = introspection => (
  fetchType,
  resource,
  params
) => {
  params = offsetPagination(params);

  // Return the first valid custom query.
  // Custom queries by-pass the introspection.
  const customQuery = customDataProivderQueries.reduce(
    (query, queryBuilder) => query || queryBuilder(fetchType, resource, params),
    false
  );

  return (
    customQuery ||
    raSimpleQuery(introspection)(fetchType, resourceTypeName(resource), params)
  );
};

export const dataProviderClient = async () => {
  try {
    return await buildGraphQLProvider({
      introspection: introspectionOptions,
      buildQuery: customQueryBuilder,
      client: ApolloClient
    });
  } catch (err) {
    throw new Error(err);
  }
};

export default async () => {
  const client = await dataProviderClient();
  return client;
};
