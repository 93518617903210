import { useState } from "react";

const useModalOpen = active => {
  const [isOpen, setOpen] = useState(active);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  return {
    isOpen,
    closeModal,
    openModal
  };
};

export default useModalOpen;
