import React from "react";
import { Route } from "react-router-dom";
import _get from "lodash.get";

import BulkImport from "bulkImport/BulkImport";
import Logbook from "logbook/Logbook";

import ContractsList from "contracts/ContractsList";
import ContractCreate from "contracts/ContractCreate";
import ContractEdit from "contracts/ContractEdit";

import Insights from "insights/Insights";
import LessonRatings from "lessonRatings/LessonRatings";
import OrganizationDashboard from "organizations/OrganizationDashboard";

import NotFound from "ui/NotFound";

import usePermissions from "hooks/usePermissions";

export const PrivateRoute = ({ auth, ...props }) => {
  const { loading, ...permissions } = usePermissions();

  if (loading) {
    return null;
  }

  const allowed = _get(permissions, auth, false);

  return allowed ? <Route {...props} /> : <NotFound />;
};

export const ssoRedirect = async ({ location: { search } }) => {
  await new Promise(resolve => setTimeout(resolve, 500));
  document.location.assign(search.replace(/^\?/, ""));
  return null;
};

export default [
  <PrivateRoute
    auth={"canCreateUsers"}
    exact
    path="/users/bulk-import"
    component={BulkImport}
  />,
  <Route exact path="/dashboard" component={OrganizationDashboard} />,
  <Route exact path="/logbook" component={Logbook} />,
  <Route exact path="/insights" component={Insights} />,
  <Route exact path="/insights/videos/:videoId" component={Insights} />,
  <PrivateRoute
    auth={"canCreateOrgs"}
    exact
    path="/organizations/:organizationId/contracts/create"
    component={ContractCreate}
  />,
  <PrivateRoute
    auth={"canViewOrgs"}
    exact
    path="/contracts"
    component={ContractsList}
  />,
  <PrivateRoute
    auth={"canCreateOrgs"}
    exact
    path="/contracts/create"
    component={ContractCreate}
  />,
  <PrivateRoute
    auth={"canViewOrgs"}
    exact
    path="/contracts/:contractId"
    component={ContractEdit}
  />,
  <PrivateRoute
    auth={"canViewLessonRatings"}
    exact
    path="/lesson-ratings"
    component={LessonRatings}
  />,
  <Route exact path="/sso-auth" component={ssoRedirect} />
];
