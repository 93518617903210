import { useEffect, useState } from "react";
import _get from "lodash.get";

import useHistory from "hooks/useHistory";

const usePersistentFilters = ({ filter, setFilter }) => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      return;
    }

    const currentSearch = _get(history, "location.search");
    const searchParams = new URLSearchParams(currentSearch);
    const defaultFilter = JSON.parse(searchParams.get("filter"));

    if (!!defaultFilter) {
      setFilter(defaultFilter);
    }

    setLoaded(true);
  }, [setFilter, loaded, setLoaded]);

  useEffect(() => {
    if (typeof window === "undefined" || !loaded) {
      return;
    }

    const currentSearch = _get(history, "location.search");
    const push = _get(history, "push", () => undefined);

    let searchParams = new URLSearchParams(currentSearch);

    if (Object.keys(filter).length) {
      searchParams.set("filter", JSON.stringify(filter));
    } else {
      searchParams.delete("filter");
    }

    const search = searchParams.toString();

    if (search !== currentSearch) {
      push({
        search
      });
    }
  }, [filter, loaded]);
};

export default usePersistentFilters;
