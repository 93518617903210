import React from "react";
import { useFormContext } from "react-hook-form";

import TextField from "@material-ui/core/TextField";

import useFormValidation from "hooks/useFormValidation";

const TextInput = ({ name, validate, ...props }) => {
  const { errors, register } = useFormContext();
  const { fieldErrors } = useFormValidation(errors);

  const { required } = validate || {};

  return (
    <TextField
      name={name}
      inputRef={register(validate)}
      {...fieldErrors(name)}
      required={!!required}
      {...props}
    />
  );
};

export default TextInput;
