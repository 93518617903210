import React from "react";
import useTranslate from "hooks/useTranslate";

const OrgTypeField = ({ organizationType, short }) => {
  const { translate } = useTranslate();
  const type = organizationType || null;

  return (
    <>
      {translate(
        `resources.organizations.types.${type}${(short && "_short") || ""}`
      )}
    </>
  );
};

OrgTypeField.defaultProps = {
  short: true
};

export default OrgTypeField;
