import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import HotTub from "@material-ui/icons/HotTub";

import useTranslate from "hooks/useTranslate";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%"
  },
  icon: {
    width: "9em",
    height: "9em"
  },
  message: {
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    opacity: 0.5,
    margin: "0 1em"
  },
  toolbar: {
    textAlign: "center",
    marginTop: "2em"
  }
});

/* istanbul ignore next default browser behavior. */
const goBack = () => {
  typeof window !== "undefined" && window.history.go(-1);
};

const NotFound = () => {
  const classes = useStyles();
  const { translate } = useTranslate();

  return (
    <div className={classes.container}>
      <div className={classes.message}>
        <HotTub className={classes.icon} />
        <h1>{translate("ra.page.not_found")}</h1>
        <div>{translate("ra.message.not_found")}.</div>
      </div>
      <div className={classes.toolbar}>
        <Button variant="contained" onClick={goBack}>
          {translate("ra.action.back")}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
