import React, { useEffect } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

import { StyledSystemMessage as SystemMessage } from "systemMessage/SystemMessage";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import SendIcon from "@material-ui/icons/Send";

import { withStyles } from "@material-ui/core/styles";
import useTranslate from "hooks/useTranslate";
import useModalOpen from "hooks/useModalOpen";

import { showNotification as showNotificationAction } from "react-admin";
import withActiveOrg from "ui/withActiveOrg";

export const COUNT_ALL_USERS = gql`
  query CountAllUsers($filter: Filter) {
    users: _allUsersMeta(filter: $filter) {
      count
      __typename
    }
  }
`;

export const INVITE_ALL_USERS = gql`
  mutation InviteAllUsers($organizationId: Int!) {
    inviteAllUsers(organizationId: $organizationId) {
      jobId
      status
    }
  }
`;

export const InviteAllSystemMessage = ({ open, activeOrgId, ...props }) => {
  const { translate } = useTranslate();
  const [getUsersCount, { loading, data }] = useLazyQuery(COUNT_ALL_USERS, {
    variables: {
      activeOrgId, // This is needed to refetch queries on org change.
      filter: {
        status: "invitable"
      }
    }
  });

  useEffect(() => {
    open && getUsersCount();
  }, [getUsersCount, open]);

  return (
    <SystemMessage
      {...props}
      open={open && !loading}
      useTranslate={false}
      title={translate("ui.inviteAllConfirmTitle", {
        count: (data && data.users.count) || ""
      })}
      message={"ui.inviteAllConfirmMsg"}
      variant={"caution"}
    />
  );
};

InviteAllSystemMessage.defaultProps = {
  open: false
};

export const InviteAllUsersBtn = ({
  activeOrgId,
  classes,
  className,
  showNotificationAction: showNotification
}) => {
  const { isOpen, closeModal, openModal } = useModalOpen();
  const { translate } = useTranslate();

  const [inviteAllUsers, { loading }] = useMutation(INVITE_ALL_USERS, {
    onCompleted: () => showNotification("ui.inviteAllSuccess", "success"),
    onError: e => showNotification(`${e}`, "warning"),
    variables: {
      organizationId: activeOrgId
    }
  });

  return (
    <>
      <Button
        data-track-id="user-invite-all"
        className={className}
        variant={"outlined"}
        disabled={loading}
        onClick={openModal}
      >
        {!loading && (
          <>
            <SendIcon className={classes.icon} />
            {translate("ui.inviteAll")}
          </>
        )}
        {loading && <CircularProgress className={classes.icon} size={20} />}
      </Button>
      <InviteAllSystemMessage
        data-test="system-message"
        activeOrgId={activeOrgId}
        open={isOpen}
        onClose={closeModal}
        actions={[
          {
            autoClose: false,
            onClick: () => {
              closeModal();
              return inviteAllUsers(); // needs to be returned for testing.
            },
            label: "ra.boolean.true"
          },
          {
            autoClose: false,
            onClick: closeModal,
            label: "ra.boolean.false"
          }
        ]}
      />
    </>
  );
};

InviteAllUsersBtn.defaultProps = {
  classes: {
    icon: "icon"
  }
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  icon: {
    color: theme.palette.secondary.main,
    width: 8 * 2.5,
    marginRight: 8 * 0.5
  }
});

const enhance = compose(
  connect(null, {
    showNotificationAction
  }),
  withActiveOrg,
  withStyles(styles, { name: "InviteAllUsersBtn" })
);

export default enhance(InviteAllUsersBtn);
