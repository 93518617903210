import React from "react";

import { theme, legacyTheme } from "hooks/useStyles";
import logo from "assets/TED-Masterclass-logo.svg";

import {
  ThemeProvider,
  createStyles,
  makeStyles
} from "@material-ui/core/styles";
import { MuiThemeProvider } from "@ra-shim/material-ui/core";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import CssBaseline from "@material-ui/core/CssBaseline";

import { Notification } from "ui";
import BreadcrumbNav from "ui/BreadcrumbNav";
import UserAccountMenu from "ui/UserAccountMenu";
import { Menu } from "./Menu";
import SingeOrgMessage from "ui/SingeOrgMessage";
import SystemMessage from "systemMessage/SystemMessage";

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      display: "flex",
      zIndex: 1,
      minHeight: "calc(100vh - 64px)",
      backgroundColor: palette.background.default,
      position: "relative",
      minWidth: 960,
      width: "100%"
    },
    logo: {
      width: 170
    },
    userAccount: {
      marginLeft: "auto",
      color: "#fff"
    },
    drawer: {
      position: "fixed",
      top: 64,
      left: 0,
      bottom: 0,
      height: "calc(100vh - 64px)",
      width: 200,
      flexShrink: 0,
      backgroundColor: palette.background.default,
      borderRight: `1px solid ${palette.divider}`,
      zIndex: 20
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "12px 40px",
      marginTop: 64,
      marginLeft: 200,
      position: "relative"
    }
  })
);

export const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <img className={classes.logo} src={logo} alt="TED Masterclass" />
          <UserAccountMenu className={classes.userAccount} />
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <nav className={classes.drawer}>
          <Menu />
        </nav>
        <main className={classes.content}>
          <BreadcrumbNav />
          <div>{children}</div>
          <Notification />
          <SystemMessage />
          <SingeOrgMessage />
        </main>
      </div>
    </>
  );
};

const ThemedLayout = ({ theme: appTheme, legacyTheme: legacy, ...props }) => (
  <ThemeProvider theme={appTheme}>
    <CssBaseline />
    <MuiThemeProvider theme={legacy}>
      <Layout {...props} />
    </MuiThemeProvider>
  </ThemeProvider>
);

ThemedLayout.defaultProps = {
  theme,
  legacyTheme
};

export default ThemedLayout;
