import React from "react";

import ROLES, { getRoleLevelMax } from "resources/users/roles";

import FilterByOption from "ui/filters/FilterByOption";

import useCurrentUser from "hooks/useCurrentUser";

const FilterByRole = props => {
  const { role } = useCurrentUser();
  const maxLevel = getRoleLevelMax(role);

  return (
    <FilterByOption
      options={ROLES.filter(({ level }) => level <= maxLevel).map(
        ({ name }) => name
      )}
      optionLabel={({ translate, value }) => translate(`userGroups.${value}`)}
      {...props}
    />
  );
};

export default FilterByRole;
