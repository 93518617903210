import React from "react";

import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

import FilterByBoolean from "ui/filters/FilterByBoolean";
import FilterByOption from "ui/filters/FilterByOption";
import FilterByValue from "ui/filters/FilterByValue";
import LessonRatingsStats from "lessonRatings/LessonRatingsStats";
import LessonRatingsTable from "lessonRatings/LessonRatingsTable";
import PageTitle from "ui/PageTitle";

import useFilters from "hooks/useFilters";

const filters = [
  <FilterByBoolean
    key="hasComment"
    filterKey="hasComment"
    options={[true, false]}
  />,
  <FilterByOption key="rating" filterKey="rating" options={[1, 2, 3, 4, 5]} />,
  <FilterByValue key="entryId" filterKey="entryId" />,
  <FilterByOption
    key="daysSinceSubmitted"
    filterKey="daysSinceSubmitted"
    options={[15, 30, 60, 90, 180, 365]}
    InputProps={{
      endAdornment: (
        <InputAdornment style={{ minWidth: 80 }} position="end">
          days ago
        </InputAdornment>
      )
    }}
  />
];

export const LessonRatings = () => {
  const { FiltersDropdown, FiltersPanel, filter } = useFilters({ filters });

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <PageTitle title="lessonRatings.plural" />
        <FiltersDropdown />
      </Grid>
      <FiltersPanel />
      <LessonRatingsStats filter={filter} />
      <LessonRatingsTable filter={filter} />
    </>
  );
};

export default LessonRatings;
