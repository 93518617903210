import React, { useEffect } from "react";
import clsx from "clsx";

import { FormDataConsumer, SelectInput, required } from "react-admin";

import OrgSelectField from "ui/fields/OrgSelectField";
import { FormRow } from "ui";

import useCurrentUser from "hooks/useCurrentUser";
import useFormStyles from "hooks/useFormStyles";
import useContractsList from "hooks/useContractsList";
import usePermissions from "hooks/usePermissions";
import useTranslate from "hooks/useTranslate";
import useStylesUtil from "hooks/useStylesUtil";

export const LinkedContractField = ({ formData, onChange, ...props }) => {
  const { isTedAdmin } = usePermissions();
  const { organizationId, contractId } = formData || {};
  const { contracts } = useContractsList({
    filter: { organizationId, status: isTedAdmin ? undefined : "active" }
  });
  const ids = contracts.map(({ id }) => id);

  useEffect(() => {
    if (onChange) {
      onChange(ids.includes(contractId) ? contractId : undefined);
    }
  }, [onChange, ids, contractId]);

  const helpText =
    (!organizationId && "You must select an organization.") ||
    (contracts.length < 1 &&
      "No contracts are available for the organization selected.") ||
    undefined;

  return (
    <SelectInput
      {...props}
      label="contracts.single"
      choices={contracts}
      source="contractId"
      optionText={({ name }) => name}
      optionValue="id"
      disabled={!organizationId || contracts.length < 1}
      helperText={helpText}
    />
  );
};

export const UserContractFields = ({ record, resource, onChange }) => {
  const { activeOrgId } = useCurrentUser();
  const { translate } = useTranslate();
  const classes = useFormStyles();
  const { visuallyHidden } = useStylesUtil();
  const fieldProps = { record, resource };

  return (
    <FormRow title={"contracts.single"}>
      <OrgSelectField
        source="organizationId"
        id="organizationId"
        label={translate("fields.organization")}
        className={clsx(classes.halfField, {
          [visuallyHidden]: activeOrgId
        })}
        {...fieldProps}
      />
      <FormDataConsumer
        source="contractId"
        id="contractId"
        className={clsx({
          [classes.halfField]: !activeOrgId,
          [classes.fullField]: activeOrgId
        })}
        validate={[required()]}
        onChange={onChange}
      >
        {LinkedContractField}
      </FormDataConsumer>
    </FormRow>
  );
};

export default UserContractFields;
