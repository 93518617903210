import React from "react";
import { palette, dropDown } from "theme/settings";

import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Toolbar from "@material-ui/core/Toolbar";

import BulkAddGroups from "users/bulk-actions/BulkAddGroups";
import BulkCancelInvites from "users/bulk-actions/BulkCancelInvites";
import BulkCopyEmails from "users/bulk-actions/BulkCopyEmails";
import BulkDeleteUsers from "users/bulk-actions/BulkDeleteUsers";
import BulkInviteUsers from "users/bulk-actions/BulkInviteUsers";
// import Typography from "@material-ui/core/Typography";

// import useTranslate from "hooks/useTranslate";

const useStyles = makeStyles({
  collapse: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 3
  },
  toolbar: {
    paddingLeft: 32,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: palette.background.shaded,
    boxShadow: dropDown.boxShadow
  }
  // title: {
  //   marginRight: 16
  // }
});

export const UserBulkActions = ({ selectedRows, refetch }) => {
  const classes = useStyles();
  // const { translate } = useTranslate();

  return (
    <Collapse className={classes.collapse} in={selectedRows.length > 0}>
      <Toolbar variant={"dense"} className={classes.toolbar}>
        {/* <Typography className={classes.title}>
          {translate("users.smartCount", { smart_count: selectedRows.length })}
        </Typography> */}

        <BulkAddGroups selectedRows={selectedRows} refetch={refetch} />
        <BulkCopyEmails selectedRows={selectedRows} />
        <BulkDeleteUsers selectedRows={selectedRows} refetch={refetch} />
        <BulkCancelInvites selectedRows={selectedRows} refetch={refetch} />
        <BulkInviteUsers selectedRows={selectedRows} refetch={refetch} />
      </Toolbar>
    </Collapse>
  );
};

UserBulkActions.defaultProps = {
  selectedRows: []
};

export default UserBulkActions;
