import React, { useEffect } from "react";
import gql from "graphql-tag";
import _get from "lodash.get";
import { useQuery } from "@apollo/react-hooks";

import Notification from "ui/Notification";

export const GET_AVAILABLE_SEATS = gql`
  query getAvailableSeats($id: Int!) {
    contract: Contract(id: $id) {
      id
      availableSeats
    }
  }
`;

const SeatLimitWarning = ({ contractId, setAvailable, className }) => {
  const { data, loading, error } = useQuery(GET_AVAILABLE_SEATS, {
    skip: !contractId || contractId < 1,
    variables: { id: parseInt(contractId, 10) },
    fetchPolicy: "network-only"
  });
  const availableSeats = _get(data, "contract.availableSeats", 0);
  const overLimit = contractId && !loading && availableSeats < 1;

  useEffect(() => {
    if (typeof setAvailable === "function") {
      setAvailable(availableSeats);
    }
  }, [setAvailable, availableSeats]);

  return overLimit ? (
    <Notification
      toast={false}
      type="warning"
      title={"errors.seat_limit.title"}
      message={"errors.seat_limit.text"}
      className={className}
    />
  ) : (
    <></>
  );
};

export default SeatLimitWarning;
