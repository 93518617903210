export const MuiToolbar = {
  gutters: {
    paddingLeft: 20,
    paddingRight: 20,
    "@media (min-width: 0px)": {
      paddingLeft: 20,
      paddingRight: 20
    }
  }
};
