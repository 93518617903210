import React from "react";
import pure from "recompose/pure";
import SvgIcon from "@material-ui/core/SvgIcon";

let CheckMarkIcon = ({ color, ...rest }) => (
  <SvgIcon viewBox="0 0 20 20" {...rest}>
    <circle fill="currentColor" cx="10" cy="10" r="9" />
    <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m2.293-10.707L9 10.586 7.707 9.293a1 1 0 1 0-1.414 1.414l2 2a.997.997 0 0 0 1.414 0l4-4a1 1 0 1 0-1.414-1.414" />
  </SvgIcon>
);

CheckMarkIcon.defaultProps = {
  color: "currentColor"
};

CheckMarkIcon = pure(CheckMarkIcon);
CheckMarkIcon.muiName = "SvgIcon";

export default CheckMarkIcon;
