import React from "react";
import _get from "lodash.get";

import gql from "graphql-tag";

import BulkUpdate from "ui/BulkUpdate";
import GroupIcon from "ui/icons/GroupIcon";
import ManageGroupsModal from "groups/ManageGroupsModal";

import useCurrentUser from "hooks/useCurrentUser";
import useGroupsList from "hooks/useGroupsList";

const UPDATE_USER_GROUPS = gql`
  mutation AddGroupsToUser($id: Int!, $groupIds: [Int!]) {
    updateUser(id: $id, groupIds: $groupIds) {
      id
      groupIds
    }
  }
`;

// Create a list of merged group ids for each user to pass to
// the bulkUpdate call.
const mergeGroupIds = ({ groupIds, rows }) =>
  rows.reduce(
    (obj, { id, groups }) => ({
      ...obj,
      [id]: {
        groupIds: [...new Set([...groups.map(g => g.id), ...groupIds])]
      }
    }),
    {}
  );

export const BulkAddGroups = ({ selectedRows, ...props }) => {
  const { activeOrgId } = useCurrentUser();
  const { groupList } = useGroupsList();

  return (
    <BulkUpdate
      disabled={!activeOrgId || groupList.length < 1}
      Dialog={
        <ManageGroupsModal
          onSave={({ selected, onConfirm }) => {
            onConfirm({
              mergeData: mergeGroupIds({
                groupIds: selected,
                rows: selectedRows
              })
            });
          }}
        />
      }
      label={"ui.addToGroups"}
      selectedRows={selectedRows}
      {...props}
    />
  );
};

BulkAddGroups.defaultProps = {
  Icon: GroupIcon,
  selectedRows: [],
  mutation: UPDATE_USER_GROUPS
};

export default BulkAddGroups;
