import React from "react";
import _get from "lodash.get";

import FilterById from "ui/filters/FilterById";

import useGroupsList from "hooks/useGroupsList";

const FilterByGroup = props => {
  const { groupList } = useGroupsList();
  return (
    <FilterById
      key="groupIds"
      filterKey="groupIds"
      options={groupList}
      {...props}
    />
  );
};

export default FilterByGroup;
