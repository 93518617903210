import gql from "graphql-tag";
import { graphTypeInt } from "hooks/useOrgQueries";

export const CREATE_CONTACT = gql`
  mutation createContact(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $primary: Boolean!
    $contractId: Int!
  ) {
    contact: createContact(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      primary: $primary
      contractId: $contractId
    ) {
      id
      firstName
      lastName
      email
      phone
      primary
      contractId
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation updateContact(
    $id: Int!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $primary: Boolean!
  ) {
    contact: updateContact(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      primary: $primary
    ) {
      id
      firstName
      lastName
      email
      phone
      primary
      contractId
    }
  }
`;

export const formatContactData = ({ contractId, id, primary, ...data }) => ({
  ...data,
  id: graphTypeInt(id),
  primary: !!primary,
  contractId: graphTypeInt(contractId)
});
