import React, { useContext } from "react";
import _get from "lodash.get";
// import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { useGridSlotComponentProps } from "@material-ui/data-grid";

import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Pagination from "@material-ui/lab/Pagination";

import { DataTableContext } from "./DataTableProvider";

import useId from "hooks/useId";
import useTranslate from "hooks/useTranslate";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.secondary.lightAlt,
    padding: 12
  },
  spacer: {
    height: "100%",
    width: 190
  },
  perPageLabel: {
    marginLeft: 8
  }
}));

const PageList = () => {
  const { page, perPage, setPage, total } = useContext(DataTableContext);

  return (
    <Pagination
      count={(total && Math.ceil(total / perPage)) || 0}
      page={page}
      boundaryCount={2}
      onChange={(e, value) => setPage(value)}
    />
  );
};

const RowsPerPage = ({ options, perPage, onPageSizeChange, label }) => {
  const { translate } = useTranslate();
  const classes = useStyles();
  const labelId = useId();
  const selectId = useId();

  if (!options || options.length < 2) {
    return <></>;
  }

  return (
    <div>
      <Select
        input={<InputBase />}
        value={perPage}
        onChange={onPageSizeChange}
        id={selectId}
        labelId={labelId}
      >
        {options.map(rows => (
          <MenuItem key={rows} value={rows}>
            {rows}
          </MenuItem>
        ))}
      </Select>
      <Typography
        variant="caption"
        id={labelId}
        className={classes.perPageLabel}
      >
        {translate(label)}
      </Typography>
    </div>
  );
};

RowsPerPage.defaultProps = {
  options: [10, 25, 50, 100],
  label: "ra.navigation.page_rows_per_page"
};

const DataTablePagination = ({ rowsPerPageOptions }) => {
  const classes = useStyles();
  const { page, perPage, setPerPage, total } = useContext(DataTableContext);
  const { translate } = useTranslate();

  return (
    <Toolbar classes={{ root: classes.root }}>
      <RowsPerPage
        options={rowsPerPageOptions}
        perPage={perPage}
        onPageSizeChange={e => setPerPage(parseInt(e.target.value, 10))}
      />
      <Typography variant="caption">
        {translate("ra.navigation.page_range_info", {
          from: Math.min(total, (page - 1) * perPage + 1),
          to: Math.min(total, page * perPage),
          total
        })}
      </Typography>
      {(total > perPage && <PageList />) || <div className={classes.spacer} />}
    </Toolbar>
  );
};

export default DataTablePagination;

export const DataGridFooter = ({ page, setPage }) => {
  const { state, apiRef } = useGridSlotComponentProps();
  const classes = useStyles();
  const { translate } = useTranslate();

  const pageCount = _get(state, "pagination.pageCount", 1);
  const pageSize = _get(state, "pagination.pageSize", 25);
  const total = _get(state, "pagination.rowCount", 25);

  return (
    <Toolbar classes={{ root: classes.root }}>
      <RowsPerPage
        options={_get(state, "options.rowsPerPageOptions")}
        perPage={pageSize}
        onPageSizeChange={e => {
          apiRef.current.setPageSize(Number(e.target.value));
        }}
      />
      <Typography variant="caption">
        {translate("ra.navigation.page_range_info", {
          from: Math.min(total, (page - 1) * pageSize + 1),
          to: Math.min(total, page * pageSize),
          total
        })}
      </Typography>
      {(total > pageSize && (
        <Pagination
          page={page}
          boundaryCount={1}
          count={pageCount}
          onChange={(_, page) => {
            setPage(page);
          }}
        />
      )) || <div className={classes.spacer} />}
    </Toolbar>
  );
};
