import React from "react";
import _get from "lodash.get";

import useTranslate from "hooks/useTranslate";

const TranslateText = ({ text }) => {
  const { translate } = useTranslate();
  return <>{translate(text)}</>;
};

export default TranslateText;
