import React from "react";
import clsx from "clsx";
import { addField } from "ra-core";

import Checkbox from "@material-ui/core/Checkbox";
import X from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";

import { SelectArrayInput } from "react-admin";

import UncheckedIcon from "ui/icons/UncheckedIcon";
import CheckedIcon from "ui/icons/CheckedIcon";
import Loading from "ui/Loading";

import useGroupsList from "hooks/useGroupsList";
import useTranslate from "hooks/useTranslate";

export const MenuOption = ({ record, value }) => {
  const classes = useStyles();
  const { name, id } = record || {};
  const selected = Array.isArray(value) ? value : [];

  return (
    <>
      <Checkbox
        classes={{
          root: classes.checkBox
        }}
        checkedIcon={<CheckedIcon className={classes.checkedIcon} />}
        icon={<UncheckedIcon className={classes.uncheckedIcon} />}
        checked={selected.indexOf(id) > -1}
      />
      {name}
    </>
  );
};

export const GroupMembershipInput = ({
  formData,
  disabled,
  input,
  record,
  ...props
}) => {
  const classes = useStyles();
  const { translate } = useTranslate();
  const { groupList, isLoading } = useGroupsList();
  const { organizationId } = formData || {};
  const choices = organizationId
    ? groupList.filter(group => group.organizationId === organizationId)
    : groupList;

  if (isLoading) {
    return (
      <Loading
        label={"groups.filter"}
        source={props.source}
        isRequired={props.isRequired}
      />
    );
  }

  if (!choices || choices.length < 1) {
    return <></>;
  }

  const SelectedItems = selectedIds => {
    const chips = choices.filter(group => selectedIds.includes(group.id));
    return (
      <div className={classes.chips}>
        {chips.map((group, idx) => (
          <div
            key={group.id}
            className={clsx(classes.chip, {
              [classes.chipDisabled]: disabled
            })}
          >
            {group.name}
            {disabled && idx < chips.length - 1 && ","}
            {!disabled && <X className={classes.chipIcon} size={22} />}
          </div>
        ))}
      </div>
    );
  };

  return (
    <SelectArrayInput
      optionText={<MenuOption value={input && input.value} />}
      meta={{ helperText: translate("resources.users.fields.groupIdsHelp") }}
      input={input}
      options={{
        renderValue: SelectedItems,
        autoWidth: false,
        classes: {
          select: classes.select
        },
        MenuProps: {
          anchorOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null,
          PopoverClasses: {
            paper: classes.dropdown
          }
        }
      }}
      choices={choices}
      disabled={disabled}
      {...props}
    />
  );
};

const useStyles = makeStyles(theme => ({
  chips: {
    margin: "-4px -2px"
  },
  chip: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#DFE3E8",
    color: theme.palette.text.primary,
    padding: 4,
    borderRadius: 3,
    margin: 4,
    outline: "none",
    pointerEvents: "none"
  },
  chipDisabled: {
    color: theme.palette.text.disabled,
    border: "none",
    backgroundColor: "transparent"
  },
  chipIcon: {
    marginLeft: 8,
    color: theme.palette.text.light,

    "$chipDisabled &": {
      display: "none"
    }
  },
  checkBox: {
    width: 16,
    height: 16,
    marginRight: 8
  },
  uncheckedIcon: {
    boxShadow: "0 1px 0 0 rgba(22,29,37,0.05)",
    color: "#DAE1E7",
    height: 16,
    wdith: 16
  },
  checkedIcon: {
    boxShadow:
      "0 1px 1px 0 rgba(97,121,133,0.05), 0 1px 1px 0 rgba(64,82,88,0.03)",
    color: theme.palette.secondary.main,
    height: 16,
    wdith: 16
  },
  dropdown: {
    ...theme.dropDown
  },
  select: {
    whiteSpace: "normal",
    minHeight: 37,
    padding: 4
  }
}));

export default addField(GroupMembershipInput);
