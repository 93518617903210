import React from "react";
import { useFormContext, Controller } from "react-hook-form";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import useFormValidation from "hooks/useFormValidation";

const safeValue = value => (value === undefined || value === null ? "" : value);

const SelectInput = ({
  defaultValue,
  name,
  label,
  validate,
  choices,
  optionText,
  optionValue,
  Select,
  ...props
}) => {
  const { errors, control } = useFormContext();
  const { fieldErrors } = useFormValidation(errors);
  const { required } = validate || {};

  const MuiTextFieldSelect = ({ onChange, value }) => (
    <TextField
      select
      label={label}
      value={safeValue(value)}
      onChange={onChange}
      required={!!required}
      {...fieldErrors(name)}
      {...props}
    >
      {choices.map((choice, idx) => (
        <MenuItem
          key={idx}
          value={(optionValue && optionValue(choice)) || choice}
        >
          {(optionText && optionText(choice)) || choice}
        </MenuItem>
      ))}
    </TextField>
  );

  const CustomSelect = ({ onChange, value }) =>
    React.cloneElement(Select, {
      onChange,
      value: safeValue(value),
      required: !!required,
      label,
      ...fieldErrors(name),
      ...props
    });

  return (
    <Controller
      name={name}
      rules={validate}
      control={control}
      render={(!!Select && CustomSelect) || MuiTextFieldSelect}
      defaultValue={defaultValue}
    />
  );
};

SelectInput.defaultProps = {
  choices: []
};

export default SelectInput;
