import { palette, typography } from "./settings";

export const MuiTable = {
  root: {
    background: palette.common.white,
    fontFamily: "Inter !important"
  }
};

export const MuiTableCell = {
  head: {
    fontWeight: 700,
    fontSize: typography.fontSize,
    color: palette.text.light,
    border: 0
  }
};

export const MuiTableHead = {
  root: {
    backgroundColor: palette.secondary.lightAlt
  }
};

export const MuiTableRow = {
  root: {
    "&$hover:hover": {
      backgroundColor: palette.secondary.light
    },
    "&:last-of-type td": {
      borderBottom: 0
    }
  }
};

export const MuiTableSortLabel = {
  root: {
    "&:active, &:focus, &:hover": {
      color: "inherit"
    }
  },
  active: {
    color: "inherit"
  }
};
