import React from "react";
import compose from "recompose/compose";

import { withStyles } from "@material-ui/core/styles";

export const FormActionsBar = ({
  ExtraActions,
  StandardActions,
  classes,
  ...props
}) => {
  if (!StandardActions && !ExtraActions) {
    return <></>;
  }
  return (
    <div className={classes.root}>
      <div className={classes.actionsBox}>
        {ExtraActions && (
          <div className={classes.actionsExtraBox}>
            <ExtraActions {...props} />
          </div>
        )}
        {StandardActions && (
          <div className={classes.actionsStandardBox}>
            <StandardActions {...props} />
          </div>
        )}
      </div>
    </div>
  );
};

FormActionsBar.defaultProps = {
  classes: {
    root: "root",
    actionsBox: "actionsBox",
    actionsExtraBox: "actionsExtraBox",
    actionsStandardBox: "actionsStandardBox"
  }
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 8 * 3,
    maxWidth: 960 + 290 + 8
  },
  actionsBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    maxWidth: `calc(100% - ${theme.typography.pxToRem(290 + 8)})`,
    paddingRight: 8 * 2.5,
    paddingLeft: 8 * 2.5
  },
  actionsExtraBox: {
    marginRight: "auto"
  },
  actionsStandardBox: {
    marginLeft: "auto"
  }
});

const enhance = compose(withStyles(styles, { name: "FormActionsBar" }));

export default enhance(FormActionsBar);
