import React from "react";
import _get from "lodash.get";

import Button from "@material-ui/core/Button";
import ContractsCell from "ui/datagrid/ContractsCell";
import { dateFormatter } from "ui/DateText";
import FilterButton from "ui/datagrid/FilterButton";
import GroupsCell from "ui/datagrid/GroupsCell";
import LessonsCompletedCell from "ui/datagrid/LessonsCompletedCell";
import Link from "ui/Link";
import OrganiztionCell from "ui/datagrid/OrganizationCell";
import { org_types_enum } from "ui/fields/OrgTypeSelector";
import TranslateText from "ui/TranslateText";
import UserCell from "ui/datagrid/UserCell";
import VideosCell from "ui/datagrid/VideosCell";

export const Header = ({ field, headerName, ...props }) => {
  const headerText = _get(props, "colDef.headerName", headerName);
  return <TranslateText text={headerText || `headerCell.${field}`} />;
};

export const baseCol = {
  renderHeader: Header
};

export const center = {
  headerAlign: "center",
  align: "center"
};

export const date = {
  width: 140,
  renderHeader: Header,
  valueFormatter: ({ value }) => dateFormatter(value, undefined, "N/A")
};

export const contracts = {
  width: 128,
  headerName: "contracts.single",
  renderHeader: Header,
  renderCell: ({ row: data }) => (
    <ContractsCell contracts={_get(data, "contracts")} id={_get(data, "id")} />
  )
};

export const getData = key => params => _get(params, `row.${key}`);

export const groups = {
  type: "string",
  width: 160,
  sortable: false,
  renderHeader: Header,
  renderCell: ({ row: data }) => <GroupsCell groups={_get(data, "groups")} />
};

export const lastActive = {
  width: 140,
  renderHeader: Header,
  renderCell: ({ row: data }) => {
    const lastActive = _get(data, "lastActive");
    if (lastActive) {
      return <>{dateFormatter(lastActive * 1000)}</>;
    }

    return <TranslateText text="ui.inactive" />;
  }
};

export const lessonsCompleted = {
  width: 144,
  renderHeader: Header,
  renderCell: ({ row: data }) => (
    <LessonsCompletedCell lessonsCompleted={_get(data, "lessonsCompleted")} />
  )
};

export const name = {
  type: "string",
  width: 240,
  headerName: "headerCell.nameEmail",
  renderHeader: Header,
  renderCell: ({ row: data }) => (
    <UserCell
      id={_get(data, "user.id") || _get(data, "id")}
      firstName={_get(data, "user.firstName") || _get(data, "firstName")}
      lastName={_get(data, "user.lastName") || _get(data, "lastName")}
      email={_get(data, "user.email") || _get(data, "email")}
    />
  )
};

export const organizationType = {
  type: "string",
  headerName: "headerCell.organizationType_short",
  renderHeader: Header,
  width: 160,
  renderCell: ({ row: data }) => {
    const organizationType =
      _get(data, "organizationType") ||
      _get(data, "organization.organizationType");

    if (!organizationType) {
      return <Button disabled>?</Button>;
    }

    return (
      <FilterButton
        filter={{ organizationType: org_types_enum[organizationType] }}
      >
        <TranslateText
          text={`resources.organizations.types.${organizationType}_short`}
        />
      </FilterButton>
    );
  }
};

// export const organization = {
//   type: "string",
//   headerName: "resources.organizations.single",
//   renderHeader: Header,
//   width: 160,
//   sortable: false,
//   valueFormatter: params => _get(params, "organization.name", "")
// };

export const organizationName = {
  type: "string",
  headerName: "headerCell.organizationName",
  renderHeader: Header,
  width: 200,
  renderCell: ({ row: data }) => <OrganiztionCell {...data} />
};

export const userOrganizationName = {
  ...organizationName,
  renderCell: ({ row: data }) => (
    <OrganiztionCell {..._get(data, "organization", {})} />
  )
};

export const organizationContracts = {
  width: 128,
  headerName: "headerCell.contractsCount",
  renderHeader: Header,
  renderCell: ({ row: data }) => {
    const contracts = Array.isArray(_get(data, "contracts"))
      ? _get(data, "contracts")
      : [];

    const props = contracts.length
      ? {
          component: Link,
          to: `/contracts`,
          queryParams: { filter: { organizationId: _get(data, "id") } }
        }
      : { disabled: true };

    return <Button {...props}>{contracts.length}</Button>;
  }
};

export const translatedField = {
  type: "string",
  width: 100,
  renderHeader: Header,
  renderCell: props => {
    const fieldName = _get(props, "field");
    const value = _get(props, `row.${fieldName}`);

    return value ? <TranslateText text={`${fieldName}.${value}`} /> : "-";
  }
};

export const role = {
  type: "string",
  width: 140,
  renderHeader: Header,
  renderCell: ({ row: data }) => (
    <TranslateText text={`userGroups.${_get(data, "role")}`} />
  )
};

export const status = {
  type: "string",
  width: 80,
  renderHeader: Header,
  renderCell: ({ row: data }) => (
    <TranslateText text={`ui.status.${_get(data, "status")}`} />
  )
};

export const timestamp = {
  width: 140,
  renderHeader: Header,
  valueFormatter: params => {
    const value = _get(params, "value");
    return value ? dateFormatter(value * 1000) : "-";
  }
};

export const timestampString = {
  width: 140,
  renderHeader: Header,
  valueFormatter: params => dateFormatter(parseInt(_get(params, "value"), 10))
};

// export const textField = (fieldKey) => ({
//   type: "string",
//   renderHeader: Header,
//   width: 160,
//   sortable: false,
//   renderCell: ({ row: data }) => _get(data, fieldKey) || "")
// });

export const videos = {
  width: 120,
  headerAlign: "center",
  align: "center",
  renderHeader: Header,
  renderCell: ({ row: data }) => (
    <VideosCell count={_get(data, "videosCount")} userId={_get(data, "id")} />
  )
};
