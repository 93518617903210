import React, { createContext } from "react";
import clsx from "clsx";
import _get from "lodash.get";

import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { DataGrid } from "@material-ui/data-grid";

import DataTable from "ui/DataTable/DataTable";
import DataTableProvider from "ui/DataTable/DataTableProvider";
import { DataGridFooter } from "ui/DataTable/DataTablePagination";
import SortIcon, { SortIconInvert } from "ui/icons/SortIcon";
import { SearchInput } from "ui/fields/SearchField";

import useDataList from "hooks/useDataList";
import useFilters from "hooks/useFilters";
import useStylesUtil from "hooks/useStylesUtil";

export const DataListContext = createContext();

const useStyles = makeStyles({
  root: {
    position: "relative",
    background: "#fff",

    "& .MuiDataGrid-columnsContainer": {
      color: "#637381"
    },
    "& .MuiDataGrid-colCellWrapper": {
      background: "#F5F6F9"
    },
    "& .MuiDataGrid-colCell": {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: "-0.5px"
    },
    "& .MuiDataGrid-colCellSortable .MuiIconButton-root": {
      color: "#637381",
      width: 24,
      height: 24
    },
    "& .MuiDataGrid-sortIcon": {
      width: 14,
      height: 14
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none"
    }
  }
});

export const DataListTable = ({
  BulkActions,
  checkboxSelection,
  columns,
  CreateButton,
  CustomizeButton,
  dataGrid,
  filters,
  preFilter,
  DataTableRow,
  ExportButton,
  ListFooter,
  onSelectionChange,
  title,
  query,
  sortModel,
  showSearch,
  searchText
}) => {
  const classes = useStyles();
  const utilClasses = useStylesUtil();

  const {
    filter,
    setSearchFilter,
    updateFilter,
    FiltersDropdown,
    FiltersPanel
  } = useFilters({ filters });

  const {
    list,
    total,
    refetch,
    page,
    setPage,
    perPage,
    setPerPage,
    setSortOrder,
    setSortField,
    loading
  } = useDataList({
    filter,
    preFilter,
    query
  });

  return (
    <DataListContext.Provider
      value={{
        updateFilter
      }}
    >
      <Paper elevation={1}>
        {BulkActions && React.cloneElement(BulkActions, { refetch })}
        <div className={clsx(utilClasses.itemsCenter, utilClasses.mb4)}>
          <Typography variant="h1">{title}</Typography>
          {CreateButton && React.cloneElement(CreateButton)}

          <div
            className={clsx(
              utilClasses.flex,
              utilClasses.mlauto,
              utilClasses.gray3
            )}
          >
            {CustomizeButton && React.cloneElement(CustomizeButton)}
            <FiltersDropdown className={utilClasses.mr4} />
            {showSearch !== false && (
              <SearchInput
                placeholder={searchText}
                value={filter.q || ""}
                onChange={setSearchFilter}
              />
            )}
          </div>
        </div>

        <FiltersPanel />

        {dataGrid && (
          <div
            style={{
              display: "flex",
              height: `${Math.min(perPage, list.length) * 52 + 137}px`,
              width: "100%",
              marginBottom: "16px"
            }}
          >
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              <DataGrid
                className={classes.root}
                columns={columns}
                rows={list}
                rowCount={total}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={({ sortModel }) => {
                  const field = _get(sortModel, "0.field");
                  const sort = _get(sortModel, "0.sort");
                  if (field && sort) {
                    setSortField(field);
                    setSortOrder(sort.toUpperCase());
                  }
                }}
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginationMode="server"
                onPageChange={({ page }) => setPage(page)}
                pageSize={perPage}
                onPageSizeChange={({ pageSize }) => setPerPage(pageSize)}
                onError={props => console.log("error", props)}
                loading={loading}
                onSelectionModelChange={({ selectionModel }) => {
                  onSelectionChange({
                    rows: selectionModel
                      .map(selectedId =>
                        list.find(({ id }) => id === parseInt(selectedId, 10))
                      )
                      .filter(x => x)
                  });
                }}
                components={{
                  Footer: () => (
                    <DataGridFooter page={page} setPage={setPage} />
                  ),
                  ColumnSortedAscendingIcon: SortIcon,
                  ColumnSortedDescendingIcon: SortIconInvert
                }}
                disableColumnMenu
                disableColumnReorder
                disableSelectionOnClick
                disableColumnFilter
                checkboxSelection={checkboxSelection}
              />
            </div>
          </div>
        )}
        {!dataGrid && (
          <DataTable
            data={list}
            columns={columns}
            DataTableRow={DataTableRow && React.cloneElement(DataTableRow)}
          />
        )}

        {ListFooter && React.cloneElement(ListFooter)}
        {ExportButton && React.cloneElement(ExportButton, { filter })}
      </Paper>
    </DataListContext.Provider>
  );
};

const DataList = ({ sortOrder, sortField, ...props }) => (
  <DataTableProvider sortOrder={sortOrder} sortField={sortField}>
    <DataListTable {...props} />
  </DataTableProvider>
);

export default DataList;
