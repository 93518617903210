import _get from "lodash.get";
import { useEffect } from "react";

const EOL = "\r\n";
const FIELD_SEPARATOR = ",";

export const formatDateCSV = date => new Date(date).toLocaleDateString();

export const formatTimestampCSV = date =>
  new Date(parseInt(date, 10)).toLocaleDateString();

const formatCell = data => {
  if (data === 0) {
    return "0";
  }

  if (typeof data === "boolean") {
    return data ? "TRUE" : "FALSE";
  }

  if (typeof data === "string") {
    return `"${data.replace(/"/g, '""')}"`;
  }

  return data;
};

const formatRow = (data, fields) => {
  if (typeof data !== "object") {
    console.warn(`CSV rows must be objects`, data);
    return Object.keys(fields)
      .map(() => "")
      .join(",");
  }

  const row = Object.keys(fields).map(key => {
    const format = _get(fields, `${key}.format`);
    if (typeof format === "function") {
      return format(data);
    }

    const value = _get(data, key);
    const formatValue = _get(fields, `${key}.formatValue`);
    if (typeof formatValue === "function") {
      return formatValue(value);
    }

    const fallback = _get(fields, `${key}.default`);

    return fallback ? value || fallback : value;
  });

  return row.map(cell => formatCell(cell)).join(FIELD_SEPARATOR);
};

const getHeaders = fields =>
  typeof fields === "object"
    ? Object.values(fields).map(({ label }) => label)
    : "";

const downloadCSV = ({ filename, csvData }) => {
  if (typeof window === "undefined") {
    return;
  }

  let link = document.createElement("a");
  link.href = URL.createObjectURL(
    new Blob([csvData], { type: "text/csv;charset=utf-8;" })
  );

  link.setAttribute("visibility", "hidden");
  link.download = `${filename}.csv`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const formatCSVData = ({ data, fields }) => {
  const rows =
    Array.isArray(data) && typeof fields === "object"
      ? data.map(row => formatRow(row, fields))
      : [];
  return [getHeaders(fields), ...rows].join(EOL);
};

const useCSVdownload = ({ data, fields, filename, startDownload }) => {
  if (typeof fields !== "object") {
    console.warn(`You must provide fields as an object.`, fields);
  }

  const csvData = formatCSVData({ data, fields });

  useEffect(() => {
    if (csvData && startDownload) {
      downloadCSV({ filename, csvData });
    }
  }, [startDownload, csvData]);
};

export default useCSVdownload;
