import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ChevronDown = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M6 9l6 6 6-6"
    ></path>
  </SvgIcon>
);

export default ChevronDown;
