import React from "react";
import _get from "lodash.get";

import gql from "graphql-tag";

import SendIcon from "@material-ui/icons/Send";
import BulkUpdate from "ui/BulkUpdate";

export const INVITE_USER = gql`
  mutation inviteUser($id: Int!) {
    inviteUser(id: $id) {
      id
    }
  }
`;

export const BulkInviteUsers = ({ selectedRows, ...props }) => (
  <BulkUpdate
    Icon={SendIcon}
    label={"users.bulk_resend_invite"}
    selectedRows={selectedRows}
    message={selectedRows.map(({ email }) => email).join(", ")}
    confirmMsg={"users.invite_bulk_confirm"}
    successMsg={"users.invite_bulk_success"}
    {...props}
  />
);

BulkInviteUsers.defaultProps = {
  mutation: INVITE_USER,
  selectedRows: []
};

export default BulkInviteUsers;
