import React from "react";

import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import PlusCircle from "react-feather/dist/icons/plus-circle";

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.secondary.main,
    padding: "10px 14px"
  },
  icon: {
    width: 22,
    height: 22,
    marginRight: 8
  }
}));

export const CreateButton = ({ Icon, text, ...props }) => {
  const classes = useStyles();

  return (
    <Button component={Link} className={classes.button} {...props}>
      <Icon className={classes.icon} />
      <Typography className={classes.text} variant={"h6"}>
        {text}
      </Typography>
    </Button>
  );
};

CreateButton.defaultProps = {
  Icon: PlusCircle
};

export default CreateButton;
