import React from "react";
// import _get from "lodash.get";

import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Popover from "@material-ui/core/Popover";

import useTranslate from "hooks/useTranslate";
import useMenuAnchor from "hooks/useMenuAnchor";
import useStylesUtil from "hooks/useStylesUtil";

export const PopoverList = ({ id, label, list, renderItem }) => {
  const classes = useStylesUtil();
  const { translate } = useTranslate();
  const { menuProps, openMenu } = useMenuAnchor();

  if (!list.length) {
    return <Button disabled>0</Button>;
  }

  return (
    <div>
      <Button aria-describedby={id} onClick={openMenu} className={classes.bold}>
        {label || translate("ra.action.show")}
      </Button>
      <Popover
        id={id}
        {...menuProps}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <List>
          {list.map((item, idx) => (
            <ListItem key={idx}>
              {(renderItem && renderItem(item)) || item}
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

PopoverList.defaultProps = {
  list: []
};

export default PopoverList;
