import React from "react";
import pure from "recompose/pure";
import SvgIcon from "@material-ui/core/SvgIcon";

let CheckedIcon = ({ viewBox, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <rect
        width="15"
        height="15"
        x="0.5"
        y="0.5"
        stroke="currentColor"
        rx="3"
      />
      <path
        fill="currentColor"
        d="M6.315 11.858L3.133 8.441a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.219 2.392 4.944-5.324a.437.437 0 0 1 .643 0l.643.683a.504.504 0 0 1 0 .682l-5.91 6.352a.437.437 0 0 1-.642 0"
      />
    </g>
  </SvgIcon>
);

CheckedIcon = pure(CheckedIcon);
CheckedIcon.muiName = "SvgIcon";

export default CheckedIcon;
