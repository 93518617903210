import React from "react";
import pure from "recompose/pure";
import SvgIcon from "@material-ui/core/SvgIcon";

let WarningIcon = ({ color, ...rest }) => (
  <SvgIcon viewBox="0 0 20 20" {...rest}>
    <circle fill={color} cx="10" cy="10" r="9" />
    <path d="M2 10c0-1.846.635-3.543 1.688-4.897l11.209 11.209A7.954 7.954 0 0 1 10 18c-4.411 0-8-3.589-8-8m14.312 4.897L5.103 3.688A7.954 7.954 0 0 1 10 2c4.411 0 8 3.589 8 8a7.952 7.952 0 0 1-1.688 4.897M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10S15.514 0 10 0 0 4.486 0 10" />
  </SvgIcon>
);

WarningIcon.defaultProps = {
  color: "currentColor"
};

WarningIcon = pure(WarningIcon);
WarningIcon.muiName = "SvgIcon";

export default WarningIcon;
