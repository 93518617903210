import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";

import GridIcon from "react-feather/dist/icons/grid";

import useSavedSettings from "hooks/useSavedSettings";
import useMenuAnchor from "hooks/useMenuAnchor";

const useStyles = makeStyles({
  list: {
    width: 200
  },
  button: {
    color: "#637381"
  },
  buttonIcon: {
    width: 14,
    height: 14,
    marginRight: 4
  },
  listItem: {
    padding: "6px 18px"
  },
  icon: {
    minWidth: 0,
    width: 18,
    height: 18,
    marginRight: 8,

    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18
    }
  }
});

const CustomizeMenuList = React.forwardRef(
  ({ columns, onAdd, onRemove, active }, ref) => {
    const classes = useStyles();

    return (
      <List
        classes={{
          root: classes.list
        }}
      >
        {columns.map(({ field, renderHeader, ...props }) => {
          const selected = active.indexOf(field) !== -1;
          const labelId = `checkbox-list-label-${field}`;
          return (
            <ListItem
              classes={{
                root: classes.listItem
              }}
              key={field}
              id={`col-id-${field}`}
              role={undefined}
              dense
              button
              onClick={() => (selected ? onRemove(field) : onAdd(field))}
            >
              <Checkbox
                classes={{
                  root: classes.icon
                }}
                edge="start"
                checked={selected}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
              {typeof renderHeader === "function" &&
                renderHeader({ ...props, field })}
            </ListItem>
          );
        })}
      </List>
    );
  }
);

CustomizeMenuList.defaultProps = {
  columns: [],
  active: []
};

export const CustomizeButton = ({ CustomizeMenu, Icon, text, ...props }) => {
  const { closeMenu, menuProps, openMenu } = useMenuAnchor();
  const classes = useStyles();

  return (
    <>
      <Button classes={{ root: classes.button }} onClick={openMenu}>
        {Icon && React.cloneElement(Icon, { className: classes.buttonIcon })}
        <Typography variant={"h6"}>{text}</Typography>
      </Button>

      <Menu {...menuProps}>
        <CustomizeMenuList closeMenu={closeMenu} {...props} />
      </Menu>
    </>
  );
};

CustomizeButton.defaultProps = {
  Icon: <GridIcon fill="currentColor" />,
  text: "Customize view"
};

export const useCustomize = () => {
  const { userListCols, saveUserListCols } = useSavedSettings();
  const [active, setActive] = useState(userListCols);
  const saveCustomize = React.useCallback(
    cols => {
      saveUserListCols(cols);
    },
    [saveUserListCols]
  );

  useEffect(() => {
    saveCustomize(active);
  }, [active]);

  return {
    active,
    onAdd: field => setActive([...active, field]),
    onRemove: field => setActive(active.filter(f => f !== field))
  };
};

export default CustomizeButton;
