import { LOCATION_CHANGE } from "react-router-redux";
import { destroy } from "redux-form";
import isEqual from "lodash/isEqual";

import { USERS, ORGS, GROUPS } from "../resources/types";
import { REDUX_FORM_NAME, resetForm, changeListParams } from "react-admin";

export const listPages = [USERS, ORGS, GROUPS].map(resource => `/${resource}`);

/**
 * See https://github.com/marmelab/react-admin/blob/v2.4.0/packages/ra-core/src/form/formMiddleware.js
 */
const formMiddleware = () => {
  let previousLocation;
  return next => action => {
    if (
      action.type !== LOCATION_CHANGE ||
      (action.payload.state && action.payload.state.skipFormReset)
    ) {
      return next(action);
    }

    if (isEqual(action.payload, previousLocation)) {
      return next(action);
    }

    previousLocation = action.payload;

    next(resetForm());
    next(destroy(REDUX_FORM_NAME));

    const { pathname } = previousLocation;
    if (listPages.includes(pathname)) {
      next(changeListParams(pathname.replace(/^\//, ""), {}));
    }

    return next(action);
  };
};

export default formMiddleware;
