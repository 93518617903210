import React, { useState } from "react";
import clsx from "clsx";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import ConfirmDialog from "ui/ConfirmDialog";
import FormRow from "ui/form/FormRow";
import { OrganizationFields } from "organizations/OrganizationFields";
import { ContractFields } from "contracts/ContractForm";

import ConfirmLeaveMessage from "ui/ConfirmLeaveMessage";

import useTranslate from "hooks/useTranslate";
import usePermissions from "hooks/usePermissions";
import { useCombinedCreateOrg } from "hooks/useOrgQueries";
import useStylesUtil from "hooks/useStylesUtil";

export const Page1 = ({ setPage, setOrg }) => {
  const classes = useStylesUtil();
  const formMethods = useForm();
  const { translate } = useTranslate();
  const onSubmit = ({ countryCode, ...data }) => {
    setOrg({ ...data, countyCode: countryCode.code });
    setPage(2);
  };

  return (
    <Paper elevation={1}>
      <Typography variant="h1">
        {translate("resources.organizations.new_org_title")}
      </Typography>
      <Divider style={{ margin: "18px 0" }} />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
          <FormRow
            title={translate("resources.organizations.detail.title")}
            desc={translate("resources.organizations.detail.desc")}
          >
            <OrganizationFields editing />
          </FormRow>
          <div className={classes.justifyContentEnd}>
            <Button
              className={classes.mr4}
              component={RouterLink}
              to={`/organizations/`}
              variant="outlined"
            >
              {translate("ra.action.cancel")}
            </Button>
            <Button type="submit" variant="contained" color="secondary">
              Step 2: Add contract
            </Button>
          </div>
        </form>
      </FormProvider>
    </Paper>
  );
};

export const Page2 = ({ org, setPage, onCompleted }) => {
  const classes = useStylesUtil();
  const formMethods = useForm();
  const [createOganization] = useCombinedCreateOrg({ onCompleted });

  const onSubmit = data => {
    createOganization({ org, contract: data });
  };

  return (
    <Paper elevation={1}>
      <Typography variant="h1">New Contract</Typography>
      <Divider style={{ margin: "18px 0" }} />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <ContractFields newOrg organization={org} editing />
          <div className={classes.justifyContentEnd}>
            <Button
              className={classes.mr4}
              onClick={() => setPage(1)}
              variant="outlined"
            >
              {" "}
              Back
            </Button>
            <Button type="submit" variant="contained" color="secondary">
              Done
            </Button>
          </div>
        </form>
      </FormProvider>
    </Paper>
  );
};

const confirmCancel = "resources.organizations.create_confirm_cancel";

export const OrganizationCreate = ({ history }) => {
  const { visuallyHidden, maxWidth1k } = useStylesUtil();
  const [completed, setCompleted] = useState(false);
  const [org, setOrg] = useState({});
  const [page, setPage] = useState(1);
  const { translate } = useTranslate();
  const { canCreateOrgs, canViewOrgs } = usePermissions();

  if (!canCreateOrgs) {
    return <Redirect to={canViewOrgs ? "/organizations" : "/dashboard"} />;
  }

  return (
    <Paper elevation={1} className={maxWidth1k}>
      {!completed && (
        <ConfirmLeaveMessage
          Message={
            <ConfirmDialog
              variant={"caution"}
              title={translate(`${confirmCancel}_title`)}
              message={translate(`${confirmCancel}_text`)}
              cancelLabel={translate(`${confirmCancel}_no`)}
              confirmLabel={translate(`${confirmCancel}_yes`)}
            />
          }
        />
      )}
      <div className={clsx({ [visuallyHidden]: page !== 1 })}>
        <Page1 setPage={setPage} setOrg={setOrg} />
      </div>
      <div className={clsx({ [visuallyHidden]: page !== 2 })}>
        <Page2
          setPage={setPage}
          org={org}
          onCompleted={() => {
            setCompleted(true);
            history.push(`/organizations`);
          }}
        />
      </div>
    </Paper>
  );
};

export default OrganizationCreate;
