import React from "react";
import pure from "recompose/pure";
import SvgIcon from "@material-ui/core/SvgIcon";

let CautionIcon = ({ color, ...rest }) => (
  <SvgIcon viewBox="0 0 20 20" {...rest}>
    <circle fill="currentColor" cx="10" cy="10" r="9" />
    <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m0-13a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0V6a1 1 0 0 0-1-1m0 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
  </SvgIcon>
);

CautionIcon.defaultProps = {
  color: "currentColor"
};

CautionIcon = pure(CautionIcon);
CautionIcon.muiName = "SvgIcon";

export default CautionIcon;
