export const USER_ROLE = { name: "user", id: 0, level: 0, maxLv: 0 };
export const ADMIN_ROLE = { name: "admin", id: 1, level: 1, maxLv: 1 };
export const SUPER_ROLE = { name: "super_admin", id: 2, level: 2, maxLv: 1 };
export const TED_ROLE = { name: "ted_admin", id: 3, level: 3, maxLv: 3 };

export const CAN_ACCESS_COMMUNITY = "can_access_community";

export const CAN_CANCEL_USERS = "can_cancel_users";
export const CAN_CREATE_USERS = "can_create_users";
export const CAN_DELETE_USERS = "can_delete_users";
export const CAN_EDIT_USERS = "can_edit_users";
export const CAN_VIEW_USERS = "can_view_users";

export const CAN_CREATE_ORGS = "can_create_orgs";
export const CAN_DELETE_ORGS = "can_delete_orgs";
export const CAN_EDIT_ORGS = "can_edit_orgs";
export const CAN_VIEW_ORGS = "can_view_orgs";

export const CAN_CREATE_GROUPS = "can_create_groups";
export const CAN_DELETE_GROUPS = "can_delete_groups";
export const CAN_EDIT_GROUPS = "can_edit_groups";
export const CAN_VIEW_GROUPS = "can_view_groups";

export const CAN_VIEW_LESSON_RATINGS = "can_view_lesson_ratings";

export const TED_ADMIN = "is_ted_admin";

export const ROLE_PERMISSIONS = {
  [USER_ROLE.name]: [],
  [ADMIN_ROLE.name]: [CAN_VIEW_GROUPS, CAN_EDIT_USERS, CAN_VIEW_USERS],
  [SUPER_ROLE.name]: [
    CAN_ACCESS_COMMUNITY,
    CAN_CREATE_GROUPS,
    CAN_CREATE_USERS,
    CAN_CANCEL_USERS,
    CAN_DELETE_GROUPS,
    CAN_EDIT_GROUPS,
    CAN_EDIT_USERS,
    CAN_VIEW_GROUPS,
    CAN_VIEW_ORGS,
    CAN_VIEW_USERS
  ],
  [TED_ROLE.name]: [
    CAN_ACCESS_COMMUNITY,
    CAN_CREATE_GROUPS,
    CAN_CREATE_ORGS,
    CAN_CANCEL_USERS,
    CAN_CREATE_USERS,
    CAN_DELETE_USERS,
    CAN_DELETE_GROUPS,
    CAN_DELETE_ORGS,
    CAN_EDIT_GROUPS,
    CAN_EDIT_ORGS,
    CAN_EDIT_USERS,
    CAN_VIEW_GROUPS,
    CAN_VIEW_ORGS,
    CAN_VIEW_USERS,
    CAN_VIEW_LESSON_RATINGS,
    TED_ADMIN
  ]
};

export const ROLES = [USER_ROLE, ADMIN_ROLE, SUPER_ROLE, TED_ROLE];

export const getPermissions = roleName =>
  (roleName && ROLE_PERMISSIONS[roleName]) || [];

export const getRoleLevel = roleName => {
  const role = ROLES.find(r => r.name === roleName);
  return role && role.level;
};

export const getRoleLevelMax = roleName => {
  const role = ROLES.find(r => r.name === roleName);
  return role && role.maxLv;
};

export const getRoleId = roleName => {
  const role = ROLES.find(r => r.name === roleName);
  return role && role.id;
};

export const isTEDAdmin = user =>
  user && user.role && user.role === TED_ROLE.name;

export default ROLES;
