import _get from "lodash.get";
import useCurrentUser from "hooks/useCurrentUser";
import { ROLE_PERMISSIONS } from "resources/users/roles";

import {
  TED_ADMIN,
  CAN_CREATE_GROUPS,
  CAN_CANCEL_USERS,
  CAN_CREATE_USERS,
  CAN_DELETE_USERS,
  CAN_VIEW_USERS,
  CAN_CREATE_ORGS,
  CAN_EDIT_GROUPS,
  CAN_EDIT_ORGS,
  CAN_EDIT_USERS,
  CAN_VIEW_GROUPS,
  CAN_VIEW_ORGS,
  CAN_VIEW_LESSON_RATINGS
} from "resources/users/roles";

export * from "resources/users/roles";

const usePermissions = () => {
  const {
    role,
    adminReadonly,
    loading,
    error,
    organization
  } = useCurrentUser();
  const permissions = (role && ROLE_PERMISSIONS[role]) || [];

  const allowed = rule => permissions.includes(rule);

  const canCreateOrgs = allowed(CAN_CREATE_ORGS) && !adminReadonly;
  const canEditOrgs = allowed(CAN_EDIT_ORGS) && !adminReadonly;
  const canViewOrgs = allowed(CAN_VIEW_ORGS);

  const canCreateGroups = allowed(CAN_CREATE_GROUPS) && !adminReadonly;
  const canEditGroups = allowed(CAN_EDIT_GROUPS) && !adminReadonly;
  const canViewGroups = allowed(CAN_VIEW_GROUPS);

  const canCreateUsers = allowed(CAN_CREATE_USERS) && !adminReadonly;
  const canEditUsers = allowed(CAN_EDIT_USERS) && !adminReadonly;
  const canCancelUsers = allowed(CAN_CANCEL_USERS) && !adminReadonly;
  const canDeleteUsers = allowed(CAN_DELETE_USERS) && !adminReadonly;
  const canViewUsers = allowed(CAN_VIEW_USERS);

  const canViewLessonRatings = allowed(CAN_VIEW_LESSON_RATINGS);

  const isEduOrg = _get(organization, "organizationType") === "edu";
  const isTedAdmin = allowed(TED_ADMIN);

  return {
    permissions,
    adminReadonly,
    canViewLessonRatings,
    canCancelUsers,
    canCreateGroups,
    canCreateOrgs,
    canCreateUsers,
    canDeleteUsers,
    canEditGroups,
    canEditOrgs,
    canEditUsers,
    canViewGroups,
    canViewOrgs,
    canViewUsers,
    isTedAdmin,
    isEduOrg,
    loading,
    error
  };
};

export default usePermissions;
