import React from "react";
import gql from "graphql-tag";
import _get from "lodash.get";
import { useQuery } from "@apollo/react-hooks";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Skeleton from "@material-ui/lab/Skeleton";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import DateText from "ui/DateText";
import VideoModalBtn from "ui/datagrid/VideoModalButton";

import useMenuAnchor from "hooks/useMenuAnchor";

export const GET_USER_VIDEOS = gql`
  query getUserVideos($id: Int!) {
    user: User(id: $id) {
      email
      firstName
      lastName
      videos {
        id
        title
        url
        createdAt
      }
    }
  }
`;

const useStyles = makeStyles({
  table: {
    margin: 20,

    "& .MuiTableCell-root": {
      padding: 8
    }
  }
});

export const VideoTable = ({ count, userId }) => {
  const classes = useStyles();
  const { loading, data } = useQuery(GET_USER_VIDEOS, {
    skip: !userId,
    variables: {
      id: userId
    }
  });
  const skeletons = [...Array(Math.min(count, 10))];
  const user = _get(data, "user", {});
  const videos = _get(data, "user.videos", []);

  return (
    <Table
      padding="none"
      classes={{
        root: classes.table
      }}
    >
      {loading && skeletons.map((_, idx) => <Skeleton key={idx} />)}
      {!loading &&
        videos
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map(({ id, createdAt, ...props }) => (
            <TableRow key={id}>
              <TableCell width={100}>
                <DateText date={createdAt} />
              </TableCell>
              <TableCell>
                <VideoModalBtn createdAt={createdAt} user={user} {...props} />
              </TableCell>
            </TableRow>
          ))}
    </Table>
  );
};

export const VideosCell = ({ count, userId }) => {
  const { menuProps, openMenu } = useMenuAnchor();

  return (
    <>
      <Button onClick={openMenu} disabled={!count}>
        {count}
      </Button>
      <Popover
        {...menuProps}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <VideoTable count={count} userId={userId} />
      </Popover>
    </>
  );
};

VideosCell.defaultProps = {
  count: 0
};

export default VideosCell;
