import React from "react";

import FieldDisplay from "ui/FieldDisplay";
import TextInput from "ui/TextInput";

import useStylesUtil from "hooks/useStylesUtil";

export const ImageFieldDisplay = ({ image, label }) => {
  const classes = useStylesUtil();
  return (
    <FieldDisplay label={label}>
      {image ? (
        <img
          src={image}
          alt={image}
          className={classes.w100}
          style={{ maxWidth: "250px" }}
        />
      ) : (
        "-"
      )}
    </FieldDisplay>
  );
};

const ImageInput = ({ editing, label, image, ...props }) => {
  const ImageDisplay = <ImageFieldDisplay label={label} image={image} />;

  if (!editing) {
    return ImageDisplay;
  }

  return (
    <>
      {!!image ? ImageDisplay : null}
      <TextInput
        type={"file"}
        inputProps={{
          accept: "image/*"
        }}
        label={!!image ? `Replace ${label}` : label}
        {...props}
      />
    </>
  );
};

export default ImageInput;
