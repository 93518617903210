import { palette } from "./settings";

export const MuiIconButton = {
  root: {
    flex: "0 0 auto",
    width: 48,
    color: palette.text.primary,
    height: 48,
    padding: 0,
    fontSize: 24,
    textAlign: "center",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "50%",

    "&$disabled": {
      color: palette.text.disabled,
      opacity: 0.5
    }
  }
};
