import React from "react";
import _get from "lodash.get";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import useTranslate from "hooks/useTranslate";

const FilterByOption = ({
  InputProps,
  filterFieldProps,
  options,
  optionLabel,
  optionValue,
  style
  // emptyLabel
}) => {
  const { translate } = useTranslate();
  const selected = _get(filterFieldProps, "value", "");
  const getLabel = o =>
    typeof optionLabel === "function"
      ? optionLabel({ value: o, translate })
      : o;
  const getValue = o =>
    typeof optionValue === "function" ? optionValue(o) : o;

  const uniqueList = [...new Set(options.map(getValue))].map(value =>
    options.find(o => getValue(o) === value)
  );

  return (
    <TextField
      style={{ minWidth: "15ch" }}
      {...filterFieldProps}
      InputProps={InputProps}
      value={selected}
      select
      // SelectProps={{
      //   displayEmpty: !!emptyLabel
      // }}
    >
      {/* {emptyLabel && <MenuItem value={""}>{emptyLabel}</MenuItem>} */}
      {uniqueList.map(option => (
        <MenuItem key={getValue(option)} value={getValue(option)}>
          {getLabel(option)}
        </MenuItem>
      ))}
    </TextField>
  );
};

FilterByOption.defaultProps = {
  filterFieldProps: {},
  options: []
};

export default FilterByOption;
