import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Button, FormHelperText } from "@material-ui/core";

import useTranslate from "hooks/useTranslate";

import { bulkImportAction } from "./actions";

export const BulkImportActions = ({
  contractId,
  bulkImportAction: bulkImport,
  classes,
  onCancel,
  file,
  onSave,
  resource
}) => {
  const { translate } = useTranslate();

  if (!contractId) {
    return <FormHelperText error>You must select a contract.</FormHelperText>;
  }

  return (
    <>
      <Button
        variant={"outlined"}
        onClick={onCancel}
        className={classes.cancel}
      >
        {translate("ra.action.cancel")}
      </Button>
      <Button
        className={classes.validate}
        variant="outlined"
        onClick={() => {
          bulkImport(resource, {
            contractId,
            file: file,
            saveUsers: false
          });
          onSave(file);
        }}
      >
        {translate("import.validate_file")}
      </Button>
      <Button
        data-test="save"
        className={classes.submit}
        color="secondary"
        variant="contained"
        onClick={() => {
          bulkImport(resource, {
            contractId,
            file: file,
            saveUsers: true,
            inviteNow: false
          });
          onSave(file);
        }}
      >
        {translate("resources.users.create.import.save_label")}
      </Button>
      <Button
        data-test="save-and-invite"
        className={classes.submit}
        color="secondary"
        variant="contained"
        onClick={() => {
          bulkImport(resource, {
            contractId: contractId,
            file: file,
            saveUsers: true,
            inviteNow: true
          });
          onSave(file);
        }}
      >
        {translate("resources.users.create.import.save_and_invite_label")}
      </Button>
    </>
  );
};

BulkImportActions.defaultProps = {
  resource: "users",
  classes: {
    cancel: "cancel",
    validate: "validate",
    submit: "submit"
  }
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  validate: {
    marginLeft: 8
  },
  submit: {
    marginLeft: 8
  }
});

const enhance = compose(
  withStyles(styles, { name: "BulkImport" }),
  connect(null, { bulkImportAction })
);

export default enhance(BulkImportActions);
