import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import _get from "lodash.get";

import UserStatItem from "insights/UserStatItem";

import useNotifcation from "hooks/useNotification";

export const GET_ORG_STATS = gql`
  query getOrgStats($id: Int!) {
    cohort: Organization(id: $id) {
      id
      availableSeats
      averageLessonsCompleted
      totalLessonsCompleted
      used: usersCount
    }
  }
`;

export const GET_CONTRACT_STATS = gql`
  query getContractStats($id: Int!) {
    cohort: Contract(id: $id) {
      id
      availableSeats
      averageLessonsCompleted
      totalLessonsCompleted
      used: usersCount
    }
  }
`;

export const UserCohortStats = ({ organizationId, contractId }) => {
  const { showGraphQLError } = useNotifcation();
  const query = contractId ? GET_CONTRACT_STATS : GET_ORG_STATS;
  const id = contractId || organizationId;
  const { loading, data } = useQuery(query, {
    onError: showGraphQLError,
    skip: !id,
    variables: { id }
  });

  if (!organizationId && !contractId) {
    return null;
  }

  return (
    <>
      <UserStatItem
        loading={loading}
        name="available"
        count={_get(data, "cohort.availableSeats", "?")}
      />
      <UserStatItem
        loading={loading}
        name="used"
        count={_get(data, "cohort.used", "?")}
      />
      <UserStatItem
        loading={loading}
        name="average"
        count={_get(data, "cohort.averageLessonsCompleted", "?")}
      />
      <UserStatItem
        loading={loading}
        name="total"
        count={_get(data, "cohort.totalLessonsCompleted", "?")}
      />
    </>
  );
};

export default UserCohortStats;
