export const USERS = "users";
export const ORGS = "organizations";
export const GROUPS = "groups";

export const resourceTypeName = resource => {
  switch (resource) {
    case USERS:
      return "User";

    case ORGS:
      return "Organization";

    case GROUPS:
      return "Group";

    default:
      throw new Error(`Unknown resource ${resource}`);
  }
};
