import { createMiddleware } from "redux-beacon";
import GoogleTagManager from "@redux-beacon/google-tag-manager";

import { LOCATION_CHANGE } from "react-router-redux";
import { CSV_UPLOAD } from "actions";

export const trackPageView = action => ({
  event: "pageView",
  ...action.payload
});

export const trackCSVUpload = ({ payload: { valid } }) => ({
  event: "csvUploaded",
  validCSV: valid
});

const eventsMap = {
  [LOCATION_CHANGE]: trackPageView,
  [CSV_UPLOAD]: trackCSVUpload
};

const gtm = GoogleTagManager();

export const gtmMiddleware = createMiddleware(eventsMap, gtm);
