import React from "react";
import _get from "lodash.get";

import FilterByOption from "ui/filters/FilterByOption";

export const booleanLabel = ({ translate, value }) =>
  translate(`ra.boolean.${value}`);

const FilterByBoolean = props => (
  <FilterByOption {...props} optionLabel={booleanLabel} />
);

export default FilterByBoolean;
