import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import PlusIcon from "react-feather/dist/icons/plus";

export const BulkActionButton = ({
  classes,
  children,
  Icon,
  title,
  ...props
}) => (
  <Tooltip title={title}>
    <div>
      <IconButton {...props}>
        <Icon />
      </IconButton>
    </div>
  </Tooltip>
);

BulkActionButton.defaultProps = {
  Icon: PlusIcon
};

export default BulkActionButton;
