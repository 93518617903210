import React, { cloneElement } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import FormFieldbox from "ui/form/FormFieldbox";
import useTranslate from "hooks/useTranslate";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 24,
    marginBottom: 24
  },
  title: {
    marginTop: 0
  },
  meta: {
    width: 290 + 30,
    paddingRight: 30,
    background: "transparent"
  },
  desc: {
    marginTop: 12
  },
  content: {
    width: `calc(100% - ${290 + 30}px)`,
    maxWidth: 960
  }
});

export const MetaBox = ({ title, desc, MetaContent }) => {
  const { translate } = useTranslate();
  const classes = useStyles();

  return (
    <Paper component="aside" elevation={0} className={classes.meta}>
      {title && (
        <Typography variant="subtitle1" className={classes.title}>
          {translate(title)}
        </Typography>
      )}
      {desc && (
        <Typography component="p" variant="caption" className={classes.desc}>
          {translate(desc)}
        </Typography>
      )}
      {MetaContent && cloneElement(MetaContent)}
    </Paper>
  );
};

export const FormRow = ({ children, fieldBox, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MetaBox classes={classes} {...props} />
      <div className={classes.content}>
        {!!children && fieldBox && <FormFieldbox>{children}</FormFieldbox>}
        {!fieldBox && children}
      </div>
    </div>
  );
};

FormRow.defaultProps = {
  fieldBox: true
};

export default FormRow;
