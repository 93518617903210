import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SortIcon = ({ viewBox, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 16 10">
    <path fill="currentColor" d="M0 0h16v2H0zM4 4h8v2H4zM6 8h4v2H6z" />
  </SvgIcon>
);

export const SortIconInvert = ({ viewBox, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 16 10">
    <path
      fill="currentColor"
      d="M0 0h16v2H0zM4 4h8v2H4zM6 8h4v2H6z"
      transform="scale(-1)"
      transform-origin="center"
    />
  </SvgIcon>
);

export default SortIcon;
