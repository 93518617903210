import React from "react";

import Link from "ui/Link";

const OrganiztionCell = ({ id, name, fallback }) => (
  <Link to={`/organizations/${id}`}>{name || fallback}</Link>
);

OrganiztionCell.defaultProps = {
  fallback: "[ no name ]"
};

export default OrganiztionCell;
