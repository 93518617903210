import englishMessages from "ra-language-english";

export default {
  ...englishMessages,
  days: "days",
  ra: {
    ...englishMessages.ra,
    navigation: {
      page_rows_per_page: "rows per page",
      page_range_info: "Showing %{from} - %{to} of %{total} Results"
    }
  },
  filters: {
    contractId: "Contract",
    contractStatus: "Contract Status",
    cycleCount: "Cycle",
    daysSinceSubmitted: "Days Since Submitted",
    entryId: "Lesson Id",
    filterBy: "Filter by",
    groupIds: "Group",
    hasComment: "Review has comment",
    inactiveWeeks: "Inactive more than __ weeks",
    invitedBeforeWeeksAgo: "Invited more than __ weeks ago",
    lessonsCompleted: "Lessons Completed",
    lessonId: "Lesson Id",
    lessonRating: "Lesson Rating",
    organizationId: "Organization",
    organizationType: "Organization Type",
    purchasePlatform: "Purchase Platform",
    purchaseStatus: "Purchased",
    rating: "Rating",
    registrationCountry: "Registration Country",
    registrationPlatform: "Registration Platform",
    role: "Role",
    selectOrg: "Select an organization",
    status: "Status",
    submittedDaysAgo: "Submitted",
    lessonsCompleted: "Lessons completed more than __",
    totalLessonsCompleted: "Total Lessons completed more than __",
    videosGreaterThan: "Videos more than __",
    videosPresent: "Videos Present"
  },
  menu: {
    help: "Email TED for help",
    review: "Review",
    resources: "Resources",
    new_org: "New organization",
    new_user: "New user",
    ariaLabel: "Main Menu",
    community: "Community"
  },
  ui: {
    addToGroups: "Add to Group(s)",
    currentOrgText: "You are managing",
    logout: "logout",
    export: "Download csv of all %{name}",
    exportFiltered: "Download csv of %{name}",
    exportStarted:
      "Export started. You will receive email with the completed export file.",
    inactive: "Not Active",
    inviteAll: "Invite All Users",
    inviteAllSuccess:
      "Invites have been sent to all pending and invited users.",
    inviteAllConfirmTitle:
      "Are you sure you want to send an invite to all %{count} users in this Organization?",
    inviteAllConfirmMsg:
      "Confirming will send an invite email to all pending or invited users in this organization.",
    resetPassword: "Password reset",
    resendInvite: "Send invite |||| Send invites",
    cancelInvite: "Cancel invite |||| Cancel invites",
    viewEmails: "Get Email Address |||| Get Email Addresses",
    na: "N/A",
    completed: "completed",
    save: "Save Changes",
    select_account: "Select an account",
    switch_account_success: "You have switched accounts.",
    switch_account_failure: "Failed to switch accounts.",
    status: {
      undefied: "-",
      null: "-",
      pending: "Pending",
      inactive: "Inactive",
      active: "Active",
      invited: "Invited"
    },
    contact_ted_btn: "Contact TED",
    loginTitle: "Administrator Portal",
    filterOrgNames: "Org name",
    allOrgs: "All Organizations",
    pleaseSelectOrg: "Please select an organization.",
    noCrossOrgStats:
      "User stats across all organizations are not currently available.",
    close: "close",
    copyToClipboard: "copy to clipboard"
  },
  import: {
    success:
      "CSV uploaded. You will receive email notification when user importing is complete.",
    validate_file_start:
      "CSV uploaded. You will receive email notification when file validation has completed.",
    guide_help:
      "Note: row 1 will be read as a header, so user data should be in row 2 and below.",
    guide_text: "Download a template for guidance.",
    reject_file: "File type must be csv.",
    accept_file: "Upload file.",
    validate_file: "Validate file",
    upload_text: "Drop a csv file here or click here to upload.",
    error: "The file you uploaded is invalid.",
    duplicate: "Duplicate value '%{value}' found. (row %{row})",
    error_row: "%{label}: '%{value}' is invalid. (row %{row})",
    error_missing: "One or more values is missing. (row %{row})",
    email: "Email",
    first_name: "First Name",
    last_name: "Last Name",
    missingColumn: "Missing Column",
    wrong_header:
      "The header must match the template format %{match}. (row 1) Please see the guide for details."
  },
  auth: {
    invalid: "Invalid username or password.",
    denied: "Permission denied.",
    unknown: "An unkown action was submitted.",
    logout: "You have been logged out."
  },
  errors: {
    validation: {
      future_date: "Please select a current or future date."
    },
    seat_limit: {
      title: "It seems you have reached your seat limit.",
      text:
        "The amount of seats requested exceeds your total allotted seats.\nPlease reach out to TED for help."
    },
    noActiveOrg: {
      title: "Error: no active organization.",
      message: "Please choose an organization to manage:",
      selectOrgLabel: "Select an organization"
    },
    graphQL: "Error! The server request was rejected:"
  },
  userGroups: {
    user: "User",
    admin: "Admin",
    super_admin: "Super Admin",
    ted_admin: "Ted Admin"
  },
  resources: {
    organizations: {
      autoUsersInactive: {
        title: "Automatic User Expiration",
        desc:
          "This information will determine if individual users will be automatically set to inactive. If this is set-up, then each user within the organization will lose access to TED Masterclass a set amount of days after they are invited.",
        help:
          "Enable this option if you want users in this organization to be automatically set to inactive a set number of days after they are invited",
        must_enable: "You must enable this option to activate."
      },
      name: "Organization |||| Organizations",
      new_org_title: "New Organization",
      single: "Organization",
      plural: "Organizations",
      create_confirm_cancel_title: "Cancel creation of Organization?",
      create_confirm_cancel_text:
        "If you leave this page you will lose all progress on creating this Organization. Are you sure you want to leave?",
      create_button: "Create organization",
      create_confirm_cancel_yes: "Yes, cancel new organization",
      create_confirm_cancel_no: "No, continue editing",
      create: {
        title: "Create organization",
        save: "Create organization"
      },
      confirm_set_auto_expire:
        "If you make this change, %{usersCount} user(s) will lose access. Do you want to continue?",
      confirm_delete_org: "Are you sure you want to delete this Organization?",
      detail: {
        title: "Organization details",
        desc:
          "This information will be used to contact the organization and will appear on invoices."
      },
      seats_text: "%{usersCount} of %{seats}",
      list: {
        type: "Type"
      },
      filter: "Organization",
      fields: {
        activationDate: "Activation Date",
        autoUsersInactive: "Automatically expire users",
        usersActiveForDays: "Number of days users will be active",
        administrativeArea: "State/Province",
        countryCode: "Country",
        address1: "Street",
        address2: "Apt, suite, etc. (optional)",
        city: "City",
        endDate: "End Date",
        invoiceNumber: "Order form number",
        name: "Organization name",
        orgTypeShort: "Type",
        organizationType: "Type of organization",
        "parentOrganization.id": "Parent Organization",
        postalCode: "Postal/ZIP code",
        primaryContact: "Professional Learning Leader",
        related_org: "Related organization",
        seats: "Seats",
        seats_new: "Number of seats available",
        seatsAvailableShort: "Seats Available",
        seats_label: "of",
        status: "Status"
      },
      contact: {
        title: "Professional Learning Leader(s)",
        desc:
          "This information will be used to contact the main admininstrator of the organization’s account."
      },
      liscense: {
        title: "License details",
        desc:
          "This information to understand the details of the contract with the organization. Dates are set for midnight UTC time."
      },
      types: {
        null: "Unknown",
        null_short: "?",
        corp: "Corporate",
        corp_short: "Corp.",
        edu: "Education",
        edu_short: "Edu.",
        st_leaders: "ST Leaders",
        st_leaders_short: "STL",
        tedx: "TEDx",
        tedx_short: "TEDx",
        ted2020: "TED2020",
        ted2020_short: "TED2020"
      },
      notification: {
        org_deleted: "Organization has been deleted.",
        org_created: "Successfully created organization",
        org_updated: "Organization has been updated."
      },
      confirm_create_inactive: `Are you sure you want to set this Org to "Inactive" after its start date?`
    },
    users: {
      name: "User |||| Users",
      single: "User",
      plural: "Users",
      filters: {
        invitedBeforeWeeksAgo: "Invited more than __ weeks ago",
        orgType: "Organization Type",
        role: "User Role",
        videosGreaterThan: "Videos more than __"
      },
      fields: {
        cycleCount: "Cycle",
        email: "User's email",
        emailPlain: "Email",
        firstName: "First Name",
        groups: "Groups",
        groupIdsHelp: "Add all that apply",
        inactiveWeeks: "Inactive more than __ weeks",
        invitedAt: "Invited",
        name: "Name",
        lastActive: "Last active",
        lastName: "Last Name",
        purchaseStatus: "Purchased",
        progress: "Progress",
        role: "Role",
        videos: "Talk Uploads",
        totalLessonsCompleted: "Total Lessons",
        status: "Status",
        video_counts: "%{smart_count} talk |||| %{smart_count} talks"
      },
      edit: {
        title: "User information",
        course_progress: "User's course progress",
        last_active: "Last active",
        partner: "Partner"
      },
      create: {
        title: "New User",
        desc: "Create users individually.",
        save: "Create User",
        save_and_invite: "Create and Invite User",
        invite_single: "Create users individually",
        import: {
          title: "Upload users in bulk",
          desc: "Upload a CSV to import users in bulk.",
          save_label: "Create users",
          save_and_invite_label: "Create and Invite Users"
        }
      },
      actions: {
        delete: "Delete User |||| Delete Users",
        confirm_delete:
          "Are you sure you want to delete this user? |||| Are you sure you want to delete these users?"
      },
      notification: {
        reset_password_success: "A password reset link has been sent.",
        reset_password_error: "Unable to send password reset.",
        resend_invite_success: "Invite email has been sent.",
        resend_invite_bulk_success:
          "An email invite has been sent to pending and invited users only.",
        resend_invite_error: "Unable to send invite email.",
        bulk_import_success: "Successfully imported users.",
        bulk_import_error:
          "There was a problem with your file. Please check the file and try again.",
        create_success: "User created and invitation sent.",
        create_only_success: "User created.",
        update_success: "User edits saved.",
        bulk_import_missing_name: "One or more users is missing a name.",
        bulk_import_missing_email:
          "One or more users is missing an email address.",
        cancelled:
          "User invite cancelled and user removed from the organization.",
        cancelled_bulk:
          "For invited users only, user invite cancelled and user removed from the organization.",
        delete: "User invite cancelled and user removed from the organization.",
        delete_many:
          "User invites cancelled and users removed from organizations.",
        copy_emails_title: "Selected user email addresses",
        copy_emails_success: "User emails have been copied to your clipboard."
      }
    },
    contacts: {
      add_new: "Additional contact",
      remove_warning:
        "Are you sure you want to remove this contact? This action cannot be undone.",
      remove_confirm: "Remove contact",
      fields: {
        primary: "Primary",
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        phone: "Phone"
      }
    },
    throughlines: {
      name: "Throughline |||| Throughlines",
      single: "Throughline",
      plural: "Throughlines",
      fields: {
        submitted: "Submitted"
      }
    }
  },
  contracts: {
    activation_title: "Activation details",
    activation_desc:
      "This information will set the activation time for the organization. Dates are set for midnight UTC time.",
    add_user: "Add user to contract",
    autoExpire_title: "Automatic User Expiration",
    autoExpire_desc:
      "This information will determine if individual users will be automatically set to inactive. If this is set-up, then each user within the organization will lose access to TED Masterclass a set amount of days after they are invited.",
    create: "Create contract",
    create_success: "Contract has been created.",
    communityAccess_title: "Community",
    communityAccess_desc: "The ability to access the TED-Ed Educator Hub.",
    details_title: "Contract details",
    details_desc:
      "This information is to understand the details of the contract with the organization. Dates are set for midnight UTC time.",
    email_list_import: "Import Allow List",
    email_list_import_confirm_text:
      "Running an import will replace any existing emails. The lists will not be merged.",
    email_list_import_title: "Select a csv file to import",
    email_list_import_success:
      "Imported started. You will receive an email when the import has completed.",
    filterLabel: "Contract",
    inviteEmail_title: "Invitation Email",
    inviteEmail_desc: "Customize the user invition email.",
    inviteRequests_title: "Direct Sign-Up Page",
    inviteRequests_desc:
      "If enabled, this will provide a page url where users can request invites to the app. A list of allowed user emails must be provided.",
    plural: "Contracts",
    single: "Contract",
    search: "Search contracts",
    settings_desc:
      "This information will show what specific settings the participants in this organization will experience.",
    settings_title: "Settings details",
    update_success: "Contract has been updated.",
    talkUploads_title: "Talk Uploads",
    talkUploads_desc:
      "The ability to share video uploads with their learning leader."
  },
  insights: {
    title: "Insights",
    userBox: {
      available: "Total seats available",
      available_count: "%{count} seats",
      used: "Total seats used",
      used_count: "%{count} users",
      invited: "Invited (not logged in yet)",
      invited_count: "%{count} users",
      active: "Active users",
      active_count: "%{count} users",
      average: "Average completed lessons across active users",
      average_count: "%{count} lessons",
      completed: "Users who completed course (>= 7 lessons complete)",
      completed_count: "%{count} users",
      total: "Total completed lessons across active users",
      total_count: "%{count} lessons",
      seeAll: "See all users"
    }
  },
  lessonRatings: {
    average: "Average Rating",
    count: "Count of Ratings",
    title: "Lesson Rating |||| Lesson Ratings",
    titleSingle: "Lesson Rating",
    plural: "Lesson Ratings",
    entryId: "Lesson Id",
    rating: "Rating",
    comment: "Comment",
    updatedAt: "Submitted",
    overall: "Overall"
  },
  purchaseStatus: {
    not_purchased: "Not Purchased",
    purchased: "Purchased",
    refunded: "Refunded"
  },
  fields: {
    activationDate: "Activation Date",
    autoUsersInactive: "Automatically expire users",
    address: "Address",
    contract_name: "Contract Name",
    email: "Email",
    endDate: "End Date",
    firstName: "First name",
    group: "Group",
    invoiceNumber: "Invoice Number",
    inviteEmailFooterImage: "Email Footer Image",
    inviteRequestDisabledText: "Disabled Text",
    inviteRequestsEnabled: "Enable Direct Sign-Up",
    inviteRequestEmailsRequired: "Use allow list for direct sign-up emails",
    inviteRequestIntroText: "Intro Text",
    inviteRequestRoleRequired: "Require Role",
    inviteRequestType: "Type",
    lastName: "Last name",
    lessonsCompletedAverage: "Avg. completed lessons",
    phone: "Phone",
    primary: "Primary",
    organization: "Organization",
    organizationSelect: "Select an Organization",
    seats: "Seats",
    startDate: "Start Date",
    status: "Status",
    statusNote: "Status Note",
    usersActiveForDays: "Set users to inactive after",
    usersCount: "Number of users"
  },
  groups: {
    create: "Create Group",
    create_success: "Group created.",
    delete: "Group has been deleted.",
    delete_confirm: "Are you sure you want to delete this group?",
    delete_success: "Group removed.",
    filter: "Filter by Group",
    name: "Group |||| Groups",
    edit: "Manage Group",
    plural: "Groups",
    search: "Search groups",
    single: "Group",
    update_success: "Group updated."
  },
  talks: {
    title: "Talks",
    fields: {
      title: "Title",
      createdAt: "Submitted",
      entryUrl: "Entry Url"
    }
  },
  users: {
    bulk_cancel_invite: "Cancel invite |||| Cancel invites",
    bulk_copy_emails:
      "Copy selected email address |||| Copy selected email addresses",
    bulk_delete_users: "Delete User |||| Delete Users",
    bulk_resend_invite: "Send invite |||| Send invites",
    create: "Create user",
    cancel_invite_confirm: "Are you sure you want to cancel this invite?",
    cancel_invite_success:
      "User invite cancelled and user removed from the organization.",
    cancel_invite_bulk_confirm:
      "Are you sure you want to cancel these invites?",
    cancel_invite_bulk_success:
      "For invited users only, user invite cancelled and user removed from the organization.",
    delete: "User invite cancelled and user removed from the organization.",
    delete_confirm: "Are you sure you want to delete these users?",
    delete_success:
      "User invites cancelled and users removed from organizations.",
    expirationWarning:
      "Warning! This change will cause %{users} to lose access.",
    invite_bulk_confirm:
      "Are you sure you want to resend the following invites?",
    invite_bulk_success:
      "An email invite has been sent to pending and invited users only.",
    missing_name: "[ no name ]",
    plural: "Users",
    search: "Search Users",
    single: "User",
    smartCount: "%{smart_count} user |||| %{smart_count} users"
  },
  video: {
    errorTitle: "Error: Failed to load video"
  },
  validation: {
    dateCompare_gt: "Must be after %{date}.",
    dateCompare_gte: "Must be on or after %{date}.",
    dateCompare_lt: "Must be before %{date}.",
    dateCompare_lte: "Must be on or before %{date}.",
    required: "This field is required.",
    minLength: "Must be at least %{min} characters.",
    maxLength: "Must be %{max} characters or less.",
    minValue: "Must be at least %{min}.",
    maxValue: "Must be %{max} or less.",
    inValidDate: "Must be a valid date.",
    inavlidDateField: "%{fieldName} must be a valid date.",
    number: "Must be a number.",
    positive: "Must be a number greater than 0.",
    email: "Must be a valid email.",
    oneOf: "Must be one of: %{options}.",
    regex: "Must match a specific format (regexp): %{pattern}.",
    futureDate: "Please select a current or future date."
  },
  headerCell: {
    avilable_slash_total_seats: "Available / Total Seats",
    contractName: "Contract name",
    contracts: "# of Contracts",
    contractsCount: "# of Contracts",
    cycleCount: "Cycle",
    endDate: "End Date",
    email: "Email",
    id: "ID",
    invitedAt: "Invited",
    invoiceNumber: "Invoice #",
    group: "Group",
    groups: "Groups",
    groupSize: "Partner Mode",
    lastActive: "Last Active",
    lessonsCompleted: "Progress",
    lessonsCompletedAverage: "Avg. completed lessons",
    name: "Name",
    nameEmail: "Name/Email",
    organizationName: "Organization name",
    organizationType: "Organization Type",
    organizationType_short: "Org Type",
    pll: "PLL",
    purchasePlatform: "Purchase Platform",
    purchaseStatus: "Purchased",
    registrationCountry: "Registration Country",
    registrationPlatform: "Registration Platform",
    role: "Role",
    seats: "Seats",
    startDate: "Start Date",
    status: "Status",
    submitted: "Submitted",
    title: "Title",
    throughlines: "Throughlines",
    totalLessonsCompleted: "Total Lessons",
    usersCount: "Number of users",
    videosCount: "Talk Uploads"
  },
  inviteRequestType: {
    educator: "Educator",
    non_educator: "Non-Educator",
    tedx_organizer: "TEDx Organizer",
    tedx_speaker: "TEDx Speaker"
  }
};
