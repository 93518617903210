import { buttonStyles, palette } from "./settings";

export const MuiButton = {
  root: {
    textTransform: "none",
    fontWeight: 400,
    borderRadius: 3,
    "&:hover": {
      backgroundColor: "transparent",
      color: palette.secondary.main,
      textDecoration: "underline"
    }
  },
  outlined: {
    background: buttonStyles.bgGradient,
    boxShadow: buttonStyles.boxShadow,
    border: buttonStyles.border,

    "&$colorInherit": {
      background: "transparent",
      borderColor: palette.text.light
    },

    ".caution &:hover": {
      color: palette.caution.dark
    },

    ".error &:hover": {
      color: palette.error.dark
    }
  },
  contained: {
    boxShadow: "none",
    minWidth: 168
  },
  containedSecondary: {
    "&:hover": {
      color: palette.common.white
    }
  }
};
