import React from "react";
import { palette } from "theme/settings";

import { makeStyles } from "@material-ui/core/styles";

export const core = 7;

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 4,
    paddingBottom: 4,
    width: 120
  },
  bar: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    backgroundColor: palette.background.shaded
  },
  completed: {
    display: "block",
    height: 16,
    backgroundColor: palette.secondary.main
  },
  label: {
    display: "block",
    paddingLeft: 4,
    paddingRight: 4,
    textAlign: "center",
    color: palette.text.light
  }
});

export const LessonsCompletedCell = ({ lessonsCompleted }) => {
  const classes = useStyles();
  const completed = Math.min(lessonsCompleted, core);

  return (
    <div className={classes.root}>
      <span className={classes.bar}>
        <span
          data-test="bar"
          className={classes.completed}
          style={{
            width: `${Math.floor((completed / core) * 100)}%`
          }}
        />
      </span>
      <span className={classes.label}>{`${completed}/${core} `}</span>
    </div>
  );
};

export default LessonsCompletedCell;
