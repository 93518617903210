import { typography } from "./settings";

export const MuiTypography = {
  h1: {
    fontSize: 24,
    lineHeight: 30 / 24,
    fontWeight: 900
  },
  h4: {
    fontSize: 18,
    lineHeight: 1.11,
    fontWeight: 600
  },
  h5: {
    fontSize: 16,
    lineHeight: 24 / 16,
    fontWeight: 600
  },
  h6: {
    fontSize: 14,
    lineHeight: 22 / 14,
    fontWeight: 700,
    letterSpacing: "-0.4px"
  },
  subtitle1: {
    fontSize: 16,
    lineHeight: 24 / 16,
    marginTop: 16,
    fontWeight: 500
  },
  body1: {
    fontSize: 14,
    lineHeight: 22 / 14,
    fontWeight: 500,
    letterSpacing: "-0.4px"
  },
  caption: {
    fontSize: 14,
    lineHeight: 20 / 14,
    color: "#637381"
  }
};

// https://material-ui.com/guides/migration-v3/#typography
export const MuiTypographyLegacy = {
  title: {
    margin: 0,
    padding: 0
  },
  headline: {
    fontSize: typography.fontSize * 1.29,
    lineHeight: 1.11,
    fontWeight: 600
  },
  subheading: {
    fontSize: typography.fontSize,
    marginTop: 16
  }
};
