import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";

import { adminSaga, USER_LOGOUT } from "react-admin";
import formMiddleware from "./middleware/form";
import { gtmMiddleware } from "./middleware/googleTagManager";

import authProvider from "./authProvider";
import i18nProvider from "./i18n/en";

import reducer from "./reducer";

export const initialState = {};

export /* istanbul ignore next */ const reduxDevTools =
  typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__({ serialize: true, trace: true })
    : f => f;

export const resettableAppReducer = (state, action) =>
  reducer(action.type !== USER_LOGOUT ? state : undefined, action);

export function* rootSaga(dataProvider) {
  yield all([adminSaga(dataProvider, authProvider, i18nProvider)].map(fork));
}

export default ({ history, dataProvider }) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = compose(
    applyMiddleware(
      sagaMiddleware,
      formMiddleware,
      routerMiddleware(history),
      gtmMiddleware
    ),
    reduxDevTools
  );
  const store = createStore(resettableAppReducer, initialState, middleware);

  sagaMiddleware.run(rootSaga, dataProvider);
  return store;
};
