import React, { useContext } from "react";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import { DataTableContext } from "./DataTableProvider";

import useTranslate from "hooks/useTranslate";
import useStylesUtil from "hooks/useStylesUtil";

const HeaderCell = ({ sortable, id, label }) => {
  const { visuallyHidden } = useStylesUtil();
  const { translate } = useTranslate();
  const { sortField, setSortField, sortOrder, setSortOrder } = useContext(
    DataTableContext
  );
  const heading = label || translate(`headerCell.${id}`);
  const active = id === sortField;
  const order = sortOrder.toLowerCase();

  const changeSort = () => {
    if (sortField === id) {
      setSortOrder(order === "asc" ? "DESC" : "ASC");
      return;
    }

    setSortOrder("ASC");
    setSortField(id);
  };

  if (sortable === false) {
    return <TableCell>{heading}</TableCell>;
  }

  return (
    <TableCell sortDirection={active ? order : false} data-field={id}>
      <TableSortLabel
        active={active}
        direction={active ? order : "asc"}
        onClick={changeSort}
      >
        {heading}
        {active && (
          <span className={visuallyHidden}>{`ui.sort${order}Aria`}</span>
        )}
      </TableSortLabel>
    </TableCell>
  );
};

const DataTableHeader = ({ columns }) => (
  <TableHead>
    <TableRow>
      {columns.map(col => (
        <HeaderCell {...col} key={col.id} />
      ))}
    </TableRow>
  </TableHead>
);

export default DataTableHeader;
