import React from "react";
import { useForm, FormProvider } from "react-hook-form";

import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import ContractForm from "./ContractForm";

import useCurrentUser from "hooks/useCurrentUser";
import { useCombinedCreateContract } from "hooks/useContractQueries";
import useStylesUtil from "hooks/useStylesUtil";

const ContractsCreate = ({ history, match: { params } }) => {
  const classes = useStylesUtil();
  const { activeOrgId } = useCurrentUser();
  const formMethods = useForm({
    defaultValues: {
      organizationId: activeOrgId
    }
  });
  const goToContracts = () => {
    history.push(`/contracts`);
  };

  const [createContract] = useCombinedCreateContract({
    onCompleted: goToContracts
  });

  const onSubmit = data => createContract(data);

  return (
    <Paper elevation={1} className={classes.maxWidth1k}>
      <Typography variant="h1">New Contract</Typography>
      <Divider style={{ margin: "18px 0" }} />
      <FormProvider {...formMethods}>
        <ContractForm
          {...params}
          onCancel={goToContracts}
          onSubmit={formMethods.handleSubmit(onSubmit)}
          contract={{ organizationId: activeOrgId }}
        />
      </FormProvider>
    </Paper>
  );
};

export default ContractsCreate;
