import React, { useState, useEffect } from "react";
import _get from "lodash.get";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useFormContext } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import XIcon from "react-feather/dist/icons/x";

import FormRow from "ui/form/FormRow";
import SwitchInput from "ui/SwitchInput";
import TextInput from "ui/TextInput";

import useFormValidation from "hooks/useFormValidation";
import useContractDefaultSettings from "hooks/useContractDefaultSettings";
import useFormStyles from "hooks/useFormStyles";
import useTranslate from "hooks/useTranslate";

const CREATE_WELCOME_PREVIEW = gql`
  mutation CreateWelcomePreview($intro: String!, $outro: String!) {
    preview: createWelcomePreview(intro: $intro, outro: $outro) {
      subject
      body
    }
  }
`;

const useStyles = makeStyles({
  divider: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10
  },
  modalTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24
  }
});

export const SettingRow = ({ title, desc, Field, children }) => (
  <Grid container spacing={0} style={{ padding: 8 }}>
    <Grid item style={{ width: "calc(100% - 70px)" }}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1" color="textSecondary">
        {desc}
      </Typography>
    </Grid>
    <Grid item style={{ width: 54 }}>
      {Field && React.cloneElement(Field)}
    </Grid>
    {children}
  </Grid>
);

export const EmailPreviewButton = ({ getValues, mutation }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [createPreview, { data, loading }] = useMutation(mutation);
  const body = _get(data, "preview.body");
  const handleClose = () => setShow(false);

  return (
    <>
      <Button
        color="secondary"
        onClick={() => {
          setShow(true);
          const {
            inviteEmailIntro: intro,
            inviteEmailOutro: outro
          } = getValues();
          createPreview({
            variables: { intro, outro }
          });
        }}
        disabled={loading}
      >
        {loading && <CircularProgress size={20} />}
        Preview Email
      </Button>
      <Dialog
        open={!!(show && body)}
        scroll={"body"}
        onClose={handleClose}
        maxWidth={false}
        fullWidth={false}
      >
        <DialogTitle classes={{ root: classes.modalTitle }} disableTypography>
          <Typography variant="h4">Invitation Email Preview</Typography>
          <IconButton onClick={handleClose}>
            <XIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <iframe frameBorder={0} width={640} height={700} srcDoc={body} />
        </DialogContent>
      </Dialog>
    </>
  );
};

EmailPreviewButton.defaultProps = {
  mutation: CREATE_WELCOME_PREVIEW
};

export const WelcomeEmailSettings = ({
  editing,
  inviteEmailIntro,
  inviteEmailOutro
}) => {
  const classes = useFormStyles();
  const { translate } = useTranslate();
  const { required } = useFormValidation();
  const { getValues } = useFormContext();

  return (
    <SettingRow
      title={translate("contracts.inviteEmail_title")}
      desc={translate("contracts.inviteEmail_desc")}
    >
      <Paper className={classes.fullField} elevation={0}>
        <TextInput
          className={classes.fullField}
          name={"inviteEmailIntro"}
          label="Intro"
          validate={{ required }}
          disabled={!editing}
          multiline
          defaultValue={inviteEmailIntro}
        />
        <TextInput
          className={classes.fullField}
          name={"inviteEmailOutro"}
          label="Outro"
          validate={{ required }}
          disabled={!editing}
          multiline
          defaultValue={inviteEmailOutro}
        />
        <EmailPreviewButton
          getValues={() => getValues(["inviteEmailIntro", "inviteEmailOutro"])}
        />
      </Paper>
    </SettingRow>
  );
};

export const useFieldDefault = (fieldKey, value, defaultValue) => {
  const { setValue, formState } = useFormContext();
  const { dirtyFields } = formState;

  const isEmpty = value === null || value === undefined;
  const isDirty = !!dirtyFields[fieldKey];

  useEffect(() => {
    if (isEmpty && !isDirty) {
      setValue(fieldKey, defaultValue);
    }
  }, [defaultValue, setValue, isDirty, isEmpty]);
};

export const ContractSettingDefaults = ({
  communityAccess,
  inviteEmailIntro,
  inviteEmailOutro,
  organization,
  talkUploads
}) => {
  const { watch } = useFormContext();
  const organizationId = watch("organizationId");
  const { data: defaultSettings } = useContractDefaultSettings({
    organizationId,
    organizationName: _get(organization, "name"),
    organizationType: _get(organization, "organizationType")
  });

  useFieldDefault(
    "talkUploads",
    talkUploads,
    _get(defaultSettings, "talkUploads")
  );

  useFieldDefault(
    "communityAccess",
    communityAccess,
    _get(defaultSettings, "communityAccess")
  );

  useFieldDefault(
    "inviteEmailIntro",
    inviteEmailIntro,
    _get(defaultSettings, "inviteEmailIntro")
  );

  useFieldDefault(
    "inviteEmailOutro",
    inviteEmailOutro,
    _get(defaultSettings, "inviteEmailOutro")
  );

  return <></>;
};

export const ContractSettingsFields = ({ id, editing, ...props }) => {
  const classes = useStyles();
  const { translate } = useTranslate();
  const {
    talkUploads,
    inviteEmailIntro,
    inviteEmailOutro,
    communityAccess
  } = props;

  return (
    <FormRow
      title={translate("contracts.settings_title")}
      desc={translate("contracts.settings_desc")}
    >
      {!id && <ContractSettingDefaults {...props} />}
      <SettingRow
        title={translate("contracts.talkUploads_title")}
        desc={translate("contracts.talkUploads_desc")}
        Field={
          <SwitchInput
            name="talkUploads"
            disabled={!editing}
            defaultValue={!!talkUploads}
          />
        }
      />
      <Divider className={classes.divider} />
      <SettingRow
        title={translate("contracts.communityAccess_title")}
        desc={translate("contracts.communityAccess_desc")}
        Field={
          <SwitchInput
            name="communityAccess"
            disabled={!editing}
            defaultValue={!!communityAccess}
          />
        }
      />
      <Divider className={classes.divider} />
      <WelcomeEmailSettings
        editing={editing}
        inviteEmailIntro={inviteEmailIntro}
        inviteEmailOutro={inviteEmailOutro}
      />
    </FormRow>
  );
};

export default ContractSettingsFields;
