import React from "react";
import clsx from "clsx";
import _get from "lodash.get";

import Link from "ui/Link";

import { StatListItem } from "ui/StatsBlock";

import useStylesUtil from "hooks/useStylesUtil";
import useTranslate from "hooks/useTranslate";

export const UserStatItem = ({ name, count, link, filter, ...props }) => {
  const { translate } = useTranslate();
  const classes = useStylesUtil();

  return (
    <StatListItem
      text={translate(`insights.userBox.${name}_count`, { count })}
      label={translate(`insights.userBox.${name}`)}
      StatLabel={
        link && (
          <Link
            className={clsx(classes.purple, classes.fontNormal)}
            to={link}
            queryParams={!!filter ? { filter } : undefined}
          >
            {translate(`insights.userBox.${name}`)}
          </Link>
        )
      }
      {...props}
    />
  );
};
export default UserStatItem;
