import React from "react";
import compose from "recompose/compose";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { translate } from "react-admin";

export const FormMetaField = ({ classes, title, children, translate }) => (
  <Paper component="figure" elevation={0} className={classes.root}>
    {title && (
      <Typography className={classes.heading}>{translate(title)}</Typography>
    )}
    {children}
  </Paper>
);

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  root: {
    padding: 0,
    margin: 0,
    background: "transparent"
  },
  heading: {}
});

const enhance = compose(
  translate,
  withStyles(styles, { name: "FormMetaField" })
);

export default enhance(FormMetaField);
