export const MuiBreadcrumbs = {
  root: {
    paddingTop: 12,
    paddingBottom: 12
  },
  separator: {
    marginLeft: 6,
    marginRight: 6,
    color: "#51677B"
  }
};
