import React from "react";
import { Redirect } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import clsx from "clsx";
import _get from "lodash.get";
import _uniq from "lodash.uniq";

import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Link from "ui/Link";

import { CAN_VIEW_ORGS } from "resources/users/roles";

import { OrganizationAddress } from "organizations/OrganizationEdit";

import useCurrentUser from "hooks/useCurrentUser";
import usePermissions from "hooks/usePermissions";
import useStylesUtil from "hooks/useStylesUtil";

const StatList = ({ children }) => {
  const utilClasses = useStylesUtil();
  return <dl className={utilClasses.m0}>{children}</dl>;
};

const StatHeading = ({ text, className }) => {
  const utilClasses = useStylesUtil();
  return (
    <dt className={clsx(utilClasses.mb2, className)}>
      <Typography variant="body1">{text}</Typography>
    </dt>
  );
};

const StatValue = ({ text, children, className }) => {
  const utilClasses = useStylesUtil();
  return (
    <dd className={clsx(utilClasses.ml4, className)}>
      <Typography variant="caption">{children || text}</Typography>
    </dd>
  );
};

const DashboardCard = ({ title, count, children, to }) => {
  const utilClasses = useStylesUtil();
  return (
    <Card>
      <CardContent>
        <div className={utilClasses.itemsCenter}>
          <Typography variant="h5">{title}</Typography>

          <IconButton
            to={to}
            className={clsx(
              utilClasses.mr_3,
              utilClasses.gray4,
              utilClasses.mlauto
            )}
            style={{ height: 24 }}
          >
            <Typography className={utilClasses.textLight} variant="h5">
              {count}
            </Typography>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        {children}
      </CardContent>
    </Card>
  );
};

export const GET_DASHBOARD = gql`
  query GetDashboard($id: Int!) {
    organization: Organization(id: $id) {
      address1
      address2
      administrativeArea
      city
      countryCode
      id
      name
      postalCode
      averageLessonsCompleted
      contracts {
        id
        name
        status
      }
    }
    invited: _allUsersMeta(
      perPage: 100000
      filter: { status: "invited", organizationId: $id }
    ) {
      count
    }
    pending: _allUsersMeta(
      perPage: 100000
      filter: { status: "pending", organizationId: $id }
    ) {
      count
    }
    active: _allUsersMeta(
      perPage: 100000
      filter: { status: "active", organizationId: $id }
    ) {
      count
    }
    throughlines: _allThroughlineEntriesMeta(
      perPage: 100000
      filter: { organizationId: $id }
    ) {
      count
    }
  }
`;

const OrganizationCard = ({ name, id, ...address }) => {
  const utilClasses = useStylesUtil();

  return (
    <DashboardCard title="Organization details" to={`/organizations/${id}`}>
      <StatList>
        <StatHeading text="Organization Name" />
        <StatValue text={name} />
        <StatHeading className={utilClasses.mt4} text="Address" />
        <StatValue>
          <OrganizationAddress {...address} />
        </StatValue>
      </StatList>
    </DashboardCard>
  );
};

const ContractLink = ({ id, name }) => (
  <StatValue key={id}>
    <Link to={`/contracts/${id}`}>{name || ""}</Link>
  </StatValue>
);

const ContractsCard = ({ contracts }) => {
  const utilClasses = useStylesUtil();
  const active = contracts.filter(({ status }) => status === "active");
  const inactive = contracts.filter(({ status }) => status === "inactive");

  return (
    <DashboardCard
      title="Contracts"
      count={_uniq(contracts).length}
      to={`/contracts/`}
    >
      <StatList>
        <StatHeading text="Active" />
        {active.map(contract => (
          <ContractLink key={contract.id} {...contract} />
        ))}
        <StatHeading className={utilClasses.mt4} text="Inactive" />
        {inactive.map(contract => (
          <ContractLink key={contract.id} {...contract} />
        ))}
      </StatList>
    </DashboardCard>
  );
};

const UsersCard = ({ active, invited, pending }) => {
  const utilClasses = useStylesUtil();

  return (
    <DashboardCard title="Users" to={`/users/`}>
      <StatList>
        <StatHeading text="Active" />
        <StatValue>
          <Link to="/users" queryParams={{ filter: { status: "active" } }}>
            {active}
          </Link>
        </StatValue>
        <StatHeading className={utilClasses.mt4} text="Invited" />
        <StatValue>
          <Link to="/users" queryParams={{ filter: { status: "invited" } }}>
            {invited}
          </Link>
        </StatValue>
        <StatHeading className={utilClasses.mt4} text="Pending" />
        <StatValue>
          <Link to="/users" queryParams={{ filter: { status: "pending" } }}>
            {pending}
          </Link>
        </StatValue>
      </StatList>
    </DashboardCard>
  );
};

const InsightsCard = ({ averageLessonsCompleted, throughlinesCount }) => (
  <DashboardCard title="Insights" to={`/insights/`}>
    <StatList>
      <StatHeading text="Average Lessons Completed / Active Users" />
      <StatValue text={averageLessonsCompleted} />
      <StatHeading text="Throughlines Submitted" />
      <StatValue text={throughlinesCount} />
    </StatList>
  </DashboardCard>
);

const OrganizationDashboard = () => {
  const utilClasses = useStylesUtil();
  const { loading: loadingUser, activeOrgId: id } = useCurrentUser();
  const { permissions } = usePermissions();
  const { loading, data } = useQuery(GET_DASHBOARD, {
    skip: !id,
    variables: { id: parseInt(id, 10) }
  });

  if (loadingUser || loading) {
    return <CircularProgress />;
  }

  if (!_get(data, "organization.id")) {
    return <Redirect to="/organizations" />;
  }

  return (
    <Paper elevation={1}>
      <Typography variant="h1" className={utilClasses.mb10}>
        {_get(data, "organization.name", "")}
      </Typography>

      <Grid container spacing={3}>
        {permissions.includes(CAN_VIEW_ORGS) && (
          <Grid item style={{ width: 275 }}>
            <OrganizationCard {..._get(data, "organization", {})} />
          </Grid>
        )}
        {permissions.includes(CAN_VIEW_ORGS) && (
          <Grid item style={{ width: 275 }}>
            <ContractsCard
              contracts={_get(data, "organization.contracts", [])}
            />
          </Grid>
        )}
        <Grid item style={{ width: 275 }}>
          <UsersCard
            active={_get(data, "active.count", "?")}
            invited={_get(data, "invited.count", "?")}
            pending={_get(data, "pending.count", "?")}
          />
        </Grid>
        <Grid item style={{ width: 275 }}>
          <InsightsCard
            averageLessonsCompleted={_get(
              data,
              "organization.averageLessonsCompleted",
              "?"
            )}
            throughlinesCount={_get(data, "throughlines.count", "?")}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OrganizationDashboard;
