import React from "react";
import { Labeled, LinearProgress } from "react-admin";

export const Loading = ({ label, source, isRequired }) => (
  <Labeled label={label} source={source} isRequired={isRequired}>
    <LinearProgress />
  </Labeled>
);

export default Loading;
