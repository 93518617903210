import React from "react";
import _get from "lodash.get";

import OrgTypeSelector from "ui/fields/OrgTypeSelector";

const FilterByOrganizationType = ({ filterFieldProps }) => {
  const value = _get(filterFieldProps, "value", "");

  return <OrgTypeSelector {...filterFieldProps} value={value} />;
};

FilterByOrganizationType.defaultProps = {
  filterFieldProps: {}
};

export default FilterByOrganizationType;
