import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { getMainDefinition } from "apollo-utilities";

import ActionCable from "actioncable";
import { ActionCableLink } from "graphql-ruby-client";

const options = {
  uri: "/graphql",
  credentials: "include"
};

export const addAuthHeaders = (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
};

const authLink = setContext(addAuthHeaders);

export const splitUploadContext = operation =>
  operation.variables.file ||
  operation.variables.logo ||
  operation.variables.inviteEmailFooterImage;

const httpLink = ApolloLink.split(
  splitUploadContext,
  createUploadLink(options),
  new BatchHttpLink(options)
);

// Create a WebSocket link:
const cable = ActionCable.createConsumer(
  `${process.env.REACT_APP_ACTION_CABLE}/cable?token=${localStorage.getItem(
    "token"
  )}`
);
const wsLink = new ActionCableLink({ cable });

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
export const hasSubscriptionOperation = ({ query }) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === "OperationDefinition" &&
    definition.operation === "subscription"
  );
};

const link = ApolloLink.split(hasSubscriptionOperation, wsLink, httpLink);

export default new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache().restore({})
});
