import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import withState from "recompose/withState";

import { EditController, translate } from "react-admin";

import { deleteResourceAction, confirmAction } from "actions";
import { PageHeader, Form } from "ui";

export const EditPage = ({
  AfterForm,
  actionProps,
  canDelete,
  canEdit,
  formatTitle,
  editing,
  setEditing,
  confirmAction: confirm,
  title,
  translate,
  ...props
}) => {
  return (
    <div id={`edit-${props.resource}-page`}>
      <PageHeader
        title={(formatTitle && formatTitle(props)) || title}
        translateTitle={typeof formatTitle !== "function"}
        editAction={canEdit && (() => setEditing(true))}
        deleteAction={
          canDelete &&
          (() =>
            confirm({
              message: "ra.message.delete_content",
              onConfirm: deleteResourceAction(props),
              confirmLabel: "ra.action.delete",
              cancelLabel: "ra.action.cancel"
            }))
        }
      />
      <Form
        StandardActions={!canEdit || !editing ? false : undefined}
        actionProps={{
          ...actionProps,
          onCancel: () => {
            setEditing(false);
          },
          beforeSubmit: () => {
            setEditing(false);
          }
        }}
        editing={editing}
        {...props}
      />
      {AfterForm && <AfterForm {...props} />}
    </div>
  );
};

EditPage.defaultProps = {
  canEdit: true,
  title: "ra.action.edit"
};

export const Edit = props => (
  <EditController {...props}>
    {controllerProps => <EditPage {...props} {...controllerProps} />}
  </EditController>
);

const enhance = compose(
  withState("editing", "setEditing", false),
  connect(
    null,
    { confirmAction }
  ),
  translate
);

export default enhance(Edit);
