export const BULK_IMPORT = "BULK_IMPORT";

export const bulkImportAction = (
  resource,
  { contractId, file, saveUsers, inviteNow }
) => ({
  type: BULK_IMPORT,
  payload: { contractId, file, saveUsers, inviteNow },
  meta: {
    resource: resource,
    fetch: BULK_IMPORT,
    onSuccess: {
      notification: {
        body: saveUsers ? `import.success` : `import.validate_file_start`,
        level: "info"
      },
      refresh: true
    }
  }
});

// This is used for analytics tracking.
export const CSV_UPLOAD = "CSV_UPLOAD";
export const csvUploadAction = payload => ({
  type: CSV_UPLOAD,
  payload
});
