import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";

import { USERS } from "resources";
import {
  deleteResourceAction,
  resetPassword,
  resendInviteAction
} from "actions";

import useStylesUtil from "hooks/useStylesUtil";
import usePermissions from "hooks/usePermissions";
import useTranslate from "hooks/useTranslate";

export const UserActions = ({
  record,
  resetPassword,
  resendInviteAction: resendInvite,
  deleteResourceAction: deleteResource
}) => {
  const classes = useStylesUtil();
  const { status } = record;
  const { canCreateUsers, canCancelUsers } = usePermissions();
  const { translate } = useTranslate();

  return (
    <div id="user-actions-bar">
      {status === "active" && (
        <Button
          data-track-id="user-reset-password"
          variant={"outlined"}
          className={classes.mr2}
          onClick={() => {
            resetPassword(record.id, record);
          }}
        >
          {translate("ui.resetPassword")}
        </Button>
      )}
      {(status === "pending" || status === "invited") && canCreateUsers && (
        <Button
          data-track-id="user-resend-invite"
          variant={"outlined"}
          className={classes.mr2}
          onClick={() => {
            resendInvite(record.id, record);
          }}
        >
          {translate("ui.resendInvite", { smart_count: 1 })}
        </Button>
      )}
      {status === "invited" && canCancelUsers && (
        <Button
          data-track-id="user-cancel-invite"
          variant={"outlined"}
          className={classes.mr2}
          onClick={() => {
            deleteResource({
              basePath: `/${USERS}`,
              message: "resources.users.notification.cancelled",
              record,
              redirect: "list",
              resource: USERS
            });
          }}
        >
          {translate("ui.cancelInvite", { smart_count: 1 })}
        </Button>
      )}
    </div>
  );
};

UserActions.defaultProps = {
  record: {}
};

const enhance = compose(
  connect(null, { resetPassword, resendInviteAction, deleteResourceAction })
);

export default enhance(UserActions);
