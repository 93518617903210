import React, { useState } from "react";
import _get from "lodash.get";

import FiltersDropdown from "ui/FiltersDropdown";
import FiltersPanel from "ui/FiltersPanel";

import usePersistentFilters from "hooks/usePersistentFilters";

const useFilters = ({ filters }) => {
  const [filter, setFilter] = useState({});
  const [active, setActive] = useState([]);
  const applied = Object.keys(filter);
  const shown = [...active, ...applied];
  const noFilters = !Array.isArray(filters) || filters.length < 1;

  usePersistentFilters({ filter, setFilter });

  const setSearchFilter = e => {
    if (e.target.value) {
      setFilter({ ...filter, q: e.target.value });
    } else {
      const { q, ...nextFilter } = filter;
      setFilter(nextFilter);
    }
  };

  const updateFilter = (key, value) => {
    if (!key) {
      return;
    }

    if (value !== undefined) {
      setFilter({ ...filter, [key]: value });
      setActive([...active, key]);
    } else {
      const { [key]: removedKey, ...nextFilter } = filter;
      setFilter(nextFilter);
    }
  };

  const Dropdown = ({ className }) => {
    if (noFilters) {
      return null;
    }

    return (
      <FiltersDropdown
        className={className}
        onSelect={key => setActive([...active, key])}
        filterList={filters.filter(
          f => !shown.includes(_get(f, "props.filterKey"))
        )}
      />
    );
  };

  const Panel = () => {
    if (noFilters) {
      return null;
    }

    return (
      <FiltersPanel
        filter={filter}
        onRemove={key => {
          updateFilter(key, undefined);
          setActive(active.filter(f => f !== key));
        }}
        updateFilter={updateFilter}
        activeFilters={filters.filter(f =>
          shown.includes(_get(f, "props.filterKey"))
        )}
      />
    );
  };

  return {
    active,
    filter,
    setFilter,
    setSearchFilter,
    updateFilter,
    FiltersDropdown: Dropdown,
    FiltersPanel: Panel
  };
};

export default useFilters;
