import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";

import PlusCircle from "react-feather/dist/icons/plus-circle";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import FormFieldBox from "ui/form/FormFieldbox";
import FormRow from "ui/form/FormRow";
import TextInput from "ui/TextInput";

import useFormValidation from "hooks/useFormValidation";
import useMenuAnchor from "hooks/useMenuAnchor";
import useTranslate from "hooks/useTranslate";
import useFormStyles from "hooks/useFormStyles";
import useStylesUtil from "hooks/useStylesUtil";

const labelBase = "resources.contacts.fields";

const emptyContact = {
  firstName: "",
  lastName: "",
  email: "",
  phone: ""
};

const DELETE_CONTACT = gql`
  mutation deleteContact($id: Int!) {
    deleteContact(id: $id) {
      id
    }
  }
`;

export const ContactForm = ({ editing, idx, primary, ...rest }) => {
  const { register } = useFormContext();
  const { maxLength, required, email: isEmail } = useFormValidation();
  const { translate } = useTranslate();
  const { w100 } = useStylesUtil();
  const classes = useFormStyles();
  const isRequired = primary ? required : undefined;
  const { id, firstName, lastName, email, phone } = rest;

  return (
    <div className={w100}>
      {id && (
        <input
          type="hidden"
          name={`contacts[${idx}]['id']`}
          value={id}
          ref={register}
        />
      )}
      <input
        type="hidden"
        name={`contacts[${idx}]['primary']`}
        value={idx === 0}
        ref={register}
      />
      <TextInput
        className={classes.halfField}
        label={translate(`${labelBase}.firstName`)}
        name={`contacts[${idx}]['firstName']`}
        validate={{ required: isRequired, maxLength: maxLength(100) }}
        defaultValue={firstName}
        disabled={!editing}
      />
      <TextInput
        className={classes.halfField}
        label={translate(`${labelBase}.lastName`)}
        name={`contacts[${idx}]['lastName']`}
        validate={{ required: isRequired, maxLength: maxLength(100) }}
        defaultValue={lastName}
        disabled={!editing}
      />
      <TextInput
        className={classes.fullField}
        label={translate(`${labelBase}.email`)}
        name={`contacts[${idx}]['email']`}
        type="email"
        validate={{
          required: isRequired,
          maxLength: maxLength(255),
          pattern: isEmail
        }}
        defaultValue={email}
        disabled={!editing}
      />
      <TextInput
        className={classes.fullField}
        label={translate(`${labelBase}.phone`)}
        type="tel"
        name={`contacts[${idx}]['phone']`}
        validate={{ maxLength: maxLength(100) }}
        defaultValue={phone}
        disabled={!editing}
      />
    </div>
  );
};

export const RemoveContact = ({ onRemove }) => {
  const { dangerBtn, dangerText } = useFormStyles();
  const { mr4, mb4, justifyContentEnd, w100, popover } = useStylesUtil();
  const { translate } = useTranslate();
  const { closeMenu, menuProps, openMenu } = useMenuAnchor();

  return (
    <div className={clsx(justifyContentEnd, w100)}>
      <Button variant={"text"} className={dangerText} onClick={openMenu}>
        {translate("resources.contacts.remove_confirm")}
      </Button>
      <Popover classes={{ paper: popover }} {...menuProps}>
        <Typography variant="body1" className={mb4}>
          {translate("resources.contacts.remove_warning")}
        </Typography>
        <Button
          variant={"contained"}
          className={clsx(dangerBtn, mr4)}
          onClick={onRemove}
        >
          {translate("resources.contacts.remove_confirm")}
        </Button>
        <Button variant={"outlined"} onClick={closeMenu}>
          {translate("ra.action.cancel")}
        </Button>
      </Popover>
    </div>
  );
};

export const ContactBlock = ({ idx, primary, onRemove, ...props }) => {
  const { translate } = useTranslate();
  const { mb6 } = useStylesUtil();

  return (
    <FormFieldBox className={mb6}>
      {primary && (
        <Typography variant="body1">
          {translate("resources.contacts.fields.primary")}
        </Typography>
      )}
      <ContactForm idx={idx} primary={primary} {...props} />
      {props.editing && onRemove && <RemoveContact onRemove={onRemove} />}
    </FormFieldBox>
  );
};

const ContractPLLFields = ({ editing, contacts: saved }) => {
  const { translate } = useTranslate();
  const [deleteContact] = useMutation(DELETE_CONTACT, {
    onError: error => console.log("error!", error)
  });
  const startingContacts = saved && saved.length > 0 ? saved : [emptyContact];
  const [contacts, setContacts] = useState(startingContacts);

  const addContact = () => {
    setContacts([...contacts, { ...emptyContact }]);
  };

  const removeContact = ({ id, idx }) => {
    if (id) {
      deleteContact({ variables: { id } });
    }
    setContacts([...contacts.slice(0, idx), ...contacts.slice(idx + 1)]);
  };

  return (
    <FormRow
      title={"resources.organizations.contact.title"}
      desc={"resources.organizations.contact.desc"}
      fieldBox={false}
    >
      {contacts.map((contact, idx) => (
        <ContactBlock
          key={contact.id || `contact-${idx}`}
          idx={idx}
          primary={idx === 0}
          onRemove={idx !== 0 && (() => removeContact({ id: contact.id, idx }))}
          {...contact}
          editing={editing}
        />
      ))}
      {editing && (
        <Button onClick={addContact}>
          <PlusCircle />
          <Typography variant={"h6"}>
            {translate("resources.contacts.add_new")}
          </Typography>
        </Button>
      )}
    </FormRow>
  );
};

export default ContractPLLFields;
