import React from "react";
import pure from "recompose/pure";
import SvgIcon from "@material-ui/core/SvgIcon";

let ArrowDropDown = ({ viewBox, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 10 5">
    <path fill="currentColor" fillRule="evenodd" d="M0 0l5 5 5-5z" />
  </SvgIcon>
);

ArrowDropDown = pure(ArrowDropDown);
ArrowDropDown.muiName = "SvgIcon";

export default ArrowDropDown;
