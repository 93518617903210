import React, { useEffect, useState } from "react";
import { Admin, Resource } from "react-admin";
import { ApolloProvider as ReactApolloProvider } from "react-apollo";
import { ApolloProvider } from "@apollo/react-hooks";
import { Provider } from "react-redux";
import { createHashHistory } from "history";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  StylesProvider,
  createGenerateClassName
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";

import buildDataProvider from "./dataProvider";
import authProvider from "./authProvider";
import NotifcationProvider from "ui/NotificationProvider";
import { Layout } from "./ui";
import LoginPage from "./login";
import client from "./apolloClient";
import createStore from "./store";
import customRoutes from "./customRoutes";

import "typeface-inter/inter.css";

import * as resources from "./resources";
import GroupsList from "./groups/GroupsList";
import UsersList from "./users/UsersList";
import { HistoryContext } from "hooks/useHistory";

const history = createHashHistory();

// History context is native in react-router 5.x+
// we can ditch this once we are able to upgrade.

export const App = () => (
  <Admin
    appLayout={Layout}
    authProvider={authProvider}
    customRoutes={customRoutes}
    history={history}
    loginPage={LoginPage}
    title="TED Masterclass"
  >
    {permissions => {
      return [
        <Resource
          name={resources.USERS}
          create={
            (permissions.includes(resources.CAN_CREATE_USERS) &&
              resources.UserCreate) ||
            null
          }
          list={
            (permissions.includes(resources.CAN_VIEW_USERS) && UsersList) ||
            null
          }
          edit={
            (permissions.includes(resources.CAN_VIEW_USERS) &&
              resources.UserEdit) ||
            null
          }
        />,
        <Resource
          name={resources.GROUPS}
          list={
            (permissions.includes(resources.CAN_VIEW_GROUPS) && GroupsList) ||
            null
          }
        />,
        <Resource
          name={resources.ORGS}
          create={
            (permissions.includes(resources.CAN_CREATE_ORGS) &&
              resources.OrganizationCreate) ||
            null
          }
          list={
            (permissions.includes(resources.CAN_VIEW_ORGS) &&
              resources.OrganizationList) ||
            null
          }
          edit={
            (permissions.includes(resources.CAN_VIEW_ORGS) &&
              resources.OrganizationEdit) ||
            null
          }
        />
      ];
    }}
  </Admin>
);

export const AppLoading = () => (
  <>
    <CssBaseline />
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "80vh",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress color={"primary"} size={120} />
    </div>
  </>
);

const generateClassName = createGenerateClassName({
  productionPrefix: "tmca"
});

export const AppWithProviders = () => {
  const [store, setStore] = useState();

  useEffect(() => {
    const initDataProvider = async () => {
      const dataProvider = await buildDataProvider();
      setStore(createStore({ history, dataProvider }));
    };
    initDataProvider();
  }, []);

  if (!store) {
    return <AppLoading />;
  }

  return (
    <ReactApolloProvider client={client}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <HistoryContext.Provider value={history}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <StylesProvider generateClassName={generateClassName}>
                <NotifcationProvider>
                  <App />
                </NotifcationProvider>
              </StylesProvider>
            </MuiPickersUtilsProvider>
          </HistoryContext.Provider>
        </Provider>
      </ApolloProvider>
    </ReactApolloProvider>
  );
};

export default AppWithProviders;
