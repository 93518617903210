import { makeStyles } from "@material-ui/core/styles";

const useStylesUtil = makeStyles({
  borderRadius0: {
    borderRadius: 0
  },
  border1: {
    border: "1px solid rgba(0, 0, 0, 0.12)"
  },
  bold: {
    fontWeight: 700
  },
  mr2: {
    marginLeft: 8
  },
  mlauto: {
    marginLeft: "auto"
  },
  p0: {
    padding: 0
  },
  p3: {
    padding: 12
  },
  p4: {
    padding: 16
  },
  px4: {
    paddingRight: 16,
    paddingLeft: 16
  },
  p5: {
    padding: 20
  },
  pb3: {
    paddingBottom: 12
  },
  pt3: {
    paddingTop: 12
  },
  m0: {
    margin: 0
  },
  m5: {
    margin: 20
  },
  ml4: {
    marginLeft: 16
  },
  mr_3: {
    marginRight: -12
  },
  mr_2: {
    marginRight: -8
  },
  mr4: {
    marginRight: 16
  },
  mb2: {
    marginBottom: 8
  },
  mb3: {
    marginBottom: 12
  },
  mb4: {
    marginBottom: 16
  },
  mb6: {
    marginBottom: 24
  },
  mb10: {
    marginBottom: 40
  },
  mt0: {
    marginTop: 0
  },
  mt2: {
    marginTop: 8
  },
  mt4: {
    marginTop: 16
  },
  mt10: {
    marginTop: 40
  },
  minW25ch: {
    minWidth: "25ch"
  },
  w100: {
    width: "100%"
  },
  h100: {
    height: "100%"
  },
  flex: {
    display: "flex"
  },
  flexCol: {
    display: "flex",
    flexDirection: "column"
  },
  maxWidth1k: {
    maxWidth: 1000
  },
  itemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center"
  },
  justifyStart: {
    display: "flex",
    justifyContent: "flex-start"
  },
  justifyContentEnd: {
    display: "flex",
    justifyContent: "flex-end"
  },
  popover: {
    padding: 20,
    maxWidth: 340
  },
  visuallyHidden: {
    position: "absolute !important",
    clip: "rect(1px, 1px, 1px, 1px)",
    padding: 0,
    border: 0,
    height: 1,
    width: 1,
    overflow: "hidden"
  },
  fontNormal: {
    fontWeight: 400
  },
  textCenter: {
    textAlign: "center"
  },
  textLight: {
    color: "#606F7B"
  },
  gray3: {
    color: "#637381"
  },
  gray4: {
    color: "#D3D3D3"
  },
  purple: {
    color: "#5866c0"
  },
  red: {
    color: "#bf0711"
  },
  redBorder: {
    borderColor: "#de3618"
  },
  lineHeight1: {
    lineHeight: 1
  }
});

export default useStylesUtil;
