export { default as errorIcon } from "assets/errorIcon.svg";

export const palette = {
  common: {
    black: "#000",
    white: "#fff",
    gray: "#B6B6B6"
  },
  primary: {
    light: "#606F7B",
    main: "#1C1C1C",
    dark: "#1C1C1C",
    contrastText: "#fff"
  },
  secondary: {
    lightAlt: "#F4F6F8",
    light: "#F5F8FC",
    main: "#5866c0",
    dark: "#5866c0"
  },
  error: {
    light: "#fdf3f0",
    main: "#fead9a",
    dark: "#bf0711",
    border: "#de3618",
    contrastText: "#000"
  },
  info: {
    light: "#eef9f9",
    main: "#b7ecec",
    border: "#47c1bf",
    dark: "#00848e"
  },
  success: {
    light: "#eff7ed",
    main: "#bbe5b3",
    border: "#50b83c",
    dark: "#108043"
  },
  caution: {
    light: "#fdf7e3",
    main: "#ffea8a",
    border: "#eec200",
    dark: "#8a6116"
  },
  background: {
    shaded: "#EFF1F3",
    default: "#FAFAFA",
    disabled: "#F9FAFB"
  },
  text: {
    primary: "#2A2A2A",
    secondary: "#7F7F7F",
    light: "#606F7B",
    disabled: "#919EAB"
  },
  contrastThreshold: 3,
  tonalOffset: 0.2
};

export const spacing = 8;

export const buttonStyles = {
  disabled: "#DFE3E8",
  border: "1px solid #DAE1E7",
  borderColor: "#DAE1E7",
  borderLight: "1px solid #E3E8EA",
  borderDisabled: "1px solid #DFE3E8",
  bgGradient: "linear-gradient(-180deg, #FFFFFF 0%, #F9FAFB 100%)",
  boxShadow: "0 1px 0 0 rgba(22,29,37,0.05)"
};

export const dropDown = {
  maxHeight: 346,
  boxShadow: "0 0 0 1px rgba(6,44,82,0.10), 0 2px 16px 0 rgba(33,43,54,0.08)",
  borderRadius: 3,
  selectedBG: "#DAE1E7",
  border: "#ECECEC",
  hoverBg: "#F6F6F6"
};

const baseFontSize = 14;

// Inter is imported into the root app via the `typeface-inter` package.
export const typography = {
  fontFamily: ["Inter", "sans-serif"].join(","),
  fontSize: baseFontSize,
  title: {
    fontSize: baseFontSize * 2,
    fontWeight: 700
  },
  display1: {
    fontSize: Math.round(baseFontSize * 1.14),
    lineHeight: 24 / 16,
    fontWeight: 600,
    color: palette.text.primary
  }
};

export default {
  buttonStyles,
  dropDown,
  palette,
  typography
};
