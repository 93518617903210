import React, { useState } from "react";
import _get from "lodash.get";

import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

export const Tabs = ({ sections, tabListProps, initial }) => {
  const [value, setValue] = useState(initial);

  return (
    <TabContext value={value}>
      <TabList
        value={value}
        onChange={(_, selected) => {
          setValue(selected);
        }}
        {...tabListProps}
      >
        {sections.map(({ id, label }) => (
          <Tab key={id} label={label} value={id} />
        ))}
      </TabList>
      {sections.map(({ id, Component }) => (
        <TabPanel key={id} value={id}>
          {React.isValidElement(Component) && React.cloneElement(Component)}
        </TabPanel>
      ))}
    </TabContext>
  );
};

Tabs.defaultProps = {
  initial: ""
};

export default Tabs;
