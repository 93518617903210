import React from "react";
import PropTypes from "prop-types";
import { DateField } from "react-admin";
import { translate } from "ra-core";

export const LastActive = ({ translate, record = {}, source, className }) => {
  const { lastActive = false } = record;

  if (!lastActive || !Number.isInteger(lastActive)) {
    return <span className={className}>{translate("ui.inactive")}</span>;
  }

  return (
    <DateField
      record={{ ...record, lastActive: new Date(lastActive * 1000) }}
      source={source}
      id={source}
      options={{ day: "numeric", month: "numeric", year: "2-digit" }}
      className={className}
    />
  );
};

LastActive.propTypes = {
  translate: PropTypes.func,
  record: PropTypes.object,
  source: PropTypes.string
};

export default translate(LastActive);
