import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { reset } from "redux-form";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { translate, SaveButton, REDUX_FORM_NAME } from "react-admin";

export const FormActions = ({
  beforeSubmit,
  classes,
  onCancel,
  form,
  handleSubmit,
  invalid,
  record,
  reset,
  save,
  savePage,
  saving,
  saveText,
  translate
}) => {
  const onSubmit = redirect => {
    return handleSubmit(values => {
      if (typeof beforeSubmit === "function") {
        beforeSubmit();
      }

      if (savePage) {
        return savePage({ values, record });
      }

      return save(values, redirect);
    });
  };

  return (
    <>
      <Button
        variant={"outlined"}
        onClick={() => {
          if (form) {
            reset(form);
          }
          if (typeof onCancel === "function") {
            onCancel();
          }
        }}
        className={classes.cancel}
      >
        <span>{translate(`ra.action.cancel`)}</span>
      </Button>
      <SaveButton
        className={classes.submit}
        classes={{
          icon: classes.submitIcon
        }}
        label={saveText}
        redirect={"list"}
        submitOnEnter={false}
        color="secondary"
        handleSubmitWithRedirect={onSubmit}
        invalid={invalid}
        saving={saving}
      />
    </>
  );
};

FormActions.defaultProps = {
  saveText: "ra.action.save",
  form: REDUX_FORM_NAME,
  classes: {
    userToolbar: "userToolbar",
    cancel: "cancel",
    submit: "submit",
    submitIcon: "submitIcon"
  }
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  userToolbar: {
    display: "block",
    overflow: "hidden",
    minHeight: 0
  },
  cancel: {},
  submit: {
    marginLeft: 8
  },
  submitIcon: {
    display: "none"
  }
});

const enhance = compose(
  connect(null, { reset }),
  translate,
  withStyles(styles, { name: "FormActions" })
);

export default enhance(FormActions);
