import React from "react";
import gql from "graphql-tag";
import _get from "lodash.get";
import { useMutation } from "@apollo/react-hooks";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ExternalLinkIcon from "ui/icons/ExternalLink";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

export const GET_LOGIN_SHORTCODE = gql`
  mutation getLoginShortcode($userId: Int!) {
    updateShortcode(userId: $userId) {
      shortCode
    }
  }
`;

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    marginTop: 16
  },
  button: {
    padding: 0
  },
  externalLinkIcon: {
    width: 16,
    height: 16,
    marginLeft: 4,
    marginBottom: -2
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const RequestShortcodeButton = ({ userId }) => {
  const [getShortCode, { data, loading }] = useMutation(GET_LOGIN_SHORTCODE);
  const classes = useStyles();
  const shortcode = _get(data, "updateShortcode.shortCode");

  if (!userId) {
    return <></>;
  }

  return (
    <div className={classes.wrapper}>
      {shortcode && (
        <Typography>
          <Link
            target="_blank"
            href="https://masterclass.ted.com/web/#/B2CShortCodeAuthScreen"
          >
            Login to the web app
            <ExternalLinkIcon className={classes.externalLinkIcon} />
          </Link>{" "}
          <br />
          code: <code>{shortcode}</code>
        </Typography>
      )}
      {!shortcode && (
        <>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={() =>
              getShortCode({ variables: { userId: parseInt(userId, 10) } })
            }
          >
            Request Shortcode
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </>
      )}
    </div>
  );
};

export default RequestShortcodeButton;
