import React, { useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { reset as resetAction } from "redux-form";
import { showNotification as showNotificationAction } from "react-admin";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import useTranslate from "hooks/useTranslate";
import { createUserAction } from "actions";
import { USERS } from "resources/types";

export const CreateUserActions = ({
  classes,
  createUserAction: createUser,
  handleSubmit,
  form,
  invalid,
  onCancel,
  resetAction: reset,
  showNotificationAction: showNotification,
  saving
}) => {
  const { translate } = useTranslate();
  const [invite, setIntiveNow] = useState(false);
  const onSubmit = ({ inviteNow }) => {
    if (invalid) {
      showNotification("ra.message.invalid_form", "warning");
    }

    setIntiveNow(inviteNow);

    // handleSubmit comes from redux form.
    handleSubmit(values => {
      const message = inviteNow ? "create_success" : "create_only_success";
      const notification = {
        body: `resources.users.notification.${message}`,
        level: "info"
      };
      createUser({ ...values, inviteNow, notification }, USERS);
    })();
  };

  const isSaving = !!saving;

  return (
    <>
      <Button
        data-test="cancel"
        variant={"outlined"}
        disabled={isSaving}
        onClick={() => {
          form && reset(form);
          onCancel && onCancel();
        }}
      >
        <span>{translate("ra.action.cancel")}</span>
      </Button>
      <Button
        data-test="save"
        className={classes.btn}
        onClick={() => onSubmit({ inviteNow: false })}
        disabled={isSaving}
        variant="contained"
        color="secondary"
      >
        {isSaving && !invite && <CircularProgress size={18} thickness={2} />}
        <span>{translate("resources.users.create.save")}</span>
      </Button>
      <Button
        data-test="save-and-invite"
        className={classes.btn}
        onClick={() => onSubmit({ inviteNow: true })}
        disabled={isSaving}
        variant="contained"
        color="secondary"
      >
        {saving && invite && <CircularProgress size={18} thickness={2} />}
        <span>{translate("resources.users.create.save_and_invite")}</span>
      </Button>
    </>
  );
};

CreateUserActions.defaultProps = {
  // handleSubmit: () => () => undefined,
  // showNotification: () => undefined,
  classes: {
    btn: "btn"
  }
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  btn: {
    marginLeft: 8
  }
});

const enhance = compose(
  withStyles(styles, { name: "CreateUserActions" }),
  connect(null, { createUserAction, resetAction, showNotificationAction })
);

export default enhance(CreateUserActions);
