import React from "react";
import { useFormContext, Controller } from "react-hook-form";

import Autocomplete from "@material-ui/lab/Autocomplete";

import useFormValidation from "hooks/useFormValidation";

const AutoCompleteInput = ({
  defaultValue,
  name,
  validate,
  renderInput,
  formatValue,
  ...props
}) => {
  const { control } = useFormContext();
  const { fieldErrors } = useFormValidation();
  const { required } = validate || {};

  return (
    <Controller
      render={({ onChange, value }) => (
        <Autocomplete
          onChange={(_, newValue) => onChange(newValue)}
          value={value || null}
          renderInput={params =>
            renderInput({ ...params, ...fieldErrors(name) })
          }
          required={!!required}
          {...props}
        />
      )}
      name={name}
      rules={validate}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default AutoCompleteInput;
