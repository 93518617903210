import React from "react";

import { SelectInput } from "react-admin";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { Loading } from "ui";
import useOrgList from "hooks/useOrgList";
import { Menu } from "@material-ui/core";

export const OrgSelectField = ({ disabled, excludeIds, ...props }) => {
  const { orgList, loading } = useOrgList({ includeAll: false });

  if (loading) {
    return <Loading {...props} />;
  }

  return (
    <SelectInput
      choices={orgList.filter(
        o => !o.parentOrganization && !excludeIds.includes(o.id)
      )}
      disabled={orgList.length < 2 ? true : disabled}
      {...props}
    />
  );
};

OrgSelectField.defaultProps = {
  excludeIds: []
};

export default OrgSelectField;

export const OrgSelect = ({ excludeIds, defaultValue, ...props }) => {
  const { orgList, loading } = useOrgList({ includeAll: false });

  if (loading) {
    return <Loading {...props} />;
  }

  const choices = orgList.filter(
    o => !o.parentOrganization && !excludeIds.includes(o.id)
  );

  return (
    <TextField
      id="select-org-type"
      select
      SelectProps={{ displayEmpty: true }}
      defaultValue={defaultValue || ""}
      {...props}
    >
      {choices.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  );
};

OrgSelect.defaultProps = {
  excludeIds: []
};
