import React from "react";
import _get from "lodash.get";

import Grid from "@material-ui/core/Grid";

import TalksTable from "insights/TalksTable";
import ThroughLines from "insights/ThroughLines";
import UsersStatsblock from "insights/UserStats";
import VideoDeepLinkModal from "insights/VideoDeepLinkModal";

import FilterByContract from "ui/filters/FilterByContract";
import FilterByGroup from "ui/filters/FilterByGroup";
import FilterByOrganization from "ui/filters/FilterByOrganization";
import FilterByOrganizationType from "ui/filters/FilterByOrganizationType";
import FilterByPurchased from "ui/filters/FilterByPurchased";
import FilterByStatus from "ui/filters/FilterByStatus";

import PageTitle from "ui/PageTitle";
import Tabs from "ui/Tabs";

import useCurrentUser from "hooks/useCurrentUser";
import useFilters from "hooks/useFilters";
import useHistory from "hooks/useHistory";
import usePermissions from "hooks/usePermissions";
import useTranslate from "hooks/useTranslate";

const filters = [
  <FilterByContract key="contractId" filterKey="contractId" />,
  <FilterByStatus
    key="contractStatus"
    filterKey="contractStatus"
    options={["active", "inactive"]}
  />,
  <FilterByGroup key="groupIds" filterKey="groupIds" />
];

const purchaseFilter = [
  <FilterByPurchased key="purchaseStatus" filterKey="purchaseStatus" />
];

const orgFilters = [
  <FilterByOrganization key="organizationId" filterKey="organizationId" />,
  <FilterByOrganizationType
    key="organizationType"
    filterKey="organizationType"
  />
];

const useInsightFilters = () => {
  const { activeOrgId } = useCurrentUser();
  const { isTedAdmin } = usePermissions();

  const tedAdminFilters = [
    ...filters,
    ...purchaseFilter,
    ...(!activeOrgId ? orgFilters : [])
  ];

  const { FiltersDropdown, FiltersPanel, filter } = useFilters({
    filters: isTedAdmin ? tedAdminFilters : filters
  });

  return {
    FiltersDropdown,
    FiltersPanel,
    filter: !activeOrgId ? filter : { ...filter, organizationId: activeOrgId }
  };
};

const Insights = ({ match }) => {
  const videoId = _get(match, "params.videoId");
  const history = useHistory();
  const { translate } = useTranslate();
  const { FiltersDropdown, FiltersPanel, filter } = useInsightFilters();

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <PageTitle id="insights-page-title" title="insights.title" />
        <FiltersDropdown />
      </Grid>
      <FiltersPanel />
      <Tabs
        initial={videoId ? "talks" : "user-stats"}
        sections={[
          {
            id: "user-stats",
            label: translate("users.plural"),
            Component: <UsersStatsblock filter={filter} />
          },
          {
            id: "throughlines",
            label: translate("resources.throughlines.plural"),
            Component: <ThroughLines filter={filter} />
          },
          {
            id: "talks",
            label: translate("talks.title"),
            Component: <TalksTable filter={filter} />
          }
        ]}
      />
      {videoId && (
        <VideoDeepLinkModal
          videoId={parseInt(videoId)}
          onClose={() => history.push("/insights")}
        />
      )}
    </>
  );
};

export default Insights;
