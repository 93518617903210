import { errorIcon, spacing, palette, typography } from "./settings";

export const MuiFormControl = {
  root: {
    padding: spacing
  },
  marginNormal: {
    marginTop: 0,
    marginBottom: 0
  }
};

export const MuiFormHelperText = {
  root: {
    color: "#637381",
    fontSize: 13,
    lineHeight: 20 / 13,
    marginTop: spacing * 0.5,

    "&$error": {
      fontSize: typography.fontSize,
      color: palette.error.dark,

      "&::before": {
        content: "''",
        display: "inline-block",
        verticalAlign: "middle",
        width: spacing * 2,
        height: spacing * 2,
        background: `center center no-repeat url(${errorIcon})`,
        marginRight: spacing * 0.5
      }
    }
  }
};

export const MuiFormLabel = {
  root: {
    fontSize: typography.fontSize,
    "&$disabled, &$error": {
      color: "inherit"
    }
  }
};
