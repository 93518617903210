import React, { useState } from "react";

import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";

import { makeStyles } from "@material-ui/core/styles";

import useTranslate from "hooks/useTranslate";

export const OrganizationSearchList = ({
  selectedId,
  onClose,
  orgList,
  onSelectOrg,
  ...props
}) => {
  const classes = useStyles();
  const [filterText, setFilterText] = useState();
  const options = filterText
    ? orgList.filter(({ name }) =>
        name.toLowerCase().includes(filterText.toLowerCase())
      )
    : orgList;

  const { translate } = useTranslate();

  return (
    <Popover
      classes={{
        root: classes.popoverRoot,
        paper: classes.popoverPaper
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      PaperProps={{
        style: {
          height: 346,
          width: 364
        }
      }}
      onClose={onClose}
      {...props}
    >
      <Typography className={classes.selectText}>
        {translate("filters.selectOrg")}
      </Typography>
      <Input
        classes={{ root: classes.searchRoot, input: classes.searchInput }}
        startAdornment={
          <InputAdornment
            classes={{ positionStart: classes.searchIcon }}
            position="start"
          >
            <SearchIcon />
          </InputAdornment>
        }
        onChange={e => {
          setFilterText(e.currentTarget.value);
        }}
        placeholder={"Search organizations"}
      />
      <div className={classes.listWrap}>
        <MenuList disablePadding>
          {options.map(({ id, name }) => (
            <MenuItem
              key={id}
              selected={id === selectedId}
              onClick={() => {
                onClose && onClose();
                setFilterText(undefined);
                if (id !== selectedId) {
                  onSelectOrg(id);
                }
              }}
            >
              {name}
            </MenuItem>
          ))}
        </MenuList>
        <div className={classes.spacer} />
      </div>
    </Popover>
  );
};

export default OrganizationSearchList;

const useStyles = makeStyles({
  popoverRoot: {
    background: "rgba(0, 0, 0, 0.25)"
  },
  popoverPaper: {
    padding: 22,
    fontSize: 14,
    lineHeight: 22 / 14,
    letterSpacing: -0.4,
    borderRadius: 4,
    boxShadow:
      "0px 3px 3px rgba(0, 0, 0, 0.05), 0px 3px 4px rgba(0, 0, 0, 0.07), 0px 1px 8px rgba(0, 0, 0, 0.06)",
    border: "1px solid #ECECEC",
    overflowY: "hidden"
  },
  selectText: {
    fontWeight: 700,
    color: "#637381"
  },
  searchRoot: {
    marginTop: 14,
    marginBottom: 8,
    width: "100%"
  },
  searchIcon: {
    marginLeft: 12,
    marginRight: -8,
    color: "#637381"
  },
  listWrap: {
    height: 243,
    overflowX: "hidden",
    overflowY: "auto"
  },
  spacer: {
    height: 44
  }
});
