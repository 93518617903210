import { useState } from "react";

const useMenuAnchor = () => {
  const [anchorEl, setAnchorEl] = useState(undefined);
  const closeMenu = () => setAnchorEl(undefined);
  const openMenu = ({ currentTarget }) => setAnchorEl(currentTarget);

  return {
    anchorEl,
    setAnchorEl,
    closeMenu,
    openMenu,
    isOpen: Boolean(anchorEl),
    menuProps: {
      open: Boolean(anchorEl),
      anchorEl,
      onClose: closeMenu
    }
  };
};

export default useMenuAnchor;
