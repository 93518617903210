import React from "react";
import _get from "lodash.get";

import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";

import XIcon from "react-feather/dist/icons/x";

import VideoCard from "ui/VideoCard";

export const VideoModal = ({ isOpen, onClose, ...props }) => (
  <Dialog
    disableBackdropClick
    open={isOpen}
    aria-labelledby="video-title"
    onClose={onClose}
  >
    <VideoCard
      titleId={"video-title"}
      title={_get(props, "title") || "(no title)"}
      {...props}
    >
      <IconButton onClick={onClose}>
        <XIcon />
      </IconButton>
    </VideoCard>
  </Dialog>
);

export default VideoModal;
