import React, { useState } from "react";
import gql from "graphql-tag";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import _get from "lodash.get";
import { useQuery, useMutation } from "@apollo/react-hooks";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

import { useGQLNotifications } from "hooks/useNotification";
import useModalOpen from "hooks/useModalOpen";
import useStylesUtil from "hooks/useStylesUtil";
import useTranslate from "hooks/useTranslate";

export const GET_USER_CONTRACTS = gql`
  query getAvailableUserContracts($id: Int!) {
    contracts: allContracts(
      page: 1
      perPage: 10000
      filter: { availableToUserId: $id }
    ) {
      id
      name
      seats
    }
  }
`;

const ADD_USER_TO_CONTRACT = gql`
  mutation addUserToContract($userId: Int!, $contractId: Int!) {
    createContractMembership(userId: $userId, contractId: $contractId) {
      id
      userId
      contractId
    }
  }
`;

export const ContractsList = ({ active, contracts, onChange }) => {
  const classes = useStylesUtil();
  const ref = React.useRef(null);

  if (!contracts || contracts.length < 1) {
    return (
      <Grid
        data-test="no-contracts"
        className={clsx([classes.textCenter, classes.h100])}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="body1">
          No availble contracts were found.
          <br />
          Would you like to{" "}
          <Link component={RouterLink} to={`/contracts/create`}>
            create a new contract
          </Link>
          ?
        </Typography>
      </Grid>
    );
  }

  return (
    <RadioGroup
      ref={ref}
      value={`${active}`}
      name="contractId"
      onChange={onChange}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Seats</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contracts.map(({ id, name, seats }) => (
            <TableRow key={id}>
              <TableCell>
                <FormControlLabel
                  value={`${id}`}
                  control={<Radio />}
                  label={name}
                />
              </TableCell>
              <TableCell>{seats}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </RadioGroup>
  );
};

export const ContractSelector = ({ closeModal, isOpen, userId }) => {
  const { data } = useQuery(GET_USER_CONTRACTS, {
    ...useGQLNotifications(),
    skip: !userId,
    variables: { id: userId }
  });

  const [addToContract] = useMutation(ADD_USER_TO_CONTRACT, {
    ...useGQLNotifications({ successMsg: "Contract added." }),
    refetchQueries: ["getUserContracts"]
  });

  const [active, setActive] = useState();

  const { translate } = useTranslate();
  const classes = useStylesUtil();
  const contracts = _get(data, "contracts", []);
  const selected = contracts.find(({ id }) => `${id}` === `${active}`);

  return (
    <Dialog onClose={closeModal} open={isOpen}>
      <DialogTitle disableTypography style={{ paddingLeft: 40 }}>
        <Typography variant="h4" className={classes.pb3}>
          {translate("contracts.add_user")}
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ height: 350 }}>
        <ContractsList
          contracts={contracts}
          active={active}
          onChange={e => setActive(e.target.value)}
        />
      </DialogContent>
      {selected && (
        <DialogContent dividers style={{ borderTop: 0 }}>
          <Typography data-test-selected={selected.name} variant="body1">
            {translate("contracts.add_user")} {selected.name}?
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="text" autoFocus onClick={closeModal} color="primary">
          {translate("ra.action.cancel")}
        </Button>
        <Button
          data-test-confirm
          disabled={!selected}
          variant="outlined"
          color="secondary"
          onClick={() => {
            addToContract({
              variables: {
                userId: parseInt(userId, 10),
                contractId: parseInt(active, 10)
              }
            });
            closeModal();
          }}
        >
          {translate("ra.action.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UserAddToContractBtn = ({ userId }) => {
  const { isOpen, openModal, closeModal } = useModalOpen();

  return (
    <>
      <Button
        disabled={!userId}
        color="secondary"
        variant="text"
        onClick={openModal}
      >
        Add to Contract
      </Button>
      <ContractSelector
        userId={userId}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </>
  );
};

export default UserAddToContractBtn;
