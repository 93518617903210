import React, { useState } from "react";
import gql from "graphql-tag";
import _get from "lodash.get";

import * as cols from "ui/datagrid/columnTypes";
import UserBulkActions from "users/bulk-actions/UserBulkActions";
import CreateButton from "ui/buttons/CreateButton";
import CustomizeButton, { useCustomize } from "ui/buttons/CustomizeButton";
import DataList from "ui/DataList";
import ExportCSVButton from "ui/buttons/ExportCSVButton";
import InviteAllUsersBtn from "users/InviteAllUsersBtn";
import UploadIcon from "react-feather/dist/icons/upload";

import FilterByBoolean from "ui/filters/FilterByBoolean";
import FilterByContract from "ui/filters/FilterByContract";
import FilterByGroup from "ui/filters/FilterByGroup";
import FilterByOption from "ui/filters/FilterByOption";
import FilterByOrganization from "ui/filters/FilterByOrganization";
import FilterByOrganizationType from "ui/filters/FilterByOrganizationType";
import FilterByPurchased from "ui/filters/FilterByPurchased";
import FilterByRole from "ui/filters/FilterByRole";
import FilterByStatus from "ui/filters/FilterByStatus";
import FilterByValue from "ui/filters/FilterByValue";

import useCurrentUser from "hooks/useCurrentUser";
import usePermissions, {
  ADMIN_ROLE,
  SUPER_ROLE,
  TED_ROLE
} from "hooks/usePermissions";
import useTranslate from "hooks/useTranslate";

export const GET_USERS_DATALIST = gql`
  query usersDataList(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: Filter
  ) {
    list: allUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      cycleCount
      contracts {
        id
        name
      }
      email
      firstName
      groups {
        id
        name
      }
      groupSize
      id
      invitedAt
      lastActive
      lastName
      lastVisited
      lessonsCompleted
      purchasePlatform
      purchaseStatus
      organization {
        id
        name
        organizationType
      }
      registrationCountry
      registrationPlatform
      role
      status
      totalLessonsCompleted
      videosCount
    }
    total: _allUsersMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
    }
  }
`;

const EXPORT_CSV = gql`
  mutation exportUsers($filter: Filter) {
    exportUsers(filter: $filter) {
      jobId
      status
    }
  }
`;

const userFilters = [
  <FilterByGroup key="groupIds" filterKey="groupIds" />,
  <FilterByStatus
    key="status"
    filterKey="status"
    options={["invited", "pending", "active", "inactive"]}
  />,
  <FilterByContract key="contractId" filterKey="contractId" />,
  <FilterByOrganization key="organizationId" filterKey="organizationId" />,
  <FilterByOrganizationType
    key="organizationType"
    filterKey="organizationType"
  />,
  <FilterByRole key="role" filterKey="role" />,
  <FilterByValue key="cycleCount" filterKey="cycleCount" integer />,
  <FilterByValue key="lessonsCompleted" filterKey="lessonsCompleted" integer />,
  <FilterByValue
    key="totalLessonsCompleted"
    filterKey="totalLessonsCompleted"
    integer
  />,
  <FilterByValue key="inactiveWeeks" filterKey="inactiveWeeks" integer />,
  <FilterByValue
    key="invitedBeforeWeeksAgo"
    filterKey="invitedBeforeWeeksAgo"
    integer
  />,
  <FilterByOption
    key="purchasePlatform"
    filterKey="purchasePlatform"
    options={["Android", "iOS", "Web"]}
  />,
  <FilterByPurchased key="purchaseStatus" filterKey="purchaseStatus" />,
  <FilterByValue key="registrationCountry" filterKey="registrationCountry" />,
  <FilterByOption
    key="registrationPlatform"
    filterKey="registrationPlatform"
    options={["Android", "iOS", "Web"]}
  />,
  <FilterByBoolean
    key="videosPresent"
    filterKey="videosPresent"
    options={[true]}
  />,
  <FilterByValue
    key="videosGreaterThan"
    filterKey="videosGreaterThan"
    integer
  />
];

const baseColumns = [
  { field: "lastName", ...cols.name },
  { field: "role", ...cols.role },
  {
    field: "organizationId",
    ...cols.userOrganizationName
  },
  { field: "contractId", ...cols.contracts, sortable: false },
  { field: "status", ...cols.status },
  { field: "groupIds", ...cols.groups },
  { field: "groupSize", ...cols.baseCol, width: 125 },
  { field: "lessonsCompleted", ...cols.lessonsCompleted },
  { field: "cycleCount", ...cols.center, ...cols.baseCol },
  {
    field: "totalLessonsCompleted",
    width: 140,
    ...cols.center,
    ...cols.baseCol
  },
  { field: "videosCount", ...cols.videos },
  { field: "invitedAt", ...cols.date },
  { field: "lastActive", ...cols.lastActive }
];

const tedAminColumns = [
  { field: "organizationType", ...cols.organizationType },
  { field: "purchaseStatus", ...cols.translatedField },
  { field: "purchasePlatform", ...cols.baseCol },
  { field: "registrationPlatform", ...cols.baseCol },
  { field: "registrationCountry", ...cols.baseCol }
];

const columnsByRole = {
  [ADMIN_ROLE.name]: baseColumns,
  [SUPER_ROLE.name]: baseColumns,
  [TED_ROLE.name]: [...baseColumns, ...tedAminColumns]
};

const UsersList = ({ query }) => {
  const [selected, setSelected] = useState();
  const { translate } = useTranslate();
  const { activeOrgId, role } = useCurrentUser();
  const { canCreateUsers } = usePermissions();
  const { active: activeCols, ...customize } = useCustomize();

  const columns = _get(columnsByRole, role, []);
  const columnKeys = columns.map(({ field }) => field);

  const filters = userFilters.filter(({ key }) => columnKeys.includes(key));
  const showCols = columns.filter(({ field }) => activeCols.includes(field));

  return (
    <DataList
      sortField={"lastActive"}
      sortOrder={"DESC"}
      onSelectionChange={({ rows }) => setSelected(rows)}
      BulkActions={<UserBulkActions selectedRows={selected} />}
      CreateButton={
        canCreateUsers && (
          <>
            <CreateButton
              to={`/users/create`}
              text={translate("users.create")}
            />
            <CreateButton
              Icon={UploadIcon}
              to={`/users/bulk-import`}
              text={"Import Users"}
            />
          </>
        )
      }
      CustomizeButton={
        <CustomizeButton columns={columns} active={activeCols} {...customize} />
      }
      ExportButton={<ExportCSVButton mutation={EXPORT_CSV} name={"users"} />}
      ListFooter={<InviteAllUsersBtn />}
      title={translate("users.plural")}
      searchText={translate("users.search")}
      preFilter={(activeOrgId && { organizationId: activeOrgId }) || undefined}
      filters={
        activeOrgId
          ? filters.filter(({ key }) => key !== "organizationId")
          : filters
      }
      columns={showCols}
      query={query}
      dataGrid
      checkboxSelection
    />
  );
};

UsersList.defaultProps = {
  query: GET_USERS_DATALIST
};

export default UsersList;
