import React, { useContext } from "react";
import _get from "lodash.get";

import Button from "@material-ui/core/Button";
import { DataListContext } from "ui/DataList";

import useStylesUtil from "hooks/useStylesUtil";

export const FilterButton = ({ children, filter }) => {
  const { updateFilter } = useContext(DataListContext);
  const classes = useStylesUtil();

  return (
    <Button
      className={classes.bold}
      onClick={() =>
        Object.entries(filter).map(([key, value]) => {
          updateFilter(key, value);
        })
      }
    >
      {children}
    </Button>
  );
};

export default FilterButton;
