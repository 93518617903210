export const MuiCard = {
  root: {
    background: "#fff",
    border: "1px solid #DAE1E7",
    borderRadius: 10
  }
};

export const MuiCardHeader = {
  root: {
    padding: 24
  },
  content: {
    maxWidth: "100%",
    overflow: "hidden"
  }
};

export const MuiCardContent = {
  root: {
    padding: 24,

    "&:last-child": {
      paddingBottom: 24
    }
  }
};

export const MuiCardActionArea = {
  focusHighlight: {
    backgroundColor: "#fff"
  }
};
