import { useState } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import useNotification from "hooks/useNotification";

import { CREATE_CONTACT, formatContactData } from "hooks/useContactQueries";
import { CREATE_CONTRACT, formatContractData } from "hooks/useContractQueries";

export const GET_ORGANIZATION = gql`
  query GetOrganization($id: Int!) {
    organization: Organization(id: $id) {
      address1
      address2
      administrativeArea
      city
      countryCode
      id
      name
      organizationType
      parentOrganization {
        id
        name
      }
      postalCode
      logoPath
    }
  }
`;

export const DELETE_ORG = gql`
  mutation deleteOrganization($id: Int!) {
    organization: deleteOrganization(id: $id) {
      id
    }
  }
`;

export const CREATE_ORG = gql`
  mutation createOrganization(
    $name: String!
    $address1: String
    $address2: String
    $city: String
    $postalCode: String
    $administrativeArea: String
    $countryCode: String
    $organizationType: Int
    $parentId: Int
  ) {
    organization: createOrganization(
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      postalCode: $postalCode
      administrativeArea: $administrativeArea
      countryCode: $countryCode
      organizationType: $organizationType
      parentId: $parentId
    ) {
      id
    }
  }
`;

export const UPDATE_ORG = gql`
  mutation updateOrganization(
    $id: Int!
    $name: String!
    $address1: String
    $address2: String
    $city: String
    $postalCode: String
    $administrativeArea: String
    $countryCode: String
    $organizationType: Int
    $parentId: Int
    $logo: File
  ) {
    updateOrganization(
      id: $id
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      postalCode: $postalCode
      administrativeArea: $administrativeArea
      countryCode: $countryCode
      organizationType: $organizationType
      parentId: $parentId
      logo: $logo
    ) {
      address1
      address2
      administrativeArea
      city
      countryCode
      id
      name
      organizationType
      parentOrganization {
        id
        name
      }
      postalCode
      logoPath
    }
  }
`;

export const GET_ORG_DATALIST = gql`
  query allOrganizations(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: Filter
  ) {
    list: allOrganizations(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      name
      organizationType
      contracts {
        id
        status
      }
      status
    }
    total: _allOrganizationsMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
    }
  }
`;

const refetchQueriesCreate = ["ALL_ORGS_QUERY", "getMenuCounts"];

export const graphTypeInt = data => {
  const val = parseInt(data, 10);
  return isNaN(val) ? data : val;
};

export const formatOrgData = ({
  id,
  organizationType,
  parentId,
  logo,
  ...data
}) => ({
  ...data,
  id: graphTypeInt(id),
  organizationType: graphTypeInt(organizationType),
  parentId: graphTypeInt(parentId),
  logo: logo[0] || undefined
});

const useOrgMutation = props => {
  const { mutation, message, onError, onCompleted, ...rest } = props || {};
  const { showGraphQLError, showNotification } = useNotification();

  const [mutate, ...mutateArgs] = useMutation(mutation, {
    onCompleted: args => {
      showNotification(
        `resources.organizations.notification.${message}`,
        "success"
      );
      onCompleted && onCompleted(args);
    },
    onError: args => {
      showGraphQLError(args);
      onError && onError();
    },
    ...rest
  });

  const formattedMutation = args =>
    mutate({
      ...args,
      variables: formatOrgData(args.variables || {})
    });

  return [formattedMutation, ...mutateArgs];
};

export const useCreateOrg = props =>
  useOrgMutation({
    mutation: CREATE_ORG,
    message: "org_created",
    refetchQueries: refetchQueriesCreate,
    awaitRefetchQueries: true,
    ...props
  });

export const useUpdateOrg = props =>
  useOrgMutation({
    mutation: UPDATE_ORG,
    message: "org_updated",
    ...props
  });

export const useDeleteOrg = props =>
  useOrgMutation({
    mutation: DELETE_ORG,
    message: "org_deleted",
    refetchQueries: refetchQueriesCreate,
    awaitRefetchQueries: true,
    ...props
  });

export const useGetOrg = id =>
  useQuery(GET_ORGANIZATION, {
    skip: id === false,
    variables: { id: parseInt(id, 10) }
  });

export const useCombinedCreateOrg = ({ onCompleted, onError } = {}) => {
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const { showGraphQLError, showNotification } = useNotification();

  const createCombined = async ({
    org,
    contract: { contacts, ...formData }
  }) => {
    setError(undefined);
    setData(undefined);

    try {
      const orgMutation = await client.mutate({
        mutation: CREATE_ORG,
        variables: formatOrgData(org),
        refetchQueries: refetchQueriesCreate
      });

      const {
        data: { organization }
      } = orgMutation;

      const contractMutation = await client.mutate({
        mutation: CREATE_CONTRACT,
        variables: formatContractData({
          ...formData,
          organizationId: organization.id
        }),
        refetchQueries: ["getMenuCounts", "contractsDataList"]
      });

      const {
        data: { contract }
      } = contractMutation;

      const contactMutations = await Promise.all(
        contacts.map(c =>
          client.mutate({
            mutation: CREATE_CONTACT,
            variables: formatContactData({ ...c, contractId: contract.id })
          })
        )
      );

      const data = {
        ...contract,
        organization,
        contacts: contactMutations.map(({ data: { contact } }) => contact)
      };

      showNotification(
        `resources.organizations.notification.org_created`,
        "success"
      );

      onCompleted && onCompleted(data);
      setData(data);
    } catch (error) {
      onError && onError(error);
      setError(error);
      showGraphQLError(error);
    }

    setLoading(false);
  };

  return [createCombined, { loading, error, data }];
};
