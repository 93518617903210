import React from "react";
import _get from "lodash.get";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import StatsBlock, { StatListItem } from "ui/StatsBlock";

import useTranslate from "hooks/useTranslate";
import { useGQLNotifications } from "hooks/useNotification";

export const GET_LESSON_RATINGS_TOTALS = gql`
  query GetLessonRatingStats($filter: Filter) {
    total: _allLessonRatingsMeta(page: 1, perPage: 10000, filter: $filter) {
      count
      average
    }
  }
`;

const LessonRatingsStats = ({ filter }) => {
  const { translate } = useTranslate();
  const { data } = useQuery(GET_LESSON_RATINGS_TOTALS, {
    ...useGQLNotifications(),
    variables: {
      filter
    }
  });

  return (
    <StatsBlock title={translate("lessonRatings.overall")}>
      <StatListItem
        label={translate("lessonRatings.count")}
        text={_get(data, "total.count")}
      />
      <StatListItem
        label={translate("lessonRatings.average")}
        text={_get(data, "total.average") || "N/A"}
      />
    </StatsBlock>
  );
};

export default LessonRatingsStats;
