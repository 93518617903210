import React from "react";
import _get from "lodash.get";

import * as cols from "ui/datagrid/columnTypes";
import CreateButton from "ui/buttons/CreateButton";
import DataList from "ui/DataList";
import ExportOrgsButton from "./ExportOrgsButton";

import FilterByOrganizationType from "ui/filters/FilterByOrganizationType";
import FilterByStatus from "ui/filters/FilterByStatus";

import useTranslate from "hooks/useTranslate";
import usePermissions from "hooks/usePermissions";

import { GET_ORG_DATALIST } from "hooks/useOrgQueries";

const columns = [
  { field: "name", ...cols.organizationName },
  { field: "contracts", ...cols.organizationContracts },
  { field: "organizationType", ...cols.organizationType },
  { field: "status", ...cols.status }
];

const filters = [
  <FilterByStatus filterKey="status" options={["active", "inactive"]} />,
  <FilterByOrganizationType
    filterKey="organizationType"
    label={"filters.organizationType"}
  />
];

const OrganizationsList = ({ query }) => {
  const { translate } = useTranslate();
  const { canCreateOrgs } = usePermissions();

  return (
    <DataList
      sortField={"name"}
      sortOrder={"ASC"}
      columns={columns}
      ExportButton={<ExportOrgsButton />}
      CreateButton={
        canCreateOrgs && (
          <CreateButton
            to={`/organizations/create`}
            text={translate("resources.organizations.create_button")}
          />
        )
      }
      filters={filters}
      title={translate("resources.organizations.plural")}
      searchText={"Search organizations"}
      query={query}
      dataGrid
    />
  );
};

OrganizationsList.defaultProps = {
  query: GET_ORG_DATALIST
};

export default OrganizationsList;
