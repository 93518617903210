import React from "react";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import ContractActivationFields from "./ContractActivationFields";
import ContractAutoExpireFields from "./ContractAutoExpireFields";
import ContractDetailFields from "./ContractDetailFields";
import ContractInviteRequestsFields from "./ContractInviteRequestsFields";
import ContractPLLFields from "./ContractPLLFields";
import ContractOrganization from "./ContractOrganization";
import ContractSettingsFields from "./ContractSettingsFields";
import useStylesUtil from "hooks/useStylesUtil";

export const ContractFields = ({ newOrg, ...props }) => (
  <>
    {!newOrg && <ContractOrganization {...props} />}
    {!newOrg && <Divider />}
    <ContractDetailFields {...props} />
    <Divider />
    <ContractPLLFields {...props} />
    <Divider />
    <ContractActivationFields {...props} />
    <Divider />
    <ContractAutoExpireFields {...props} />
    <Divider />
    <ContractInviteRequestsFields {...props} />
    <Divider />
    <ContractSettingsFields {...props} />
  </>
);

const ContractForm = ({
  editing,
  contract,
  onSubmit,
  onCancel,
  submitText
}) => {
  const classes = useStylesUtil();

  return (
    <form onSubmit={onSubmit} noValidate>
      <ContractFields editing={editing} {...contract} />
      {editing && (
        <div className={classes.justifyContentEnd}>
          <Button className={classes.mr4} onClick={onCancel} variant="outlined">
            Cancel
          </Button>
          <Button variant="contained" color="secondary" type="submit">
            {submitText}
          </Button>
        </div>
      )}
    </form>
  );
};

ContractForm.defaultProps = {
  editing: true,
  submitText: "done"
};

export default ContractForm;
