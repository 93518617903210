import React from "react";
import { Link as RouterLink } from "react-router-dom";
import MuiLink from "@material-ui/core/Link";

export const getSearchString = queryParams =>
  new URLSearchParams(
    Object.entries(queryParams).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: JSON.stringify(value)
      }),
      {}
    )
  ).toString();

const Link = ({ to, queryParams, ...props }) => (
  <MuiLink
    component={RouterLink}
    to={queryParams ? `${to}?${getSearchString(queryParams)}` : to}
    {...props}
  />
);

export default Link;
