import React from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { Field, reduxForm } from "redux-form";
import { TextInput, REDUX_FORM_NAME } from "react-admin";

import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";

import Input from "@material-ui/core/Input";

const useStyles = makeStyles(theme => ({
  seachIcon: {
    color: theme.palette.secondary.main
  },
  labelText: {
    position: "absolute !important",
    height: 1,
    width: 1,
    overflow: "hidden",
    clip: "rect(1px, 1px, 1px, 1px)"
  },
  root: {
    alignItems: "center",
    border: "1px solid #E3E8EA",
    borderRadius: 2,
    background: theme.palette.common.white
  }
}));

export const SearchField = ({ placeholder, source }) => {
  const { seachIcon, labelText, controlRoot, ...rest } = useStyles();
  return (
    <>
      <label className={labelText} htmlFor="searchText">
        {placeholder}
      </label>
      <Field
        allowEmpty
        label={false}
        placeholder={placeholder}
        component={TextInput}
        InputProps={{
          endAdornment: (
            <InputAdornment className={seachIcon} position="end">
              <SearchIcon />
            </InputAdornment>
          ),
          classes: rest
        }}
        name={source}
        record={{}}
        id={"searchText"}
      />
    </>
  );
};

SearchField.propTypes = {
  classes: PropTypes.object,
  translate: PropTypes.func
};

export const onSearchFieldChange = (values, _, props) => {
  const { q } = values || {};
  const { filterValues, setFilters } = props || {};
  return setFilters && setFilters({ ...filterValues, q: q });
};

const enhance = compose(
  reduxForm({
    form: REDUX_FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false, // do not destroy to preserve state across navigation
    onChange: onSearchFieldChange
  })
);

export default enhance(SearchField);

export const SearchInput = props => {
  const classes = useStyles();
  return (
    <Input
      endAdornment={
        <InputAdornment className={classes.seachIcon} position="end">
          <SearchIcon />
        </InputAdornment>
      }
      classes={{ root: classes.root }}
      {...props}
    />
  );
};
