import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { createMuiTheme as createLegacyMuiTheme } from "@ra-shim/material-ui/core";
import appTheme, { themeLegacy } from "theme";

const useStyles = styles => makeStyles(styles);

export const theme = createMuiTheme(appTheme);
export const legacyTheme = createLegacyMuiTheme(themeLegacy);

export default useStyles;
