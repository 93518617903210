import React from "react";
import _get from "lodash.get";
import gql from "graphql-tag";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@apollo/react-hooks";

import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from "@material-ui/lab/Alert";

import FormRow from "ui/form/FormRow";
import TextInput from "ui/TextInput";
import SwitchInput from "ui/SwitchInput";

import useFormValidation from "hooks/useFormValidation";
import useFormStyles from "hooks/useFormStyles";
import useTranslate from "hooks/useTranslate";

const GET_EXPIRING_USERS = gql`
  query GetExpiringUsers($invitedBeforeDaysAgo: Int!, $contractId: Int!) {
    users: _allUsersMeta(
      filter: {
        invitedBeforeDaysAgo: $invitedBeforeDaysAgo
        contractId: $contractId
      }
    ) {
      count
    }
  }
`;

export const ExpiringUsersWarning = ({ usersActiveForDays, contractId }) => {
  const { translate } = useTranslate();
  const invitedBeforeDaysAgo = parseInt(usersActiveForDays, 10);
  const { data } = useQuery(GET_EXPIRING_USERS, {
    skip: isNaN(usersActiveForDays) || !contractId,
    variables: { invitedBeforeDaysAgo, contractId: parseInt(contractId, 10) }
  });
  const expiringUsers = _get(data, "users.count", 0);

  if (expiringUsers < 1) {
    return null;
  }

  return (
    <Alert variant="filled" severity="warning" style={{ width: "100%" }}>
      {translate("users.expirationWarning", {
        users: translate("users.smartCount", { smart_count: expiringUsers })
      })}
    </Alert>
  );
};

const ContractAutoExpireFields = ({
  editing,
  id,
  autoUsersInactive,
  usersActiveForDays
}) => {
  const { watch, clearErrors } = useFormContext();
  const watchEnabled = watch("autoUsersInactive", autoUsersInactive);
  const watchUsersActiveForDays = watch(
    "usersActiveForDays",
    usersActiveForDays
  );
  const { required, positive } = useFormValidation();
  const classes = useFormStyles();
  const { translate } = useTranslate();

  return (
    <FormRow
      title={translate("contracts.autoExpire_title")}
      desc={translate("contracts.autoExpire_desc")}
    >
      {editing && usersActiveForDays !== watchUsersActiveForDays && (
        <ExpiringUsersWarning
          usersActiveForDays={watchUsersActiveForDays}
          contractId={id}
        />
      )}
      <SwitchInput
        name={"autoUsersInactive"}
        label={translate("fields.autoUsersInactive")}
        disabled={!editing}
        onChange={checked => {
          if (!checked) {
            clearErrors("usersActiveForDays");
          }
        }}
        defaultValue={autoUsersInactive}
      />
      {watchEnabled && (
        <TextInput
          name={"usersActiveForDays"}
          label={translate("fields.usersActiveForDays")}
          className={classes.fullField}
          validate={{
            required,
            number: true,
            validate: { positive }
          }}
          type={"number"}
          disabled={!editing}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {translate("days")}
              </InputAdornment>
            )
          }}
          defaultValue={usersActiveForDays}
        />
      )}
    </FormRow>
  );
};

ContractAutoExpireFields.defaultProps = {
  autoUsersInactive: false
};

export default ContractAutoExpireFields;
