import { buttonStyles, palette, spacing, typography } from "./settings";

export const MuiInput = {
  root: {
    border: buttonStyles.border,
    borderRadius: "3px",

    "&$disabled": {
      color: palette.text.disabled,
      backgroundColor: palette.background.disabled,
      border: buttonStyles.borderDisabled,
      cursor: "not-allowed"
    },

    "&$error": {
      backgroundColor: palette.error.light,
      borderColor: palette.error.border,
      color: palette.error.dark
    }
  },
  formControl: {
    "label + &": {
      marginTop: spacing * 3
    }
  },
  input: {
    boxSizing: "border-box",
    height: 34,
    paddingTop: 8,
    paddingLeft: 12,
    paddingBottom: 8,
    paddingRight: 12,
    border: 0,

    "&#password": {
      height: 34
    }
  },
  inputTypeSearch: {
    color: palette.secondary.main
  }
};

export const MuiInputLabel = {
  root: {
    fontSize: typography.fontSize,

    "&$error": {
      color: palette.error.dark
    }
  },
  formControl: {
    top: spacing,
    left: spacing
  },
  shrink: {
    transform: "scale(1)"
  }
};

export const MuiInputAdornment = {
  positionEnd: {
    paddingRight: 16
  }
};
