import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useForm, FormProvider } from "react-hook-form";
import _get from "lodash.get";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import ContractForm from "./ContractForm";
import useTranslate from "hooks/useTranslate";
import useStylesUtil from "hooks/useStylesUtil";
import usePermissions from "hooks/usePermissions";

import { CAN_EDIT_ORGS } from "resources/users/roles";

import {
  useCombinedUpdateContract,
  useGetContract
} from "hooks/useContractQueries";

import { NotFound } from "react-admin";

const ContractEditForm = ({ editing, contract, onCancel, onCompleted }) => {
  const formMethods = useForm({
    defaultValues: contract
  });
  const { reset } = formMethods;
  const { translate } = useTranslate();
  const [updateContract] = useCombinedUpdateContract({ onCompleted });

  const onSubmit = data => {
    updateContract({ ...data, id: contract.id });
  };

  useEffect(() => {
    if (!editing) {
      reset(contract);
    }
  }, [editing, reset]);

  return (
    <FormProvider {...formMethods}>
      <ContractForm
        contract={contract}
        editing={editing}
        submitText={translate("ra.action.save")}
        onCancel={() => {
          formMethods.reset(contract);
          onCancel();
        }}
        onSubmit={formMethods.handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
};

const ContractEdit = ({ match, history }) => {
  const [editing, setEditing] = useState(false);
  const classes = useStylesUtil();
  const id = _get(match, "params.contractId", false);
  const { loading, data: contract } = useGetContract(id);
  const { translate } = useTranslate();
  const { permissions } = usePermissions();

  if (!id || (!loading && !contract)) {
    return <NotFound />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  const canEditOrgs = permissions.includes(CAN_EDIT_ORGS);
  const editMode = canEditOrgs ? editing : false;

  return (
    <Paper elevation={1} className={classes.maxWidth1k}>
      <Typography variant="h1">
        {_get(contract, "organization.name", "?")}:{" "}
        {translate("contracts.single")} {contract.name}
        {canEditOrgs && (
          <Button
            variant="outlined"
            disabled={editing}
            onClick={() => setEditing(true)}
            className={clsx(classes.ml4, classes.mr4)}
          >
            Edit
          </Button>
        )}
      </Typography>
      <Divider style={{ margin: "18px 0" }} />
      <ContractEditForm
        contract={contract}
        editing={editMode}
        onCancel={() => setEditing(false)}
        onCompleted={() => history.push("/contracts")}
      />
    </Paper>
  );
};

export default ContractEdit;
