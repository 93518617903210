import { crudDelete, crudDeleteMany } from "ra-core";

export * from "bulkImport/actions";
export * from "resources/users/actions";
export * from "systemMessage/actions";

export const deleteResourceAction = ({
  basePath,
  message,
  record,
  redirect,
  resource
}) => {
  const crudDeleteAction = crudDelete(
    resource,
    record.id,
    record,
    basePath,
    redirect
  );
  const messageTxt = message || `resources.${resource}.notification.delete`;
  return {
    ...crudDeleteAction,
    meta: {
      ...crudDeleteAction.meta,
      onSuccess: {
        notification: {
          body: messageTxt,
          level: "info"
        },
        basePath,
        redirectTo: basePath,
        refresh: true
      }
    }
  };
};

export const deleteManyResourceAction = ({
  basePath,
  ids,
  message,
  resource,
  refresh = false
}) => {
  const messageTxt =
    message || `resources.${resource}.notification.delete_many`;
  const crudDeleteManyAction = crudDeleteMany(resource, ids, basePath);
  return {
    ...crudDeleteManyAction,
    meta: {
      ...crudDeleteManyAction.meta,
      onSuccess: {
        ...crudDeleteManyAction.meta.onSuccess,
        notification: {
          body: messageTxt,
          level: "info"
        },
        refresh
      }
    }
  };
};
