import { useEffect, useRef } from "react";
import gql from "graphql-tag";
import _get from "lodash.get";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";

import { logout } from "authProvider";

import useHistory from "hooks/useHistory";
import useNotification from "hooks/useNotification";
import useTranslate from "hooks/useTranslate";

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    user: loggedInUserQuery {
      id
      role
      firstName
      lastName
      email
      adminReadonly
      activeOrgId: activeOrganizationId
      activeOrg: activeOrganization {
        id
        name
      }
      organizationId
      organization {
        id
        organizationType
      }
    }
  }
`;

export const UPDATE_ACTIVE_ORG = gql`
  mutation updateActiveOrg($id: Int!, $activeOrganizationId: Int) {
    user: updateUser(id: $id, activeOrganizationId: $activeOrganizationId) {
      id
      activeOrgId: activeOrganizationId
      activeOrg: activeOrganization {
        id
        name
      }
    }
  }
`;

// const invalidUser = user =>
//   !user || !user.id || !user.role || user.role === "user";

export const useLogout = () => {
  const { translate } = useTranslate();
  const client = useApolloClient();
  const history = useHistory();
  const { showNotification } = useNotification();

  const logoutUser = async () => {
    client.stop();
    const message = await logout();
    showNotification(translate(message), "warning");
    history.push(`/login`);
  };

  return logoutUser;
};

export const useCurrentUser = () => {
  // const logoutUser = useLogout();
  const { loading, error, data } = useQuery(GET_CURRENT_USER, {
    // onCompleted: ({ user }) => {
    //   if (invalidUser(user)) {
    //     logoutUser();
    //   }
    // }
  });
  const currentUser = _get(data, "user", {});

  return { ...currentUser, loading, error };
};

export const useChangeActiveOrg = args => {
  const client = useApolloClient();
  const [changeActiveOrg] = useMutation(UPDATE_ACTIVE_ORG, {
    onCompleted: () => {
      client.reFetchObservableQueries();
    },
    ...args
  });

  return changeActiveOrg;
};

export default useCurrentUser;
