import React from "react";
import clsx from "clsx";
import { palette } from "theme/settings";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import NotificationIcon from "ui/NotificationIcon";

import useTranslate from "../hooks/useTranslate";

const useStyles = makeStyles({
  paper: {
    position: "relative",
    borderTop: "3px solid",
    color: palette.text.primary
  },
  icon: {
    "$paper &": {
      position: "absolute",
      top: 8 * 2,
      left: 8 * 2
    }
  },
  button: {
    minWidth: 145,
    marginRight: 8 * 2,

    "$caution &:hover": {
      color: palette.caution.dark
    },
    "$warning &:hover": {
      color: palette.error.dark
    }
  },
  actionsBox: {
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0
  },
  warning: {
    "&$paper": {
      borderColor: palette.error.border,
      background: palette.error.light,
      overflow: "visible"
    }
  },
  caution: {
    "&$paper": {
      borderColor: palette.caution.border,
      background: palette.caution.light,
      overflow: "visible"
    }
  }
});

export const ConfirmDialog = ({
  cancelLabel,
  classes: extraClasses,
  confirmLabel,
  message,
  onCancel,
  onConfirm,
  title,
  variant,
  setOpen,
  isOpen,
  ...props
}) => {
  const classes = useStyles();
  const { translate } = useTranslate();

  return (
    <Dialog
      keepMounted
      PaperProps={{ elevation: 0 }}
      classes={{
        paper: clsx(classes.paper, classes[variant]),
        ...extraClasses
      }}
      {...props}
    >
      <NotificationIcon className={classes.icon} type={variant} />
      {title && (
        <DialogTitle disableTypography>
          <Typography variant="h4">{title}</Typography>
        </DialogTitle>
      )}
      {message && (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions classes={{ root: classes.actionsBox }}>
        <Button
          className={classes.button}
          onClick={onCancel}
          variant="outlined"
          color="inherit"
        >
          {cancelLabel || translate("ra.action.cancel")}
        </Button>
        <Button
          className={classes.button}
          onClick={onConfirm}
          variant="outlined"
          color="inherit"
        >
          {confirmLabel || translate("ra.action.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
