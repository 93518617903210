import { useState, useEffect, useCallback } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { formatApolloError } from "hooks/useNotification";
import useNotification from "hooks/useNotification";

const formatErrorMessages = data =>
  data
    .filter(({ error }) => !!error)
    .map(
      ({ reason }) => `Failed to update ${reason.id}. ${reason.error.message}.`
    );

const useBulkAction = params => {
  const { refetch, successMsg } = params || {};
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const { showNotification } = useNotification();

  const onSuccess = useCallback(() => {
    typeof refetch === "function" && refetch();
    successMsg && showNotification(successMsg, "success");
  }, [showNotification, refetch, successMsg]);

  useEffect(() => {
    if (data.length > 0) {
      onSuccess();
    }
  }, [data]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      showNotification("One or more updates failed", "warning", {
        messageArgs: {
          list: errors
        }
      });
    }
  }, [errors, showNotification]);

  const bulkUpdate = async ({
    selectedIds,
    mutation,
    variables,
    mergeData,
    options
  }) => {
    setData([]);
    setErrors(undefined);
    setLoading(true);

    const updates = selectedIds.map(async id => {
      const mergeVars = (mergeData && mergeData[id]) || {};

      try {
        const data = await client.mutate({
          mutation,
          variables: { id, ...variables, ...mergeVars },
          errorPolicy: "all",
          ...options
        });
        return {
          data,
          error: false
        };
      } catch (e) {
        return {
          variables,
          error: formatApolloError(e)
        };
      }
    });

    const data = await Promise.allSettled(updates);
    const errors = formatErrorMessages(data);

    setData(data);
    setErrors(errors);
    setLoading(false);
  };

  return [
    bulkUpdate,
    {
      data,
      errors,
      loading
    }
  ];
};

export default useBulkAction;
