import React, { memo } from "react";
import EllipsisText from "react-ellipsis-text";

export const GroupsCell = ({ groups }) => (
  <EllipsisText
    text={groups
      .map(({ name }) => name)
      .sort()
      .join(", ")}
    length={30}
  />
);

GroupsCell.defaultProps = {
  groups: []
};

export default memo(GroupsCell);
