import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import useTranslate from "hooks/useTranslate";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    marginBottom: 16
  }
});

export const PageTitle = ({ title, id }) => {
  const classes = useStyles();
  const { translate } = useTranslate();

  return (
    <Typography variant="h1" component="h1" classes={classes} id={id}>
      {translate(title)}
    </Typography>
  );
};

export default PageTitle;
