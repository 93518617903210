import usePermissions from "hooks/usePermissions";

export const tedAdminCols = [
  "lastName",
  "role",
  "organizationName",
  "contracts",
  "organizationType",
  "status",
  "totalLessonsCompleted",
  "videosCount",
  "invitedAt",
  "lastActive"
];

const adminCols = [
  "lastName",
  "role",
  "status",
  "groups",
  "parnterMode",
  "lessonsCompleted",
  "totalLessonsCompleted",
  "videosCount",
  "invitedAt",
  "lastActive"
];

export const storageKey = "userListColumns";

const useSavedUserListCols = () => {
  const { isTedAdmin } = usePermissions();
  const columnsByRole = isTedAdmin ? tedAdminCols : adminCols;
  const savedColumns = localStorage.getItem(storageKey);

  const saveSetting = columns => {
    localStorage.setItem(storageKey, JSON.stringify(columns));
  };

  return {
    userListCols: (savedColumns && JSON.parse(savedColumns)) || columnsByRole,
    saveUserListCols: saveSetting
  };
};

const useSavedSettings = () => {
  const userListSettings = useSavedUserListCols();

  return {
    ...userListSettings
  };
};

export default useSavedSettings;
