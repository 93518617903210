import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import classnames from "classnames";

import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import {
  hideNotification,
  getNotification,
  translate,
  undo,
  complete
} from "react-admin";

import NotificationIcon from "./NotificationIcon";

const formatGraphQL = ({ graphQLErrors: errors }) => {
  if (!errors) {
    return false;
  }

  return {
    message: "errors.graphQL",
    list: errors.map(({ message }) => message),
    messageArgs: {}
  };
};

const formatMessage = ({ messageArgs, message, ...props }) => {
  const { list, translate, ...args } = messageArgs || {};

  // This isn't the best, but Apollo Client returns a hard-coded `GraphQL error: ` string.
  // https://github.com/apollographql/apollo-client/blob/v2.6.8/packages/apollo-client/src/errors/ApolloError.ts#L20
  return {
    ...props,
    message: message.replace("GraphQL error:", "Error:"),
    messageArgs: args,
    list: list && list.map(error => error)
  };
};

export const NoficationMessage = ({ classes, notification, translate }) => {
  if (!notification) {
    return <></>;
  }

  const { message, messageArgs, list } =
    formatGraphQL(notification) || formatMessage(notification);

  return (
    <div className={classes.messageBox}>
      <h4 className={classes.messageTitle}>
        {translate(message, messageArgs)}
      </h4>
      {list && (
        <ul>
          {list.map((error, idx) => (
            <li key={idx}>
              {!messageArgs.skipTranslate && translate(error)}
              {!!messageArgs.skipTranslate && error}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

NoficationMessage.defaultProps = {
  classes: {
    messageBox: "messageBox",
    messageTitle: "messageTitle"
  }
};

export const NoficationContent = ({
  classes,
  notification,
  translate,
  undo
}) => (
  <>
    <NotificationIcon type={notification.type} />
    <NoficationMessage
      notification={notification}
      translate={translate}
      classes={classes}
    />
    {notification.undoable && (
      <Button
        color="primary"
        className={classes.undo}
        size="small"
        onClick={undo}
      >
        {translate("ra.action.undo")}
      </Button>
    )}
  </>
);

NoficationContent.defaultProps = {
  classes: {
    undo: "undo"
  }
};

export const NotificationBox = ({
  className,
  classes,
  type,
  message,
  translate,
  ...props
}) => {
  const { hideNotification, complete, undo, ...safe } = props;

  return (
    <SnackbarContent
      className={classnames(classes[type], className)}
      aria-describedby="client-snackbar"
      message={
        <NoficationContent
          id="client-snackbar"
          notification={{ type, message }}
          translate={translate}
          classes={classes}
        />
      }
      {...safe}
    />
  );
};

NotificationBox.defaultProps = {
  notification: {},
  classes: {
    close: "close",
    undo: "undo",
    closeIcon: "closeIcon",
    messageBox: "messageBox",
    messageTitle: "messageTitle",
    info: "info",
    success: "success",
    caution: "caution",
    warning: "warning"
  }
};

export class NotificationBar extends React.Component {
  state = {
    open: false
  };

  componentWillMount = () => {
    this.setOpenState(this.props);
  };

  componentWillReceiveProps = nextProps => {
    this.setOpenState(nextProps);
  };

  setOpenState = ({ notification }) => {
    this.setState({
      open: !!notification
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  handleExited = () => {
    const { notification, hideNotification, complete } = this.props;
    if (notification && notification.undoable) {
      complete();
    }
    hideNotification();
  };

  render() {
    const { classes, className, translate, notification, ...rest } = this.props;
    const { hideNotification, complete, undo, ...safe } = rest;
    const contentProps = { classes, translate, notification };

    if (!notification) {
      return <></>;
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={this.state.open}
        message={<NoficationContent undo={undo} {...contentProps} />}
        onExited={this.handleExited}
        onClose={this.handleRequestClose}
        ContentProps={{
          className: classnames(
            classes[notification.type],
            classes.snackbar,
            className
          )
        }}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleRequestClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        ]}
        {...safe}
        autoHideDuration={notification.type === "error" ? null : undefined}
      />
    );
  }
}

NotificationBar.defaultProps = {
  autoHideDuration: 5000,
  classes: {
    close: "close",
    undo: "undo",
    closeIcon: "closeIcon",
    messageBox: "messageBox",
    messageTitle: "messageTitle",
    info: "info",
    success: "success",
    caution: "caution",
    warning: "warning"
  }
};

export const Notification = ({ toast, ...props }) => {
  if (!toast) {
    return <NotificationBox {...props} />;
  }

  return <NotificationBar {...props} />;
};

Notification.defaultProps = {
  toast: true
};

/* istanbul ignore next getNotification is part of react-admin core. */
const mapStateToProps = state => ({
  notification: getNotification(state)
});

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  close: {
    margin: 0,
    padding: 8,
    width: "auto",
    height: "auto",
    color: theme.palette.primary.light
  },
  undo: {
    margin: 8 * -0.5,
    marginLeft: "auto",
    padding: 8 * 0.5,
    minHeight: 0,
    lineHeight: 1.6,
    color: theme.palette.primary.light
  },
  closeIcon: {
    width: 20,
    height: 20
  },
  messageBox: {
    paddingLeft: 20,
    lineHeight: 1.4
  },
  messageTitle: {
    fontWeight: 600,
    margin: 0,
    padding: 0
  },
  info: {
    background: theme.palette.info.light,
    borderColor: theme.palette.info.border
  },
  success: {
    background: theme.palette.success.light,
    borderColor: theme.palette.success.border
  },
  caution: {
    background: theme.palette.caution.light,
    borderColor: theme.palette.caution.border
  },
  warning: {
    background: theme.palette.error.light,
    borderColor: theme.palette.error.border
  }
});

export const StyledNotification = withStyles(styles, { name: "Notification" })(
  Notification
);

export default compose(
  translate,
  withStyles(styles, { name: "Notification" }),
  connect(mapStateToProps, {
    complete,
    hideNotification,
    undo
  })
)(Notification);
