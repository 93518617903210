import React from "react";
import _get from "lodash.get";
import moment from "moment";

export const dateFormatter = (value, format = "MM/DD/YY", fallback = "-") => {
  const date = moment(new Date(value));
  return value && date.isValid() ? date.format(format) : fallback;
};

const DateText = ({ date, format, fallback }) => (
  <>{dateFormatter(date, format, fallback)}</>
);

export default DateText;
