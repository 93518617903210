import React, { createContext } from "react";
import { connect } from "react-redux";
import { showNotification } from "react-admin";

export const NotificationContext = createContext();

const NotificationProvider = ({ children, dispatch }) => {
  const onShowNotification = (message, type, args) => {
    dispatch(showNotification(message, type, args));
  };

  return (
    <NotificationContext.Provider
      value={{ showNotification: onShowNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default connect()(NotificationProvider);
