import React, { useState } from "react";

import { Form, FormRow } from "ui";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { validateCSV } from "./csv-validator";
import BulkImportActions from "./BulkImportActions";
import BulkUploadField from "./BulkUploadField";
import BulkImportMeta from "./BulkImportMeta";

import UserContractFields from "resources/users/userContractFields";
import useCurrentUser from "hooks/useCurrentUser";
import useTranslate from "hooks/useTranslate";

/* @link http://stackoverflow.com/questions/46155/validate-email-address-in-javascript */
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const required = value =>
  !(typeof value === "undefined" || value === null || value === "");

export const email = value => value && EMAIL_REGEX.test(value.trim());

export const csvConfig = {
  headers: [
    {
      name: "first_name",
      validate: value => required(value)
    },
    {
      name: "last_name",
      validate: value => required(value)
    },
    {
      name: "email",
      unique: true,
      validate: value => required(value) && email(value)
    }
  ]
};

export const BulkImport = ({
  desc,
  resource,
  title,
  StandardActions,
  ...props
}) => {
  const [contractId, setContractId] = useState();
  const [file, setFile] = useState(null);
  const { activeOrgId } = useCurrentUser();
  const { translate } = useTranslate();

  return (
    <Paper elevation={1}>
      <Typography variant="h1">Import Users</Typography>
      <Divider style={{ margin: "18px 0" }} />
      <Form
        form="bulk-upload"
        defaultValue={{ organizationId: activeOrgId }}
        StandardActions={file && StandardActions}
        actionProps={{
          contractId,
          file,
          onCancel: () => setFile(null),
          onSave: () => setFile(null)
        }}
      >
        <UserContractFields onChange={v => setContractId(v)} />
        <FormRow
          title={translate(title)}
          desc={translate(desc)}
          MetaContent={<BulkImportMeta />}
        >
          <BulkUploadField
            file={file}
            afterDrop={file => setFile(file)}
            {...props}
          />
        </FormRow>
      </Form>
    </Paper>
  );
};

BulkImport.defaultProps = {
  title: "resources.users.create.import.title",
  desc: "resources.users.create.import.desc",
  config: csvConfig,
  validateCSVFile: validateCSV,
  classes: {
    dropZone: "dropZone"
  },
  StandardActions: BulkImportActions
};

export default BulkImport;
