import React from "react";

import FilterByOption from "ui/filters/FilterByOption";

const FilterByStatus = props => (
  <FilterByOption
    optionLabel={({ translate, value }) => translate(`ui.status.${value}`)}
    {...props}
  />
);

export default FilterByStatus;
