import { makeStyles } from "@material-ui/core/styles";

const useFormStyles = makeStyles(theme => ({
  fieldRoot: {
    lineHeight: "1.43",
    padding: 8
  },
  fieldValue: {
    display: "block",
    color: theme.palette.text.light,
    padding: 8 * 1.75,
    paddingTop: 8,
    paddingBottom: 0
  },
  fullField: {
    width: `100%`
  },
  halfField: {
    display: "inline-flex",
    width: `50%`
  },
  primaryBlock: {
    position: "relative",
    borderTop: `2px solid ${theme.palette.secondary.main}`,
    paddingTop: "2em"
  },
  primaryLabel: {
    position: "absolute",
    top: 8,
    left: 20
  },
  dangerText: {
    color: theme.palette.error.dark,
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.error.dark
    }
  },
  dangerBtn: {
    backgroundColor: theme.palette.error.light,
    border: `1px solid ${theme.palette.error.border}`,

    "&:hover": {
      color: "inherit",
      backgroundColor: theme.palette.error.light,
      textDecoration: "underline"
    }
  }
}));

export default useFormStyles;

export const usePopupStyles = makeStyles(theme => ({
  popupText: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  confirmRemoveText: {
    marginBottom: theme.spacing.unit * 2
  }
}));
