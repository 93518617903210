import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import _get from "lodash.get";

import Button from "@material-ui/core/Button";
import GetApp from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";

import useTranslate from "hooks/useTranslate";

const useStyles = makeStyles({
  root: {
    float: "right",
    marginLeft: "auto"
  },
  icon: {
    marginLeft: "0.25em",
    width: "0.8em",
    height: "0.8em"
  }
});

export const CSVButton = ({ filter, name, loading, ...props }) => {
  const classes = useStyles();
  const { translate } = useTranslate();
  const label = Object.keys(filter).length ? "ui.exportFiltered" : "ui.export";
  const Icon = loading ? CircularProgress : GetApp;

  return (
    <Button className={classes.root} disabled={loading} {...props}>
      {translate(label, { name })}
      <Icon className={classes.icon} />
    </Button>
  );
};

CSVButton.defaultProps = {
  filter: {}
};

export default CSVButton;
