import React from "react";
import _get from "lodash.get";
import gql from "graphql-tag";

import * as cols from "ui/datagrid/columnTypes";
import DataList from "ui/DataList";
import ExportCSVButton from "ui/buttons/ExportCSVButton";

const GET_LESSON_RATINGS = gql`
  query GetLessonRatings(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: Filter
  ) {
    list: allLessonRatings(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      user {
        id
        email
        organization {
          id
          name
        }
      }
      entryId
      rating
      comment
      updatedAt
    }
    total: _allLessonRatingsMeta(filter: $filter) {
      count
    }
  }
`;

const columns = [
  {
    field: "email",
    ...cols.baseCol,
    width: 300,
    headerName: "headerCell.email",
    valueGetter: cols.getData("user.email")
  },
  {
    field: "organizationName",
    ...cols.baseCol,
    width: 200,
    headerName: "headerCell.organizationName",
    valueGetter: cols.getData("user.organization.name")
  },
  {
    field: "rating",
    width: 120,
    headerName: "lessonRatings.rating",
    ...cols.baseCol
  },
  {
    field: "entryId",
    flex: 0.25,
    headerName: "lessonRatings.entryId",
    ...cols.baseCol
  },
  {
    field: "comment",
    flex: 0.75,
    headerName: "lessonRatings.comment",
    ...cols.baseCol
  },
  {
    field: "updatedAt",
    width: 200,
    headerName: "lessonRatings.updatedAt",
    ...cols.date
  }
];

const EXPORT_LESSONS_CSV = gql`
  mutation exportLessonRatings($filter: Filter) {
    exportLessonRatings(filter: $filter) {
      jobId
      status
    }
  }
`;

const LessonRatingsTable = ({ filter }) => (
  <DataList
    sortModel={[
      {
        field: "updatedAt",
        sort: "desc"
      }
    ]}
    columns={columns}
    preFilter={filter}
    ExportButton={
      <ExportCSVButton
        mutation={EXPORT_LESSONS_CSV}
        name={"lessons"}
        filter={filter}
      />
    }
    query={GET_LESSON_RATINGS}
    showSearch={false}
    dataGrid
  />
);

export default LessonRatingsTable;
