import React, { useEffect } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const LOGBOOK_SUBSCRIPTION = gql`
  subscription onNewLogbookEntry {
    logbookEntrySaved {
      entryId
      entry
      timestamp
    }
  }
`;

const LOGBOOK_COLLECTION = gql`
  query logbookCollection {
    logbook: logbookCollectionQuery {
      _id
      collection {
        entryId
        entry
        timestamp
      }
    }
  }
`;

const LogbookEntry = ({ entryId, entry, timestamp }) => (
  <TableRow tabIndex={-1}>
    <TableCell>{entryId}</TableCell>
    <TableCell>{entry}</TableCell>
    <TableCell>{timestamp}</TableCell>
  </TableRow>
);

const useLogBookSubscription = () => {
  const { subscribeToMore, data, loading } = useQuery(LOGBOOK_COLLECTION);

  useEffect(() => {
    if (!subscribeToMore) {
      return;
    }

    const addLogbookEntry = (prev, { subscriptionData }) => {
      if (!subscriptionData.data) {
        return prev;
      }

      const { logbookEntrySaved } = subscriptionData.data;

      return {
        ...prev,
        logbook: {
          ...prev.logbook,
          collection: [logbookEntrySaved, ...prev.logbook.collection]
        }
      };
    };

    const unsubscribe = subscribeToMore({
      document: LOGBOOK_SUBSCRIPTION,
      updateQuery: addLogbookEntry
    });

    return () => unsubscribe();
  }, [subscribeToMore]);

  const collection = (data && data.logbook && data.logbook.collection) || [];

  return {
    collection,
    loading
  };
};

const LogbookTable = () => {
  const { collection, loading } = useLogBookSubscription();

  if (loading) {
    return (
      <TableRow>
        <TableCell>
          <CircularProgress />
        </TableCell>
      </TableRow>
    );
  }

  if (collection.length < 1) {
    return (
      <TableRow>
        <TableCell>No entries.</TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {collection.map(({ entryId, ...props }) => (
        <LogbookEntry key={entryId} entryId={entryId} {...props} />
      ))}
    </>
  );
};

const LogbookList = () => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Entry Id</TableCell>
        <TableCell>Entry</TableCell>
        <TableCell>Timestamp</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <LogbookTable />
    </TableBody>
  </Table>
);

LogbookList.defaultProps = {};

export default LogbookList;
