export const SHOW_SYSTEM_MESSAGE = "SHOW_SYSTEM_MESSAGE";
export const HIDE_SYSTEM_MESSAGE = "HIDE_SYSTEM_MESSAGE";

export const showSystemMessageAction = payload => ({
  type: SHOW_SYSTEM_MESSAGE,
  payload
});

export const hideSystemMessageAction = () => ({
  type: HIDE_SYSTEM_MESSAGE
});

export const confirmAction = ({
  message,
  items,
  onConfirm,
  onClickConfirm,
  confirmLabel,
  onCancel,
  onClickCancel,
  cancelLabel,
  useTranslate,
  variant
}) => {
  return {
    type: SHOW_SYSTEM_MESSAGE,
    payload: {
      title: message || "ra.message.are_you_sure",
      message: items,
      variant: variant || "caution",
      actions: [
        {
          onClick: onClickConfirm,
          action: onConfirm,
          label: confirmLabel || "ra.boolean.true"
        },
        {
          onClick: onClickCancel,
          action: onCancel,
          label: cancelLabel || "ra.boolean.false"
        }
      ],
      useTranslate
    }
  };
};
