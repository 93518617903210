import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { reduxForm } from "redux-form";

import { withStyles } from "@material-ui/core/styles";

import { getDefaultValues, translate, REDUX_FORM_NAME } from "react-admin";
import FormActions from "./FormActions";
import FormActionsBar from "./FormActionsBar";

export const Form = props => {
  const { actionProps, classes, actionsBarClasses, children, ...rest } = props;
  const childProps = {
    editing: props.editing,
    basePath: props.basePath,
    input: props.input,
    record: props.record,
    resource: props.resource,
    className: classes.formField
  };

  const formChildren = React.Children.map(children, child =>
    React.cloneElement(child, { ...childProps, ...child.props })
  );

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <div className={classes.content}>{formChildren}</div>
      <FormActionsBar {...rest} {...actionProps} classes={actionsBarClasses} />
    </form>
  );
};

Form.defaultProps = {
  StandardActions: FormActions,
  classes: {
    root: "root",
    content: "content",
    formField: "formField"
  }
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  root: {
    fontFamily: theme.typography.fontFamily
  },
  content: {},
  formField: {}
});

export const mapStateToProps = (state, props) => ({
  form: props.form || REDUX_FORM_NAME,
  initialValues: getDefaultValues(state, props),
  saving: props.saving || state.admin.saving
});

const enhance = compose(
  connect(mapStateToProps),
  translate, // Must be before reduxForm so that it can be used in validation
  reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }),
  withStyles(styles, { name: "Form" })
);

export default enhance(Form);
