import React from "react";
import _get from "lodash.get";
import gql from "graphql-tag";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";

import * as cols from "ui/datagrid/columnTypes";
import CreateButton from "ui/buttons/CreateButton";
import DataList from "ui/DataList";
import Link from "ui/Link";

import { makeStyles } from "@material-ui/core/styles";
import ExportGroupsButton from "./ExportGroupsButton";
import GroupCreate from "groups/GroupCreate";
import GroupEdit from "groups/GroupEdit";

import useCurrentUser from "hooks/useCurrentUser";
import usePermissions from "hooks/usePermissions";
import useMenuAnchor from "hooks/useMenuAnchor";
import useStylesUtil from "hooks/useStylesUtil";
import useTranslate from "hooks/useTranslate";

const useStyles = makeStyles({
  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0
  }
});

export const GroupFormPopover = ({ Button, Form }) => {
  const classes = useStyles();
  const { closeMenu, menuProps, openMenu } = useMenuAnchor();

  return (
    <>
      {React.cloneElement(Button, {
        onClick: openMenu
      })}
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        onKeyDown={e => e.stopPropagation()}
        {...menuProps}
      >
        <IconButton
          className={classes.closeIcon}
          onClick={closeMenu}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        {React.cloneElement(Form, {
          onSubmit: closeMenu
        })}
      </Popover>
    </>
  );
};

export const GroupCreateButton = () => {
  const { translate } = useTranslate();

  return (
    <GroupFormPopover
      Button={
        <CreateButton component={"button"} text={translate("groups.create")} />
      }
      Form={<GroupCreate />}
    />
  );
};

export const GroupEditCell = ({ row: data }) => {
  const classes = useStylesUtil();

  return (
    <GroupFormPopover
      Button={
        <Button className={classes.bold}>{_get(data, "name", "?")}</Button>
      }
      Form={<GroupEdit {...data} />}
    />
  );
};

export const GroupUsersCell = ({ row: data }) => (
  <Link
    to={"/users"}
    queryParams={{ filter: { groupIds: [_get(data, "id")].filter(f => f) } }}
  >
    {_get(data, "usersCount") || "0"}
  </Link>
);

export const GET_GROUPS_DATALIST = gql`
  query groupsDataList(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: Filter
  ) {
    list: allGroups(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      lessonsCompletedAverage
      name
      usersCount
    }
    total: _allGroupsMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
    }
  }
`;

const columns = [
  {
    field: "name",
    headerName: "headerCell.group",
    width: 300,
    renderCell: GroupEditCell,
    ...cols.baseCol
  },
  {
    field: "usersCount",
    width: 140,
    renderCell: GroupUsersCell,
    ...cols.baseCol
  },
  { field: "lessonsCompletedAverage", width: 200, ...cols.baseCol }
];

export const GroupsList = ({ query }) => {
  const { translate } = useTranslate();
  const { activeOrgId } = useCurrentUser();
  const { canCreateGroups } = usePermissions();

  return (
    <DataList
      CreateButton={canCreateGroups && activeOrgId && <GroupCreateButton />}
      ExportButton={<ExportGroupsButton />}
      title={translate("groups.plural")}
      searchText={translate("groups.search")}
      preFilter={(activeOrgId && { organizationId: activeOrgId }) || undefined}
      columns={columns}
      query={query}
      dataGrid
    />
  );
};

GroupsList.defaultProps = {
  query: GET_GROUPS_DATALIST
};

export default GroupsList;
