import { palette } from "./settings";

export const MuiLink = {
  root: {
    fontWeight: 700,
    color: palette.primary.main,

    "&:hover": {
      color: palette.secondary.main
    }
  }
};
