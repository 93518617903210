import React from "react";
import clsx from "clsx";
import _get from "lodash.get";

import Button from "@material-ui/core/Button";

import VideoModal from "ui/VideoModal";

import useModalOpen from "hooks/useModalOpen";
import useStylesUtil from "hooks/useStylesUtil";

export const VideoModalButton = ({ active, ...props }) => {
  const classes = useStylesUtil();
  const { isOpen, closeModal, openModal } = useModalOpen(active);
  const title = _get(props, "title") || "(no title)";
  return (
    <>
      <Button
        className={clsx(classes.bold, classes.justifyStart)}
        onClick={openModal}
      >
        {title}
      </Button>
      <VideoModal
        {...props}
        isOpen={isOpen}
        onClose={closeModal}
        title={title}
      />
    </>
  );
};

export default VideoModalButton;
