import React from "react";
import pure from "recompose/pure";
import SvgIcon from "@material-ui/core/SvgIcon";

let GroupIcon = ({ viewBox, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 24 24">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="translate(1 3)"
    >
      <rect width="7.652" height="7.652" x="6.983" rx="3.826" />
      <rect width="7.652" height="7.652" x="0.287" y="9.565" rx="3.826" />
      <rect width="7.652" height="7.652" x="13.678" y="9.565" rx="3.826" />
      <path d="M12.937 7.652l2.152 2.87m-8.872-.718L7.94 7.652m0 6.218h5.102" />
    </g>
  </SvgIcon>
);

GroupIcon = pure(GroupIcon);
GroupIcon.muiName = "SvgIcon";

export default GroupIcon;
