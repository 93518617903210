import React from "react";
import { Link as RouterLink, matchPath } from "react-router-dom";
import gql from "graphql-tag";
import _get from "lodash.get";

import { makeStyles } from "@material-ui/core/styles";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

import useHistory from "hooks/useHistory";
import { useQuery } from "@apollo/react-hooks";
import useCurrentUser from "hooks/useCurrentUser";

const useStyles = makeStyles({
  link: {
    color: "#51677B",
    fontWeight: 400,
    letterSpacing: "-0.6px",
    fontSize: 12,
    lineHeight: 20 / 12,

    "&:hover": {
      color: "#51677B",
      textDecoration: "underline"
    }
  }
});

export const paths = {
  users: {
    pathname: "/users",
    label: "Users"
  },
  contracts: {
    pathname: "/contracts",
    label: "Contracts"
  },
  organizations: {
    pathname: "/organizations",
    label: "Organizations"
  }
};

const crumbtrails = [
  {
    path: "/users/:param",
    trail: [paths.users]
  },
  {
    path: "/contracts/:param",
    trail: [paths.contracts]
  },
  {
    path: "/organizations/create",
    trail: [paths.organizations]
  }
];

const GET_USER_ORGANIZATION = gql`
  query getUserOrganization($id: Int!) {
    user: User(id: $id) {
      id
      organization {
        id
        name
      }
    }
  }
`;

const useUserOrganizationCrumb = pathname => {
  const { activeOrg } = useCurrentUser();
  const userPath = matchPath(pathname, "/users/:id");
  const userId = _get(userPath, "params.id", false);

  const { data } = useQuery(GET_USER_ORGANIZATION, {
    skip: activeOrg || !userId,
    variables: {
      id: parseInt(userId, 10)
    }
  });
  const id = _get(data, "user.organization.id");
  const name = _get(data, "user.organization.name");
  return id
    ? [
        {
          label: name,
          pathname: `/organizations/${id}`
        }
      ]
    : [];
};

const useBreadcrumbTrail = () => {
  const { activeOrg } = useCurrentUser();
  const history = useHistory();
  const pathname = _get(history, "location.pathname");
  const userOrgPath = useUserOrganizationCrumb(pathname);

  const activeOrgPath = activeOrg
    ? [{ label: _get(activeOrg, "name"), pathname: "/dashboard" }]
    : [];

  const active = crumbtrails.find(({ path }) => matchPath(pathname, path));
  const trail = _get(active, "trail", []);

  return [...activeOrgPath, ...userOrgPath, ...trail];
};

const BreadcrumbNav = () => {
  const classes = useStyles();
  const pathnames = useBreadcrumbTrail();

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {pathnames.map(({ pathname, label }) => (
        <Link
          classes={{ root: classes.link }}
          to={pathname}
          key={pathname}
          component={RouterLink}
        >
          {label}
        </Link>
      ))}
      <>&nbsp;</>
    </Breadcrumbs>
  );
};

export default BreadcrumbNav;
