import React from "react";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";
import _get from "lodash.get";

import Divider from "@material-ui/core/Divider";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ActiveOrgSelector from "ui/ActiveOrgSelector";
import CurrentUserWatcher from "ui/CurrentUserWatcher";
import ExternalLinkIcon from "ui/icons/ExternalLink";

import useTranslate from "hooks/useTranslate";
import useCommunityLink from "hooks/useCommunityLink";
import useCurrentUser from "hooks/useCurrentUser";
import usePermissions from "hooks/usePermissions";
import { useQuery } from "@apollo/react-hooks";

import { ORGS, USERS, GROUPS } from "resources";

export const NavListItem = ({ text, children, ...props }) => (
  <ListItem
    button
    component={React.forwardRef((props, ref) => (
      <NavLink {...props} innerRef={ref} />
    ))}
    {...props}
  >
    {text}
    {children}
  </ListItem>
);

const useStyles = makeStyles({
  hasCount: {
    justifyContent: "space-between"
  },
  spacer: {
    opacity: 0,
    marginTop: "auto"
  },
  externalLink: {
    fontWeight: 400
  },
  externalLinkIcon: {
    color: "#9A9A9A",
    width: 16,
    height: 16,
    marginLeft: 4
  },
  list: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 148px)"
  }
});

export const ExternalLink = ({ text, ...props }) => {
  const { externalLink, externalLinkIcon } = useStyles();
  return (
    <ListItem
      button
      component={"a"}
      target="_blank"
      rel="nofollow noopener"
      className={externalLink}
      {...props}
    >
      {text} <ExternalLinkIcon className={externalLinkIcon} />
    </ListItem>
  );
};

export const GET_MENU_COUNTS = gql`
  query getMenuCounts($filter: Filter, $userId: Int) {
    User(id: $userId) {
      id
    }
    orgs: _allOrganizationsMeta {
      count
    }
    contracts: _allContractsMeta(filter: $filter) {
      count
    }
  }
`;

const CountItem = ({ to, label, count }) => {
  const { hasCount } = useStyles();

  return (
    <NavListItem to={to} className={count > 0 ? hasCount : undefined}>
      <span>{label}</span>
      {count > 0 ? <span>{count}</span> : null}
    </NavListItem>
  );
};

export const OrgNav = () => {
  const { translate } = useTranslate();
  const { activeOrgId, id: userId } = useCurrentUser();
  const { data } = useQuery(GET_MENU_COUNTS, {
    variables: { filter: { organizationId: activeOrgId }, userId },
    skip: userId < 1
  });

  return (
    <>
      {!activeOrgId && (
        <CountItem
          to={`/${ORGS}`}
          label={translate(`resources.${ORGS}.plural`)}
          count={_get(data, "orgs.count", -1)}
        />
      )}
      {activeOrgId > 0 && <NavListItem to={`/dashboard`} text={"Overview"} />}
      <CountItem
        to={`/contracts`}
        label={"Contracts"}
        count={_get(data, "contracts.count", -1)}
      />
      <Divider />
    </>
  );
};

export const NavList = () => {
  const { list, spacer } = useStyles();
  const { translate } = useTranslate();
  const {
    canViewGroups,
    canViewOrgs,
    canViewUsers,
    canViewLessonRatings
  } = usePermissions();
  const communityLink = useCommunityLink();

  return (
    <List
      component="nav"
      aria-label={translate("menu.ariaLabel")}
      classes={{ root: list }}
    >
      {canViewOrgs && <OrgNav />}
      {canViewGroups && (
        <NavListItem to={`/${GROUPS}`} text={translate(`${GROUPS}.plural`)} />
      )}
      {canViewUsers && (
        <NavListItem
          to={`/${USERS}`}
          text={translate(`resources.${USERS}.plural`)}
        />
      )}
      {(canViewGroups || canViewUsers) && <Divider />}

      <NavListItem to={`/insights`} text={translate("insights.title")} />
      {canViewLessonRatings && (
        <NavListItem
          to={`/lesson-ratings`}
          text={translate("lessonRatings.plural")}
        />
      )}

      <Divider className={spacer} />
      {communityLink && (
        <ExternalLink href={communityLink} text={translate("menu.community")} />
      )}
      <ExternalLink
        href="https://drive.google.com/open?id=1pO2s7yFwskgVLuu-vOTjosC6cyrhLZR8"
        text={translate("menu.resources")}
      />
      <ExternalLink
        href="mailto:tedmasterclass@ted.com"
        text={translate("menu.help")}
      />
    </List>
  );
};

/* istanbul ignore next Don't test React core. */
export const Menu = () => (
  <>
    <CurrentUserWatcher />
    <ActiveOrgSelector />
    <NavList />
  </>
);

export default Menu;
