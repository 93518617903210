import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ExternalLink = ({ color, ...props }) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      fill={color}
      d="M12.7 12.7H3.3V3.3H8V2H3.3C2.6 2 2 2.6 2 3.3v9.4c0 .7.6 1.3 1.3 1.3h9.4c.7 0 1.3-.6 1.3-1.3V8h-1.3v4.7zM9.3 2v1.3h2.4L5.2 10l1 1 6.5-6.6v2.4H14V2H9.3z"
    ></path>
  </SvgIcon>
);

ExternalLink.defaultProps = {
  color: "currentColor"
};

export default ExternalLink;
