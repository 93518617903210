import gql from "graphql-tag";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { TED_ROLE } from "resources/users/roles";

import useCurrentUser from "hooks/useCurrentUser";
import useTranslate from "hooks/useTranslate";

export const ALL_ORGS_QUERY = gql`
  query ALL_ORGS_QUERY {
    allOrganizations(sortOrder: "ASC", sortField: "name", perPage: 10000) {
      id
      name
    }
  }
`;

const useOrgListFormatting = () => {
  const { translate } = useTranslate();
  const { role, id } = useCurrentUser();
  const options = {
    skip: !id,
    displayName: "ALL_ORGS_QUERY",
    variables: { currentUser: id }
  };

  const formatList = ({ data, includeAll }) => {
    const orgs =
      (data && Array.isArray(data.allOrganizations) && data.allOrganizations) ||
      [];
    return includeAll && role === TED_ROLE.name
      ? [{ id: null, name: translate("ui.allOrgs") }, ...orgs]
      : orgs;
  };
  return { options, formatList };
};

export const useLazyOrgList = ({ includeAll } = {}) => {
  const { options, formatList } = useOrgListFormatting();
  const [getOrgList, { loading, data, error }] = useLazyQuery(
    ALL_ORGS_QUERY,
    options
  );
  return {
    getOrgList,
    orgList: formatList({ data, includeAll }),
    loading,
    error
  };
};

const useOrgList = ({ includeAll } = {}) => {
  const { options, formatList } = useOrgListFormatting();
  const { loading, error, data } = useQuery(ALL_ORGS_QUERY, options);

  return { orgList: formatList({ data, includeAll }), loading, error };
};

export default useOrgList;
