import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { translate, userLogout as userLogoutAction } from "react-admin";

import Button from "@material-ui/core/Button";

export const LogoutButton = ({ translate, userLogout, className }) => (
  <Button onClick={userLogout} className={className}>
    {translate("ui.logout")}
  </Button>
);

export const mapDispatchToProps = dispatch => ({
  userLogout: () => dispatch(userLogoutAction("/login"))
});

const enhance = compose(
  translate,
  connect(
    undefined,
    mapDispatchToProps
  )
);

export default enhance(LogoutButton);
