import React, { useEffect } from "react";
import { connect } from "react-redux";
import { refreshView } from "react-admin";

import useCurrentUser from "hooks/useCurrentUser";

// This is a terrible hack to force refreshes
// of react-admin when there are changes in the current user.
// This can be removed once we fully migrate to apollo.
const CurrentUserWatcher = ({ dispatch }) => {
  const { activeOrgId, id } = useCurrentUser();

  useEffect(() => {
    dispatch(refreshView());
  }, [activeOrgId, id, dispatch]);

  return (
    <p style={{ opacity: 0, position: "absolute", pointerEvents: "none" }}>
      activeOrgId: {activeOrgId} - user: {id}
    </p>
  );
};

export default connect()(CurrentUserWatcher);
