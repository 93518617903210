import gql from "graphql-tag";
import _get from "lodash.get";
import { useMutation, useApolloClient } from "@apollo/react-hooks";

import { TED_ROLE } from "resources/users/roles";

import { UPDATE_ACTIVE_ORG } from "hooks/useCurrentUser";

const SWITCH_USER = gql`
  mutation SwitchUser($id: Int!) {
    user: switchUser(userId: $id, admin: true) {
      jwt
      role
      id
      activeOrganizationId
      organizationId
    }
  }
`;

export const useSwitchUser = () => {
  const client = useApolloClient();
  const [updateActiveOrg] = useMutation(UPDATE_ACTIVE_ORG);
  const [switchUserMutation, { loading, error }] = useMutation(SWITCH_USER);

  const switchUser = async id => {
    const { data } = await switchUserMutation({
      variables: { id, admin: true }
    });
    const nextUser = _get(data, "user", {});
    const { jwt, role, organizationId, activeOrganizationId } = nextUser;

    if (!jwt || !role || role === "user") {
      throw new Error("ui.switch_account_failure");
    }

    localStorage.setItem("token", jwt);
    localStorage.setItem("role", role);

    if (!TED_ROLE.name && !activeOrganizationId) {
      try {
        await updateActiveOrg({
          variables: {
            id: nextUser.id,
            activeOrganizationId: organizationId
          }
        });
      } catch (error) {
        console.error("updateActiveOrg", error);
      }
    }

    try {
      // Refetching all active queries to force a refresh.
      // https://www.apollographql.com/docs/react/v2.6/api/apollo-client/#ApolloClient.resetStore
      const reset = await new Promise(resolve => {
        client.onResetStore(() => resolve("store reset"));
        client.resetStore();
      });
    } catch (error) {
      console.error("resetStore", error);
    }

    return nextUser;
  };

  return { switchUser, loading, error };
};

export default useSwitchUser;
