import React, { useState, createContext } from "react";

export const DataTableContext = createContext();

const DataTableProvider = ({ children, ...props }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortField, setSortField] = useState(props.sortField || null);
  const [sortOrder, setSortOrder] = useState(props.sortOrder || "ASC");
  const [total, setTotal] = useState(0);

  const state = {
    page,
    setPage,
    perPage,
    setPerPage,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    total,
    setTotal
  };

  return (
    <DataTableContext.Provider value={state}>
      {children}
    </DataTableContext.Provider>
  );
};

export default DataTableProvider;
