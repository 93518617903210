import React, { useEffect, useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import ChevronDown from "ui/icons/ChevronDown";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import OrganizationSearchList from "ui/OrganizationSearchList";

import useCurrentUser, { useChangeActiveOrg } from "hooks/useCurrentUser";
import { useLazyOrgList } from "hooks/useOrgList";

export const ActiveOrgSelector = () => {
  const classes = useStyles();
  const anchor = useRef();
  const [anchorEl, setAnchorEl] = useState(undefined);
  const closeMenu = () => setAnchorEl(undefined);
  const { id: userId, activeOrgId } = useCurrentUser();
  const { getOrgList, orgList, loading } = useLazyOrgList({
    includeAll: true
  });
  const multiOrg = orgList.length > 1;
  const changeActiveOrg = useChangeActiveOrg();
  const selected = orgList.find(({ id }) => id === activeOrgId);

  useEffect(() => {
    getOrgList();
  }, [userId, getOrgList]);

  if (!selected || !selected.name || loading) {
    return <div className={classes.root}></div>;
  }

  return (
    <div className={classes.root} ref={anchor}>
      {!multiOrg && (
        <Typography className={classes.buttonText}>{selected.name}</Typography>
      )}

      {multiOrg && (
        <>
          <Button
            classes={{ text: classes.buttonText }}
            onClick={() => setAnchorEl(anchor.current)}
          >
            {selected.name}
            <ChevronDown className={classes.chevron} />
          </Button>
          <OrganizationSearchList
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={closeMenu}
            orgList={orgList}
            onSelectOrg={id => {
              changeActiveOrg({
                variables: { id: userId, activeOrganizationId: id }
              });
            }}
          />
        </>
      )}
      <Divider className={classes.divider} />
    </div>
  );
};

export default ActiveOrgSelector;

const useStyles = makeStyles({
  root: {
    position: "relative",
    padding: 10,
    height: 84,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column"
  },
  buttonText: {
    fontWeight: 700,
    lineHeight: 22 / 14,
    width: "100%",
    justifyContent: "center"
  },
  chevron: {
    marginLeft: "auto"
  },
  divider: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0
  }
});
