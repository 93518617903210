import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import VideoModal from "ui/VideoModal";
import useNotifcation from "hooks/useNotification";

export const GET_TALK = gql`
  query GetTalk($id: Int!) {
    video: Video(id: $id) {
      createdAt
      id
      url
      title
      user {
        id
        firstName
        lastName
        email
        throughlineEntry {
          entry
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

const VideoDeepLinkModal = ({ videoId, onClose }) => {
  const { showGraphQLError } = useNotifcation();
  const { loading, data, error } = useQuery(GET_TALK, {
    skip: !videoId,
    onError: showGraphQLError,
    variables: {
      id: videoId
    }
  });

  if (loading || !videoId || error) {
    return <></>;
  }

  return <VideoModal {...data.video} isOpen={true} onClose={onClose} />;
};

export default VideoDeepLinkModal;
