import { useContext } from "react";
import { NotificationContext } from "ui/NotificationProvider";
import _get from "lodash.get";

export const formatApolloError = e => {
  const error = JSON.parse(JSON.stringify(e));
  const message = _get(error, "message");
  const graphQLErrors = _get(error, "graphQLErrors");
  const networkError = _get(error, "networkError.result.error.message");

  return {
    graphQLErrors,
    networkError,
    originalMessage: message,
    message:
      networkError ||
      (graphQLErrors && graphQLErrors.map(({ message }) => message).join("")) ||
      message
  };
};

const useNotification = () => {
  const { showNotification } = useContext(NotificationContext);

  const showGraphQLError = ({ graphQLErrors, networkError }) => {
    if (networkError) {
      const message = `${networkError} ${_get(
        networkError,
        "result.error.message",
        ""
      )}`;
      showNotification(message, "warning");
      return;
    }

    if (graphQLErrors) {
      showNotification("errors.graphql", "warning", {
        graphQLErrors
      });
      return;
    }
  };

  return {
    showNotification,
    showGraphQLError
  };
};

export default useNotification;

export const useGQLNotifications = args => {
  const { showGraphQLError, showNotification } = useNotification();
  const successMsg = _get(args, "successMsg");

  return {
    onError: showGraphQLError,
    onCompleted: () => {
      successMsg && showNotification(successMsg, "success");
    }
  };
};
