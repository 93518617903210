import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import useTranslate from "hooks/useTranslate";

import guideFile from "./users-guide.csv";

const useStyles = makeStyles({
  helpText: {
    marginTop: 8
  }
});

export const BulkImportMeta = ({ file, help, text }) => {
  const classes = useStyles();
  const { translate } = useTranslate();

  return (
    <>
      <a href={file}>{translate(text)}</a>
      <Typography variant="caption" className={classes.helpText}>
        ({translate(help)})
      </Typography>
    </>
  );
};

BulkImportMeta.defaultProps = {
  file: guideFile,
  text: "import.guide_text",
  help: "import.guide_help"
};

export default BulkImportMeta;
