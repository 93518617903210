import React from "react";
import _get from "lodash.get";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { startUndoable } from "react-admin";

import EditPage from "resources/EditPage";
import UserFields from "resources/users/userFields";
import UserActionsBar from "resources/users/actionsBar";

import { updateUserAction } from "actions";

import usePermissions from "hooks/usePermissions";

export const userTitle = ({ record }) => {
  const firstName = _get(record, "firstName");
  const lastName = _get(record, "lastName");

  return firstName || lastName ? `${firstName} ${lastName}` : ` [ no name ] `;
};

export const UserEdit = ({ startUndoable, ...props }) => {
  const { canEditUsers, canDeleteUsers, permissions } = usePermissions();

  return (
    <EditPage
      data-track-id="edit-user"
      formatTitle={userTitle}
      canEdit={canEditUsers}
      canDelete={canDeleteUsers}
      ExtraActions={UserActionsBar}
      form={"edit-user"}
      savePage={({ values: data, record: previousData }) => {
        startUndoable(updateUserAction({ data, previousData }));
      }}
      actionProps={{ permissions }}
      {...props}
    >
      <UserFields />
    </EditPage>
  );
};

const enhance = compose(connect(null, { startUndoable }));

export default enhance(UserEdit);
