import { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import _get from "lodash.get";

import useDataTable from "hooks/useDataTable";
import useCurrentUser from "hooks/useCurrentUser";

const useDataList = ({ query, filter, preFilter }) => {
  const {
    page,
    perPage,
    sortField,
    sortOrder,
    setTotal,
    ...dataTable
  } = useDataTable();
  const { activeOrgId } = useCurrentUser();
  const { loading, error, data, refetch } = useQuery(query, {
    fetchPolicy: "network-only",
    skip: activeOrgId === -1,
    variables: {
      activeOrgId,
      page,
      perPage,
      sortField,
      sortOrder,
      filter: { ...filter, ...preFilter }
    }
  });

  const list = _get(data, "list", []);
  const total = _get(data, "total.count", 0);

  useEffect(() => {
    setTotal(total);
  }, [total, setTotal]);

  return {
    ...dataTable,
    error,
    list,
    loading,
    page,
    perPage,
    refetch,
    sortField,
    sortOrder,
    total
  };
};

export default useDataList;
