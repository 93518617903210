import settings from "./settings";
import { MuiTypographyLegacy, MuiTypography } from "./MuiTypography";
import { MuiAutocomplete } from "./MuiAutocomplete";
import * as overrides from "./overrides";

const theme = {
  ...settings,
  overrides: { ...overrides, MuiTypography, MuiAutocomplete },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
      focusRipple: false
    },
    MuiInput: {
      disableUnderline: true
    },
    MuiPaper: {
      square: true,
      elevation: 0
    },
    MuiInputLabel: {
      shrink: true
    }
  }
};

export const themeLegacy = {
  ...theme,
  overrides: { ...overrides, MuiTypography: MuiTypographyLegacy }
};

export default theme;
