import React from "react";
import clsx from "clsx";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import _get from "lodash.get";
import { Link as RouterLink } from "react-router-dom";

import Link from "@material-ui/core/Link";

import StatsBlock from "ui/StatsBlock";
import UserCohortStats from "insights/UserCohortStats";
import UserStatItem from "insights/UserStatItem";

import useStylesUtil from "hooks/useStylesUtil";
import useTranslate from "hooks/useTranslate";
import useNotifcation from "hooks/useNotification";

export const GET_USERS_STATS = gql`
  query getUserStats(
    $contractId: Int
    $contractStatus: String
    $groupIds: [Int!]
    $organizationId: Int
    $organizationType: Int
    $purchaseStatus: String
  ) {
    invited: _allUsersMeta(
      perPage: 100000
      filter: {
        contractId: $contractId
        contractStatus: $contractStatus
        organizationId: $organizationId
        organizationType: $organizationType
        groupIds: $groupIds
        purchaseStatus: $purchaseStatus
        status: "invited"
      }
    ) {
      count
    }
    active: _allUsersMeta(
      perPage: 100000
      filter: {
        contractId: $contractId
        contractStatus: $contractStatus
        organizationId: $organizationId
        organizationType: $organizationType
        groupIds: $groupIds
        purchaseStatus: $purchaseStatus
        status: "active"
      }
    ) {
      count
    }
    completed: _allUsersMeta(
      perPage: 100000
      filter: {
        contractId: $contractId
        contractStatus: $contractStatus
        organizationId: $organizationId
        organizationType: $organizationType
        groupIds: $groupIds
        purchaseStatus: $purchaseStatus
        lessonsCompleted: 7
      }
    ) {
      count
    }
  }
`;

export const UserStatsBlock = ({ filter }) => {
  const classes = useStylesUtil();
  const { translate } = useTranslate();
  const { showGraphQLError } = useNotifcation();
  const { loading, data } = useQuery(GET_USERS_STATS, {
    onError: showGraphQLError,
    variables: filter
  });

  return (
    <StatsBlock
      title={translate("resources.users.name", { smart_count: 2 })}
      Actions={
        <Link
          component={RouterLink}
          to={"users"}
          className={clsx(classes.purple, classes.fontNormal)}
        >
          {translate(`insights.userBox.seeAll`)}
        </Link>
      }
    >
      <UserStatItem
        loading={loading}
        name={"invited"}
        count={_get(data, "invited.count", "?")}
        link={"/users"}
        filter={{ status: "invited" }}
      />
      <UserStatItem
        loading={loading}
        name={"active"}
        count={_get(data, "active.count", "?")}
        link={"/users"}
        filter={{ status: "active" }}
      />
      <UserStatItem
        loading={loading}
        name={"completed"}
        count={_get(data, "completed.count", "?")}
        link={"/users"}
        filter={{ lessonsCompleted: 7 }}
      />
      <UserCohortStats {...filter} />
    </StatsBlock>
  );
};

export default UserStatsBlock;
