import React from "react";
import pure from "recompose/pure";
import SvgIcon from "@material-ui/core/SvgIcon";

let InfoIcon = ({ color, ...rest }) => (
  <SvgIcon viewBox="0 0 20 20" {...rest}>
    <circle cx="10" cy="10" r="9" fill="currentColor" />
    <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m1-5v-3a1 1 0 0 0-1-1H9a1 1 0 1 0 0 2v3a1 1 0 0 0 1 1h1a1 1 0 1 0 0-2m-1-5.9a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2" />
  </SvgIcon>
);

InfoIcon.defaultProps = {
  color: "currentColor"
};

InfoIcon = pure(InfoIcon);
InfoIcon.muiName = "SvgIcon";

export default InfoIcon;
