import React from "react";
import countryCodes from "i18n/countryCodes";
import _get from "lodash.get";

import TextField from "@material-ui/core/TextField";

import AutocompleteInput from "ui/AutocompleteInput";

const countryList = countryCodes.map(option => ({
  code: option["alpha-2"],
  label: option.name
}));

export const USA = countryList.find(country => country.code === "US");

const CountryCodeInput = ({
  className,
  defaultValue,
  label,
  validate,
  ...props
}) => (
  <AutocompleteInput
    className={"outlined"}
    validate={validate}
    fullWidth={true}
    getOptionLabel={option => _get(option, "label")}
    renderOption={option => <span>{_get(option, "label")}</span>}
    renderInput={params => (
      <TextField
        {...params}
        className={className}
        label={label}
        required={!!_get(validate, "required")}
      />
    )}
    defaultValue={
      countryList.find(country => country.code === defaultValue) || USA
    }
    {...props}
  />
);

CountryCodeInput.defaultProps = {
  options: countryList
};

export default CountryCodeInput;
