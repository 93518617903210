import gql from "graphql-tag";
import _get from "lodash.get";
import { useQuery } from "@apollo/react-hooks";

import useCurrentUser from "hooks/useCurrentUser";

const ALL_GROUPS_QUERY = gql`
  query ALL_GROUPS_QUERY($organizationId: Int) {
    allGroups(
      sortOrder: "ASC"
      sortField: "name"
      perPage: 1000
      filter: { organizationId: $organizationId }
    ) {
      id
      name
      organizationId
    }
  }
`;

const useGroupsList = () => {
  const { activeOrgId } = useCurrentUser();
  const { loading, error, data } = useQuery(ALL_GROUPS_QUERY, {
    variables: {
      organizationId: activeOrgId
    }
  });
  return { groupList: _get(data, "allGroups", []), isLoading: loading, error };
};

export default useGroupsList;
