import React from "react";
import _get from "lodash.get";
import { TED_ROLE } from "resources/users/roles";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";

import { StyledSystemMessage as SystemMessage } from "systemMessage/SystemMessage";

import { palette } from "theme/settings";

import useCurrentUser, { useChangeActiveOrg } from "hooks/useCurrentUser";
import useOrgList from "hooks/useOrgList";

const useStyles = makeStyles({
  selectList: {
    paddingLeft: 60,
    paddingRight: 60
  },
  skeleton: {
    height: 30,
    marginTop: 10,
    marginBottom: 10
  },
  selectItem: {
    borderColor: palette.caution.border,
    border: "1px solid",
    color: palette.caution.dark,
    fontWeight: 700,

    "&:hover": {
      background: "transparent"
    }
  }
});

export const OrgSelectDialog = ({ onSelect }) => {
  const classes = useStyles();
  const { loading, orgList } = useOrgList({
    includeAll: true
  });

  return (
    <SystemMessage
      variant="caution"
      disableBackdropClick={true}
      shouldTranslate={false}
      title={"Please select an organization to continue"}
      scroll={"body"}
      open={true}
    >
      {loading && (
        <div className={classes.selectList}>
          <Skeleton className={classes.skeleton} animation="wave" />
          <Skeleton className={classes.skeleton} animation="wave" />
          <Skeleton className={classes.skeleton} animation="wave" />
        </div>
      )}
      {!loading && (
        <List className={classes.selectList}>
          {orgList.map(({ id, name }) => (
            <ListItem
              key={id}
              className={classes.selectItem}
              button
              onClick={() => onSelect(id)}
            >
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
      )}
    </SystemMessage>
  );
};

export const SingeOrgMessage = () => {
  const { id, role, activeOrgId, loading } = useCurrentUser();
  const changeActiveOrg = useChangeActiveOrg();
  const selectActiveOrg = activeOrganizationId => {
    changeActiveOrg({
      variables: {
        id,
        activeOrganizationId
      }
    });
  };

  const showSelectOrg = !loading && role !== TED_ROLE.name && !activeOrgId;

  return showSelectOrg ? <OrgSelectDialog onSelect={selectActiveOrg} /> : <></>;
};

export default SingeOrgMessage;
