import React from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";

import { CreateController } from "react-admin";

import { Form, PageHeader } from "ui";

export const CreatePage = ({ title, history, basePath, ...props }) => {
  return (
    <>
      <PageHeader title={title} />
      <Form
        actionProps={{
          onCancel: () => {
            history.push(basePath);
          }
        }}
        {...props}
      />
    </>
  );
};

CreatePage.defaultProps = {
  title: "ra.action.create"
};

export const Create = ({ savePage, ...props }) => (
  <CreateController {...props}>
    {({ save, ...controllerProps }) => (
      <CreatePage {...props} {...controllerProps} save={savePage || save} />
    )}
  </CreateController>
);

const enhance = compose(withRouter);

export default enhance(Create);
