import gql from "graphql-tag";
import { BULK_IMPORT } from "actions";
import { USERS } from "resources/types";

export const bulkImportUsers = ({
  file,
  inviteNow,
  contractId,
  saveUsers
}) => ({
  query: gql`
    mutation BulkImportUsers(
      $file: File!
      $contractId: Int!
      $saveUsers: Boolean
      $inviteNow: Boolean
    ) {
      result: createUserImport(
        file: $file
        contractId: $contractId
        saveUsers: $saveUsers
        inviteNow: $inviteNow
      ) {
        responseMessage
      }
    }
  `,
  variables: {
    file,
    inviteNow,
    contractId: parseInt(contractId, 10),
    saveUsers
  },
  context: {
    hasUpload: true
  },
  parseResponse: ({ data = {} }) => {
    const { result = {} } = data;
    return { data: result };
  }
});

const queries = {
  [USERS]: bulkImportUsers
};

export default (fetchType, resource, params) => {
  if (fetchType !== BULK_IMPORT) {
    return false;
  }

  return queries[resource] && queries[resource](params);
};
