import React from "react";
import { Link as RouterLink } from "react-router-dom";
import _get from "lodash.get";

import Link from "@material-ui/core/Link";

import PopoverList from "ui/PopoverList";

const ContractLink = ({ id, name }) => (
  <Link component={RouterLink} to={`/contracts/${id}`}>
    {name}
  </Link>
);

const ContractsCell = ({ id, contracts }) => (
  <PopoverList
    id={`contracts-${id}`}
    label={contracts.length}
    list={contracts}
    renderItem={ContractLink}
  />
);

ContractsCell.defaultProps = {
  contracts: []
};

export default ContractsCell;
