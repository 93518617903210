import React from "react";

import Card from "@material-ui/core/Card";
import logoDark from "assets/TEDMasterclass.dark.svg";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@ra-shim/material-ui/core";

import { LoginForm } from "react-admin";
import useTranslate from "hooks/useTranslate";
import { theme, legacyTheme } from "hooks/useStyles";
import { Notification } from "ui";

// https://stackoverflow.com/questions/9870512/how-to-obtain-the-query-string-from-the-current-url-with-javascript#answer-9870540
export const getQueryStringValue = key => {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
          encodeURIComponent(key).replace(/[.+*]/g, "\\$&") +
          "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  );
};

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    height: "1px",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
    background: "#fff",
    padding: 8 * 1.5,
    borderRadius: 3,
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)"
  },
  logo: {
    width: 95,
    height: "auto"
  },
  resetLink: {
    display: "block",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    marginTop: 8,
    textDecoration: "none",
    fontSize: ".75em"
  },
  avatar: {
    margin: 8 * 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    marginTop: 8 * 2,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: theme.typography.fontSize * 1.4
  }
}));

export const Login = () => {
  const { translate } = useTranslate();
  const classes = useStyles();
  const ssoPath = getQueryStringValue("return_path");

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <h1 className={classes.avatar}>
          <img src={logoDark} alt="TED Masterclass" className={classes.logo} />
          <span className={classes.title}>{translate("ui.loginTitle")}</span>
        </h1>
        <LoginForm
          redirectTo={ssoPath ? `/sso-auth?${ssoPath}` : "/dashboard"}
        />
      </Card>
      <a className={classes.resetLink} href="/request_password_reset">
        Reset Password
      </a>
      <Notification />
    </div>
  );
};

export const LoginPage = () => (
  <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={legacyTheme}>
      <Login />
    </MuiThemeProvider>
  </ThemeProvider>
);

export default LoginPage;
