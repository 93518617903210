import React from "react";

import FormRow from "ui/form/FormRow";
import DateInput from "ui/DateInput";

import useLinkedDateValidation from "hooks/useLinkedDateValidation";
import useFormValidation from "hooks/useFormValidation";
import useFormStyles from "hooks/useFormStyles";
import useTranslate from "hooks/useTranslate";

const ContractActivationFields = ({ editing, activationDate }) => {
  const afterStartDate = useLinkedDateValidation({
    name: "activationDate",
    linked: "startDate",
    operator: "gte"
  });
  const { required } = useFormValidation();
  const classes = useFormStyles();
  const { translate } = useTranslate();

  return (
    <FormRow
      title={translate("contracts.activation_title")}
      desc={translate("contracts.activation_desc")}
    >
      <DateInput
        name="activationDate"
        validate={{ required, afterStartDate }}
        id="contract-activation-date"
        className={classes.halfField}
        label={translate("fields.activationDate")}
        defaultValue={activationDate || null}
        disabled={!editing}
      />
    </FormRow>
  );
};

ContractActivationFields.defaultProps = {};

export default ContractActivationFields;
