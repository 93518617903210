import { useState } from "react";
import _get from "lodash.get";
import gql from "graphql-tag";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import useNotification from "hooks/useNotification";

import { graphTypeInt } from "hooks/useOrgQueries";
import {
  CREATE_CONTACT,
  UPDATE_CONTACT,
  formatContactData
} from "hooks/useContactQueries";

export const INVITE_REQUEST_FIELDS = gql`
  fragment InviteRequestFields on Contract {
    inviteEmailFooterImagePath
    inviteRequestType
    inviteRequestsEnabled
    inviteRequestEmailsRequired
    inviteRequestIntroText
    inviteRequestDisabledText
    inviteRequestRoleRequired
  }
`;

export const GET_CONTRACT = gql`
  ${INVITE_REQUEST_FIELDS}
  query getContract($id: Int!) {
    contract: Contract(id: $id) {
      __typename
      activationDate
      availableSeats
      autoUsersInactive
      communityAccess
      contacts {
        id
        firstName
        lastName
        email
        phone
        primary
      }
      endDate
      id
      invoiceNumber
      inviteEmailIntro
      inviteEmailOutro
      ...InviteRequestFields
      name
      organizationId
      organization {
        id
        name
      }
      seats
      startDate
      status
      talkUploads
      usersActiveForDays
    }
  }
`;

export const CREATE_CONTRACT = gql`
  ${INVITE_REQUEST_FIELDS}
  mutation createContract(
    $organizationId: Int!
    $activationDate: String
    $startDate: String
    $endDate: String
    $seats: Int
    $status: String
    $statusNote: String
    $invoiceNumber: String
    $autoUsersInactive: Boolean
    $usersActiveForDays: Int
    $talkUploads: Boolean
    $communityAccess: Boolean
    $inviteEmailIntro: String
    $inviteEmailOutro: String
    $inviteEmailFooterImage: File
    $inviteRequestsEnabled: Boolean
    $inviteRequestEmailsRequired: Boolean
    $inviteRequestType: String
    $inviteRequestIntroText: String
    $inviteRequestDisabledText: String
    $inviteRequestRoleRequired: Boolean
  ) {
    contract: createContract(
      organizationId: $organizationId
      activationDate: $activationDate
      startDate: $startDate
      endDate: $endDate
      seats: $seats
      status: $status
      statusNote: $statusNote
      invoiceNumber: $invoiceNumber
      autoUsersInactive: $autoUsersInactive
      usersActiveForDays: $usersActiveForDays
      talkUploads: $talkUploads
      communityAccess: $communityAccess
      inviteEmailIntro: $inviteEmailIntro
      inviteEmailOutro: $inviteEmailOutro
      inviteEmailFooterImage: $inviteEmailFooterImage
      inviteRequestsEnabled: $inviteRequestsEnabled
      inviteRequestEmailsRequired: $inviteRequestEmailsRequired
      inviteRequestType: $inviteRequestType
      inviteRequestIntroText: $inviteRequestIntroText
      inviteRequestDisabledText: $inviteRequestDisabledText
      inviteRequestRoleRequired: $inviteRequestRoleRequired
    ) {
      activationDate
      autoUsersInactive
      communityAccess
      endDate
      id
      invoiceNumber
      inviteEmailIntro
      inviteEmailOutro
      ...InviteRequestFields
      organizationId
      organization {
        id
        name
        organizationType
      }
      primaryContact {
        firstName
        lastName
        email
      }
      seats
      startDate
      status
      statusNote
      talkUploads
      usersActiveForDays
    }
  }
`;

export const UPDATE_CONTRACT = gql`
  ${INVITE_REQUEST_FIELDS}
  mutation updateContract(
    $id: Int!
    $activationDate: String
    $startDate: String
    $endDate: String
    $seats: Int
    $status: String
    $statusNote: String
    $invoiceNumber: String
    $autoUsersInactive: Boolean
    $usersActiveForDays: Int
    $talkUploads: Boolean
    $communityAccess: Boolean
    $inviteEmailIntro: String
    $inviteEmailOutro: String
    $inviteRequestsEnabled: Boolean
    $inviteRequestEmailsRequired: Boolean
    $inviteEmailFooterImage: File
    $inviteRequestType: String
    $inviteRequestIntroText: String
    $inviteRequestDisabledText: String
    $inviteRequestRoleRequired: Boolean
  ) {
    contract: updateContract(
      id: $id
      activationDate: $activationDate
      startDate: $startDate
      endDate: $endDate
      seats: $seats
      status: $status
      statusNote: $statusNote
      invoiceNumber: $invoiceNumber
      autoUsersInactive: $autoUsersInactive
      usersActiveForDays: $usersActiveForDays
      talkUploads: $talkUploads
      communityAccess: $communityAccess
      inviteEmailIntro: $inviteEmailIntro
      inviteEmailOutro: $inviteEmailOutro
      inviteRequestsEnabled: $inviteRequestsEnabled
      inviteRequestEmailsRequired: $inviteRequestEmailsRequired
      inviteRequestType: $inviteRequestType
      inviteEmailFooterImage: $inviteEmailFooterImage
      inviteRequestIntroText: $inviteRequestIntroText
      inviteRequestDisabledText: $inviteRequestDisabledText
      inviteRequestRoleRequired: $inviteRequestRoleRequired
    ) {
      activationDate
      communityAccess
      endDate
      id
      invoiceNumber
      inviteEmailIntro
      inviteEmailOutro
      ...InviteRequestFields
      organizationId
      organization {
        id
        name
        organizationType
      }
      primaryContact {
        firstName
        lastName
        email
      }
      status
      seats
      startDate
      talkUploads
      autoUsersInactive
      usersActiveForDays
    }
  }
`;

export const GET_CONTRACT_DATALIST = gql`
  query contractsDataList(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: Filter
  ) {
    list: allContracts(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      activationDate
      availableSeats
      endDate
      id
      invoiceNumber
      name
      organizationId
      organization {
        id
        name
        organizationType
      }
      primaryContact {
        firstName
        lastName
        email
      }
      status
      seats
      startDate
    }
    total: _allContractsMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
    }
  }
`;

const refetchQueriesCreate = [
  "getMenuCounts",
  "allContracts",
  "contractsDataList"
];

export const formatContractData = args => {
  const {
    inviteEmailFooterImage,
    organizationId,
    seats,
    usersActiveForDays,
    ...data
  } = args || {};

  return {
    ...data,
    organizationId: graphTypeInt(organizationId),
    seats: graphTypeInt(seats),
    usersActiveForDays: graphTypeInt(usersActiveForDays),
    inviteEmailFooterImage: _get(inviteEmailFooterImage, "[0]")
  };
};

const useContractMutation = props => {
  const { mutation, message, onError, onCompleted, ...rest } = props;
  const { showGraphQLError, showNotification } = useNotification();

  const [mutate, ...mutateArgs] = useMutation(mutation, {
    onCompleted: args => {
      showNotification(message, "info");
      onCompleted && onCompleted(args);
    },
    onError: args => {
      showGraphQLError(args);
      onError && onError();
    },
    ...rest
  });

  const formattedMutation = args =>
    mutate({
      ...args,
      variables: formatContractData(args.variables)
    });

  return [formattedMutation, ...mutateArgs];
};

export const useCreateContract = props =>
  useContractMutation({
    mutation: CREATE_CONTRACT,
    message: "contract_created",
    refetchQueries: refetchQueriesCreate,
    awaitRefetchQueries: true,
    ...props
  });

export const useUpdateContract = props =>
  useContractMutation({
    mutation: UPDATE_CONTRACT,
    message: "contract_updated",
    ...props
  });

export const useGetContract = id => {
  const { data, ...rest } = useQuery(GET_CONTRACT, {
    skip: !id,
    variables: { id: parseInt(id, 10) },
    fetchPolicy: "network-only"
  });
  const { contract } = data || {};

  return {
    data: contract,
    ...rest
  };
};

export const useCombinedUpdateContract = ({ onCompleted, onError } = {}) => {
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const { showGraphQLError, showNotification } = useNotification();

  const updateCominbed = async ({ contacts, ...contract }) => {
    setError(undefined);
    setData(undefined);

    try {
      const contractMutation = client.mutate({
        mutation: UPDATE_CONTRACT,
        variables: formatContractData(contract)
      });

      const contactMutations = contacts.map(c =>
        client.mutate({
          mutation: c.id ? UPDATE_CONTACT : CREATE_CONTACT,
          variables: formatContactData({ ...c, contractId: contract.id })
        })
      );

      const mutations = await Promise.all([
        ...contactMutations,
        contractMutation
      ]);

      showNotification("contracts.update_success", "success");
      onCompleted && onCompleted(mutations);
      setData(mutations);
    } catch (error) {
      onError && onError(error);
      setError(error);
      showGraphQLError(error);
    }

    setLoading(false);
  };

  return [updateCominbed, { loading, error, data }];
};

export const useCombinedCreateContract = ({ onCompleted, onError } = {}) => {
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const { showGraphQLError, showNotification } = useNotification();

  const createCombined = async ({ contacts, ...formData }) => {
    setError(undefined);
    setData(undefined);

    try {
      const contractMutation = await client.mutate({
        mutation: CREATE_CONTRACT,
        variables: formatContractData(formData),
        refetchQueries: refetchQueriesCreate
      });

      const {
        data: { contract }
      } = contractMutation;

      const contactMutations = await Promise.all(
        contacts.map(c =>
          client.mutate({
            mutation: CREATE_CONTACT,
            variables: formatContactData({ ...c, contractId: contract.id })
          })
        )
      );

      const data = {
        ...contract,
        contacts: contactMutations.map(({ data: { contact } }) => contact)
      };
      showNotification("contracts.create_success", "success");
      onCompleted && onCompleted(data);
      setData(data);
    } catch (error) {
      onError && onError(error);
      setError(error);
      showGraphQLError(error);
    }

    setLoading(false);
  };

  return [createCombined, { loading, error, data }];
};
