import _get from "lodash.get";

export const buildPrefillParams = params => {
  Object.keys(params).forEach(k => !params[k] && delete params[k]);
  return Object.keys(params)
    .reduce(
      (values, k) => [
        ...values,
        `${encodeURIComponent(`prefill_${k}`)}=${encodeURIComponent(params[k])}`
      ],
      []
    )
    .join("&");
};

export const formatThroughline = ({ entry }) => {
  const { throughline } = (entry && JSON.parse(entry)) || {};
  return throughline;
};
