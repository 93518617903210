import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { routerReducer as routing } from "react-router-redux";
import { adminReducer as admin, i18nReducer } from "react-admin";

import { systemMessageReducer as systemMessage } from "systemMessage";

import englishMessages from "./i18n/en";

export default combineReducers({
  admin,
  i18n: i18nReducer("en", englishMessages),
  form,
  routing,
  systemMessage
});
