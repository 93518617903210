import React from "react";
import pure from "recompose/pure";
import SvgIcon from "@material-ui/core/SvgIcon";

let UncheckedIcon = ({ viewBox, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 16 16">
    <rect
      width="15"
      height="15"
      x="0.5"
      y="0.5"
      stroke="currentColor"
      rx="3"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

UncheckedIcon = pure(UncheckedIcon);
UncheckedIcon.muiName = "SvgIcon";

export default UncheckedIcon;
