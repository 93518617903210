import React from "react";
import _get from "lodash.get";
import clsx from "clsx";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import Alert from "@material-ui/lab/Alert";

import { useGQLNotifications } from "hooks/useNotification";
import useStylesUtil from "hooks/useStylesUtil";

const INVITABLE_EMAILS_COUNT = gql`
  query getInvitableEmailsCount($filter: Filter) {
    emails: _allInvitableEmailsMeta(filter: $filter) {
      count
    }
  }
`;

const ContractInvitableCount = ({ contractId }) => {
  const classes = useStylesUtil();
  const { data } = useQuery(INVITABLE_EMAILS_COUNT, {
    ...useGQLNotifications(),
    fetchPolicy: "network-only",
    skip: !contractId,
    variables: {
      filter: { contractId }
    }
  });
  const className = clsx(classes.m5, classes.w100);
  const count = _get(data, "emails.count");

  if (!contractId) {
    return (
      <Alert className={className} severity="info" variant="outlined">
        A list of allowed users will need to be uploaded after the contract has
        been created.
      </Alert>
    );
  }

  if (count) {
    return (
      <Alert className={className} severity="success" variant="outlined">
        {count.toLocaleString()} email addresses are allowed.
      </Alert>
    );
  }

  return (
    <Alert className={className} severity="error" variant="filled">
      This contract has no allowed email addresses. Direct sign-ups will not
      work until at least one address is added.
    </Alert>
  );
};

export default ContractInvitableCount;
