import React from "react";
import { useFormContext, Controller } from "react-hook-form";

import { KeyboardDatePicker } from "@material-ui/pickers";

import useFormValidation from "hooks/useFormValidation";

const DatePicker = React.forwardRef((props, ref) => (
  <KeyboardDatePicker {...props} inputRef={ref} />
));

const DateInput = ({ defaultValue, name, label, validate, ...props }) => {
  const { control } = useFormContext();
  const { fieldErrors } = useFormValidation();
  const { required, ...rules } = validate || {};

  return (
    <Controller
      name={name}
      rules={{ required, validate: rules }}
      control={control}
      as={
        <DatePicker
          disableToolbar
          label={required ? `${label} *` : label}
          variant="inline"
          format="MM/DD/yyyy"
          margin="normal"
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          {...fieldErrors(name)}
          {...props}
        />
      }
      defaultValue={defaultValue}
    />
  );
};

DateInput.defaultProps = {};

export default DateInput;
