import React from "react";
import _get from "lodash.get";
import { useFormContext, Controller } from "react-hook-form";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import useFormValidation from "hooks/useFormValidation";

const SwitchInput = ({
  defaultValue,
  offLabel,
  onLabel,
  name,
  validate,
  ...props
}) => {
  const { errors, control } = useFormContext();
  const { fieldErrors } = useFormValidation(errors);

  return (
    <Controller
      name={name}
      rules={validate}
      control={control}
      render={({ onChange, onBlur, value, name, ref }) => (
        <FormControlLabel
          control={
            <Switch
              name={name}
              inputRef={ref}
              onBlur={onBlur}
              onChange={e => {
                const checked = !!_get(e, "target.checked", defaultValue);
                onChange(checked);
                if (typeof props.onChange === "function") {
                  props.onChange(checked);
                }
              }}
              // Force boolean to prevent switching between
              // controlled and uncontrolled on "undefined" or "null" values.
              checked={!!value}
            />
          }
          label={value ? onLabel : offLabel}
          {...fieldErrors(name)}
          {...props}
        />
      )}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

SwitchInput.defaultProps = {
  onLabel: "On",
  offLabel: "Off"
};

export default SwitchInput;
