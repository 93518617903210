import { CREATE, GET_ONE, UPDATE } from "react-admin";
import { RESET_PASSWORD, RESEND_INVITE } from "actions";
import { USERS } from "resources/types";
import gql from "graphql-tag";
import { getRoleId } from "resources";

export const userQueryFields = `
cycleCount
contracts {
  id
  organization {
    id
    name
  }
}
totalLessonsCompleted
email
firstName
groupSize
groupIds
groups {
  id
  name
}
id
invitedAt
lastActive
lastName
lastVisited
lessonsCompleted
purchaseStatus
organization {
  address1
  address2
  administrativeArea
  city
  countryCode
  id
  name
  organizationType
  postalCode
}
role
status
`;

const statuses = {
  pending: 0,
  active: 1,
  inactive: 2
};

export const resetPassword = params => {
  return {
    query: gql`
      mutation RequestPasswordReset($email: String!, $host: String) {
        requestPasswordResetEmail(email: $email, host: $host) {
          emailSentTo
          validationSummary
        }
      }
    `,
    variables: params,
    parseResponse: response => response
  };
};

export const resendInvite = params => {
  return {
    query: gql`
      mutation inviteUser($id: Int!) {
        data: inviteUser(id: $id) {
          id
        }
      }
    `,
    variables: params,
    parseResponse: response => response
  };
};

export const getOne = ({ id }) => {
  return {
    query: gql`
      query User($id: Int!) {
        data: User(id: $id) {
          ${userQueryFields}
        }
      }
    `,
    variables: { id: id && parseInt(id, 10) },
    parseResponse: response => response.data
  };
};

export const update = ({ id, data = {} }) => {
  const { email, firstName, lastName, groupIds, role } = data;
  const status = data.status && statuses[data.status];

  return {
    query: gql`
      mutation updateUser(
        $id: Int!
        $firstName: String
        $lastName: String
        $role: Int
        $status: Int
        $groupIds: [Int!]
      ) {
        data: updateUser(
          id: $id
          firstName: $firstName
          lastName: $lastName
          role: $role
          status: $status
          groupIds: $groupIds
        ) {
          ${userQueryFields}
        }
      }
    `,
    variables: {
      email,
      firstName,
      lastName,
      role: getRoleId(role),
      status,
      id: id && parseInt(id, 10),
      groupIds
    },
    parseResponse: response => response.data
  };
};

export const create = ({ data = {} }) => {
  const {
    email,
    firstName,
    lastName,
    contractId,
    groupIds,
    role,
    inviteNow
  } = data;

  return {
    query: gql`
      mutation createUser($email: String!, $firstName: String!, $lastName: String!, $role: Int, $contractId: Int!, $groupIds: [Int!], $inviteNow: Boolean) {
        data: createUser(email: $email, firstName: $firstName, lastName: $lastName, role: $role, contractId: $contractId, groupIds: $groupIds, inviteNow: $inviteNow) {
          ${userQueryFields}
        }
      }
    `,
    variables: {
      email,
      firstName,
      lastName,
      role: getRoleId(role),
      contractId,
      groupIds,
      inviteNow
    },
    parseResponse: response => response.data
  };
};

const queries = {
  [GET_ONE]: getOne,
  [UPDATE]: update,
  [CREATE]: create,
  [RESET_PASSWORD]: resetPassword,
  [RESEND_INVITE]: resendInvite
};

export default (fetchType, resource, params) => {
  if (resource !== USERS) {
    return false;
  }

  if (typeof params.id !== "undefined") {
    params = {
      ...params,
      id: parseInt(params.id, 10)
    };
  }

  return queries[fetchType] && queries[fetchType](params);
};
