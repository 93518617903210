import React, { useState } from "react";
import _get from "lodash.get";
import clsx from "clsx";
import { useForm, FormProvider } from "react-hook-form";
import countryCodes from "i18n/countryCodes";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import DeleteButton from "ui/buttons/DeleteButton";
import OrganizationFields from "./OrganizationFields";
import NotFound from "ui/NotFound";

import { ImageFieldDisplay } from "ui/ImageInput";
import FieldDisplay from "ui/FieldDisplay";
import FormRow from "ui/form/FormRow";

import usePermissions from "hooks/usePermissions";
import useTranslate from "hooks/useTranslate";
import useStylesUtil from "hooks/useStylesUtil";
import { useDeleteOrg, useUpdateOrg, useGetOrg } from "hooks/useOrgQueries";

export const OrganizationAddress = ({
  address1,
  address2,
  city,
  administrativeArea: state,
  postalCode,
  countryCode
}) => {
  const country = countryCodes.find(({ "alpha-2": code }) => {
    return countryCode && code === countryCode;
  });
  return (
    <>
      {address1}
      {address1 && <br />}
      {address2}
      {address2 && <br />}
      {city} {state} {postalCode}
      {(city || state || postalCode) && <br />}
      {country && country.name}
    </>
  );
};

export const OrganizationView = ({
  id,
  name,
  parentOrganization,
  logoPath,
  ...address
}) => {
  const { translate } = useTranslate();

  return (
    <FormRow
      title={translate("resources.organizations.detail.title")}
      desc={translate("resources.organizations.detail.desc")}
    >
      <Paper elevation={1}>
        <FieldDisplay
          label={translate("resources.organizations.fields.name")}
          value={name}
        />
        <FieldDisplay label={translate("fields.address")}>
          <OrganizationAddress {...address} />
        </FieldDisplay>
        <FieldDisplay
          label={translate(
            "resources.organizations.fields.parentOrganization.id"
          )}
          value={_get(parentOrganization, "name", "-")}
        />
        <ImageFieldDisplay label={"Organization Logo"} image={logoPath} />
      </Paper>
    </FormRow>
  );
};

export const OrganizationEditForm = ({ organization, onCancel }) => {
  const classes = useStylesUtil();
  const { translate } = useTranslate();
  const formMethods = useForm();
  const [updateOrganization] = useUpdateOrg({
    onCompleted: onCancel
  });

  const onSubmit = ({ countryCode, ...data }) => {
    updateOrganization({
      variables: { ...data, id: organization.id, countryCode: countryCode.code }
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <FormRow
          title={translate("resources.organizations.detail.title")}
          desc={translate("resources.organizations.detail.desc")}
        >
          <OrganizationFields organization={organization} />
        </FormRow>

        <div className={classes.justifyContentEnd}>
          <Button
            className={classes.mr4}
            onClick={() => {
              formMethods.reset();
              onCancel();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button variant="contained" color="secondary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export const DeleteOrgButton = ({ id, onCompleted }) => {
  const [deleteOrganization] = useDeleteOrg({ onCompleted });

  return (
    <DeleteButton onDelete={() => deleteOrganization({ variables: { id } })} />
  );
};

const OrganizationEdit = ({ id, history }) => {
  const [editing, setEditing] = useState(false);
  const classes = useStylesUtil();
  const { loading, data } = useGetOrg(id);
  const { canEditOrgs } = usePermissions();

  if (!id || (!loading && !_get(data, "organization.id"))) {
    return <NotFound />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  const organization = _get(data, "organization");
  const editMode = canEditOrgs ? editing : false;

  return (
    <Paper elevation={1} className={classes.maxWidth1k}>
      <div>
        <Typography variant="h1">
          {_get(organization, "name")}
          {canEditOrgs && (
            <>
              <Button
                variant="outlined"
                disabled={editing}
                onClick={() => setEditing(true)}
                className={clsx(classes.ml4, classes.mr4)}
              >
                Edit
              </Button>
              <DeleteOrgButton
                id={id}
                onCompleted={() => history.push(`/organizations`)}
              />
            </>
          )}
        </Typography>
      </div>

      {editMode && (
        <OrganizationEditForm
          organization={organization}
          onCancel={() => setEditing(false)}
        />
      )}
      {!editMode && <OrganizationView {...organization} />}
    </Paper>
  );
};

export default OrganizationEdit;
