import React from "react";
import compose from "recompose/compose";
import classnames from "classnames";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import useTranslate from "../hooks/useTranslate";

import { hideSystemMessageAction } from "actions";

import { FormatMessage, NotificationIcon } from "ui";

export const SystemMessageActions = ({
  classes,
  actions,
  dispatch,
  translate
}) => (
  <DialogActions classes={{ root: classes.actionsBox }}>
    {actions.map(({ action, autoClose = true, onClick, label }, idx) => (
      <Button
        key={`action-${idx}`}
        classes={{ root: classes.button }}
        onClick={() => {
          action && dispatch(action);
          onClick && onClick(dispatch);
          autoClose && dispatch(hideSystemMessageAction());
        }}
        variant="outlined"
        color="inherit"
      >
        {translate(label)}
      </Button>
    ))}
  </DialogActions>
);

SystemMessageActions.defaultProps = {
  actions: [],
  classes: {
    actionsBox: "actionsBox",
    button: "button"
  }
};

export const SystemMessage = ({
  actions,
  classes,
  children,
  dispatch,
  open,
  message,
  title,
  useTranslate: shouldTranslate,
  variant,
  disableBackdropClick,
  onClose,
  scroll
}) => {
  const { translate } = useTranslate();

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted
      disableBackdropClick={disableBackdropClick}
      PaperProps={{ elevation: 0 }}
      classes={{ paper: classnames(classes.paper, classes[variant]) }}
      onClose={onClose}
      scroll={scroll}
    >
      <NotificationIcon className={classes.icon} type={variant} />
      {title && (
        <DialogTitle disableTypography>
          <Typography variant="h4">
            {shouldTranslate === false ? title : translate(title)}
          </Typography>
        </DialogTitle>
      )}
      {message && (
        <DialogContent>
          <DialogContentText>
            <FormatMessage message={message} />
          </DialogContentText>
        </DialogContent>
      )}
      {actions && (
        <SystemMessageActions
          actions={actions}
          classes={classes}
          dispatch={dispatch}
          translate={translate}
        />
      )}
      {!actions && children}
    </Dialog>
  );
};

SystemMessage.defaultProps = {
  disableBackdropClick: true,
  variant: "warning",
  classes: {
    root: "root",
    paper: "paper",
    icon: "icon",
    button: "button",
    actionsBox: "actionsBox",
    warning: "warning",
    caution: "caution"
  }
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  paper: {
    position: "relative",
    borderTop: "3px solid",
    color: theme.palette.text.primary
  },
  icon: {
    "$paper &": {
      position: "absolute",
      top: 8 * 2,
      left: 8 * 2
    }
  },
  button: {
    minWidth: 145,
    marginRight: 8 * 2,

    "$caution &:hover": {
      color: theme.palette.caution.dark
    },
    "$warning &:hover": {
      color: theme.palette.error.dark
    }
  },
  actionsBox: {
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0
  },
  warning: {
    "&$paper": {
      borderColor: theme.palette.error.border,
      background: theme.palette.error.light,
      overflow: "visible"
    }
  },
  caution: {
    "&$paper": {
      borderColor: theme.palette.caution.border,
      background: theme.palette.caution.light,
      overflow: "visible"
    }
  }
});

export const useSystemMessageStyles = makeStyles({
  button: {
    minWidth: 145,
    marginRight: 16,
    background: "transparent"
  }
});

export const StyledSystemMessage = withStyles(styles, {
  name: "SystemMessage"
})(SystemMessage);

export const mapStateToProps = ({ systemMessage }) => systemMessage;

const ehance = compose(
  withStyles(styles, { name: "SystemMessage" }),
  connect(mapStateToProps)
);

export default ehance(SystemMessage);
