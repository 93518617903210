import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

import useTranslate from "hooks/useTranslate";
import useGroupsList from "hooks/useGroupsList";

export const ManageGroupsModal = ({ open, onCancel, onSave, onConfirm }) => {
  const { groupList: groups } = useGroupsList();
  const { translate } = useTranslate();
  const [selected, setSelected] = useState([]);

  const toggleItem = v =>
    setSelected(
      selected.includes(v) ? selected.filter(i => i !== v) : [...selected, v]
    );

  return (
    <Dialog
      disableBackdropClick
      open={open}
      aria-labelledby="select group(s)"
      onClose={onCancel}
    >
      <DialogTitle id="manage-groups">
        {translate("ui.addToGroups")}
      </DialogTitle>
      <DialogContent>
        <FormGroup aria-label="User Groups" name="groups">
          {groups.map(({ id, name }) => (
            <FormControlLabel
              htmlFor={`group-${id}`}
              value={`${id}`}
              key={id}
              control={
                <Checkbox
                  id={`group-${id}`}
                  onChange={() => toggleItem(id)}
                  checked={selected.indexOf(id) > -1}
                />
              }
              checked={selected.indexOf(id) !== -1}
              label={name}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button data-test="close" onClick={onCancel} variant={"raised"}>
          {translate("ra.action.cancel")}
        </Button>
        <Button
          data-test="save"
          onClick={() => onSave({ selected, onConfirm })}
          color={"secondary"}
          variant={"raised"}
        >
          {translate("ra.action.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ManageGroupsModal.defaultProps = {
  selected: []
};

export default ManageGroupsModal;
