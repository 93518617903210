import React from "react";
import useFromStyles from "hooks/useFormStyles";

export const FieldDisplay = ({ label, value, children }) => {
  const classes = useFromStyles();

  return (
    <div className={classes.fieldRoot}>
      <span className={classes.fieldLabel}>{label}</span>
      {value && <span className={classes.fieldValue}>{value}</span>}
      {!!children && <span className={classes.fieldValue}>{children}</span>}
    </div>
  );
};

export default FieldDisplay;
