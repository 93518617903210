import React from "react";

import moment from "moment";

import FormRow from "ui/form/FormRow";
import DateInput from "ui/DateInput";
import TextInput from "ui/TextInput";
import SelectInput from "ui/SelectInput";

import useFormValidation from "hooks/useFormValidation";
import useFormStyles from "hooks/useFormStyles";
import useTranslate from "hooks/useTranslate";

const ContractDetailFields = ({ editing, ...contract }) => {
  const { maxLength, required } = useFormValidation();
  const classes = useFormStyles();
  const { translate } = useTranslate();

  const {
    invoiceNumber,
    seats,
    status,
    startDate,
    endDate,
    statusNote
  } = contract;

  return (
    <FormRow
      title={translate("contracts.details_title")}
      desc={translate("contracts.details_desc")}
    >
      <TextInput
        name={"invoiceNumber"}
        label={translate("fields.invoiceNumber")}
        className={classes.halfField}
        validate={{ required, maxLength: maxLength(255) }}
        defaultValue={invoiceNumber}
        disabled={!editing}
      />
      <TextInput
        name={"seats"}
        label={translate("fields.seats")}
        className={classes.halfField}
        validate={{ required, maxLength: maxLength(255) }}
        defaultValue={seats}
        disabled={!editing}
      />
      <SelectInput
        name="status"
        label={translate("fields.status")}
        validate={{ required }}
        choices={["inactive", "active"]}
        optionText={status => translate(`ui.status.${status}`)}
        className={classes.fullField}
        defaultValue={status || ""}
        disabled={!editing}
      />
      <DateInput
        name="startDate"
        validate={{ required }}
        id="contract-start-date"
        className={classes.halfField}
        label={translate("fields.startDate")}
        defaultValue={
          (startDate && moment(startDate)) || new Date().toISOString()
        }
        disabled={!editing}
      />
      <DateInput
        name="endDate"
        validate={{ required }}
        id="contract-end-date"
        className={classes.halfField}
        label={translate("fields.endDate")}
        defaultValue={
          (endDate && moment(endDate)) || moment(new Date()).add(1, "year")
        }
        disabled={!editing}
      />
      <TextInput
        name={"statusNote"}
        label={translate("fields.statusNote")}
        className={classes.fullField}
        validate={{ maxLength: maxLength(255) }}
        defaultValue={statusNote}
        disabled={!editing}
      />
    </FormRow>
  );
};

ContractDetailFields.defaultProps = {};

export default ContractDetailFields;
