import React from "react";
import clsx from "clsx";
import gql from "graphql-tag";
import _get from "lodash.get";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import UserAddToContractBtn from "users/UserAddToContractBtn";
import useStylesUtil from "hooks/useStylesUtil";

export const GET_USER_CONTRACTS = gql`
  query getUserContracts($id: Int!) {
    user: User(id: $id) {
      id
      firstName
      lastName
      contracts {
        id
        name
        status
      }
    }
  }
`;

const UserContracts = ({ userId }) => {
  const classes = useStylesUtil();
  const { data, loading } = useQuery(GET_USER_CONTRACTS, {
    skip: !userId,
    variables: {
      id: userId
    }
  });

  const contracts = _get(data, "user.contracts", []);

  return (
    <Paper elevation={2} className={clsx(classes.mb10, classes.mt10)}>
      <Toolbar variant="dense" className={classes.px4}>
        <Typography variant="h5" id="tableTitle">
          Contracts
        </Typography>
        <Typography variant="h5" className={classes.mlauto}>
          {contracts.length}
        </Typography>
      </Toolbar>
      <Divider />
      <TableContainer>
        <Table size={"small"} aria-labelledby="tableTitle">
          <TableBody>
            {contracts.map(({ id, name, status }) => (
              <TableRow key={id}>
                <TableCell>
                  <Link
                    variant="body2"
                    color="inherit"
                    component={RouterLink}
                    to={`/contracts/${id}`}
                  >
                    {name || "[no name]"}
                  </Link>
                </TableCell>
                <TableCell align="right">{status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!contracts.length && <Divider />}
      <Toolbar variant="dense" className={classes.justifyCenter}>
        <UserAddToContractBtn userId={userId} />
      </Toolbar>
    </Paper>
  );
};

export default UserContracts;
