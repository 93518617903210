import React from "react";
import _get from "lodash.get";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from "react-hook-form";

import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormRow from "ui/form/FormRow";
import TextField from "@material-ui/core/TextField";

import ExportCSVButton from "ui/buttons/ExportCSVButton";

import { useGQLNotifications } from "hooks/useNotification";
import useTranslate from "hooks/useTranslate";
import useModalOpen from "hooks/useModalOpen";
import useStylesUtil from "hooks/useStylesUtil";

export const CREATE_INVITABLE_EMAIL_IMPORT = gql`
  mutation CreateInvitableEmailImport($file: File!, $contractId: Int!) {
    createInvitableEmailImport(file: $file, contractId: $contractId) {
      id
      jobId
      responseMessage
    }
  }
`;

const EXPORT_CSV = gql`
  mutation exportInvitableEmails($filter: Filter) {
    exportInvitableEmails(filter: $filter) {
      jobId
      status
    }
  }
`;

export const InvitableEmailImportModal = ({ closeModal, contractId }) => {
  const { translate } = useTranslate();
  const [createImportJob] = useMutation(CREATE_INVITABLE_EMAIL_IMPORT, {
    ...useGQLNotifications({
      successMsg: translate("contracts.email_list_import_success")
    })
  });
  const { register, getValues, trigger, errors } = useForm();
  const classes = useStylesUtil();
  const fieldError = !!_get(errors, "allowedEmails")
    ? translate("validation.required")
    : undefined;

  return (
    <form>
      <Alert className={classes.m5} variant="outlined" severity="warning">
        {translate("contracts.email_list_import_confirm_text")}
      </Alert>
      <DialogContent>
        <TextField
          name={"allowedEmails"}
          label={translate("contracts.email_list_import_title")}
          type="file"
          inputProps={{
            accept: "text/csv"
          }}
          inputRef={register({ required: "required" })}
          required={true}
          error={!!fieldError}
          helperText={fieldError}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" autoFocus onClick={closeModal} color="primary">
          {translate("ra.action.cancel")}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={async () => {
            const result = await trigger("allowedEmails");
            if (result) {
              createImportJob({
                variables: {
                  file: getValues("allowedEmails")[0],
                  contractId
                }
              });
              closeModal();
            }
          }}
        >
          Import
        </Button>
      </DialogActions>
    </form>
  );
};

const ContractInvitableEmailImport = ({ contractId }) => {
  const { translate } = useTranslate();
  const { isOpen, openModal, closeModal } = useModalOpen();

  if (!contractId) {
    return null;
  }

  return (
    <FormRow>
      <Button color="secondary" variant="outlined" onClick={openModal}>
        {translate("contracts.email_list_import")}
      </Button>
      <ExportCSVButton
        mutation={EXPORT_CSV}
        name="allowed emails"
        filter={{ contractId }}
      />
      <Dialog onClose={closeModal} open={isOpen}>
        <InvitableEmailImportModal
          closeModal={closeModal}
          contractId={contractId}
        />
      </Dialog>
    </FormRow>
  );
};

export default ContractInvitableEmailImport;
