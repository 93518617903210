import React from "react";
import _get from "lodash.get";
import copy from "copy-to-clipboard";

import MailIcon from "react-feather/dist/icons/mail";

import BulkActionButton from "ui/buttons/BulkActionButton";
import ConfirmButton from "ui/buttons/ConfirmButton";

import useNotification from "hooks/useNotification";
import useTranslate from "hooks/useTranslate";

export const BulkCopyEmails = ({ selectedRows }) => {
  const { translate } = useTranslate();
  const { showNotification } = useNotification();
  const emails = selectedRows.map(({ email }) => email).join(", ");
  const count = selectedRows.length;

  return (
    <ConfirmButton
      Button={
        <BulkActionButton
          Icon={MailIcon}
          title={`${translate("ui.viewEmails", {
            smart_count: count
          })} (${count})`}
          disabled={!count}
        />
      }
      confirmLabel={translate("ui.copyToClipboard")}
      onConfirm={() => {
        copy(emails);
        showNotification(
          "resources.users.notification.copy_emails_success",
          "success"
        );
      }}
      message={emails}
      title={translate("users.bulk_copy_emails", {
        smart_count: count
      })}
      variant="info"
    />
  );
};

BulkCopyEmails.defaultProps = {
  selectedRows: []
};

export default BulkCopyEmails;
