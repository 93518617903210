import _get from "lodash.get";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import { org_types } from "ui/fields/OrgTypeSelector";

const GET_CONTRACT_SETTINGS = gql`
  query GetContractSettings($id: Int!) {
    settings: allContracts(
      page: 1
      perPage: 1
      sortField: "createdAt"
      sortOrder: "DESC"
      filter: { organizationId: $id }
    ) {
      id
      inviteEmailIntro
      inviteEmailOutro
      talkUploads
      communityAccess
    }
    organization: Organization(id: $id) {
      id
      name
      organizationType
    }
  }
`;

const intro = `Good news! %orgName% and TED are joining forces to provide you and your colleagues an opportunity to identify, develop, and share your best ideas in the form of short, TED-style talks.\n\nThis email is your exclusive invitation to enroll in TED Masterclass for Orgs.`;

const outro = `That’s it! Keep an eye out for additional information regarding this program sent by your administrators at %orgName%.`;

const emailText = (value, name) => {
  const text = typeof value === "string" ? value : "";
  return text.replaceAll("%orgName%", name);
};

const removeNull = obj =>
  Object.entries(obj).reduce(
    (filtered, [prop, value]) => ({
      ...filtered,
      ...(value !== null ? { [prop]: value } : {})
    }),
    {}
  );

const useContractDefaultSettings = ({
  organizationId,
  organizationName,
  organizationType
}) => {
  const { data, loading, error } = useQuery(GET_CONTRACT_SETTINGS, {
    skip: !organizationId,
    variables: {
      id: organizationId
    },
    fetchPolicy: "network-only"
  });

  const type = _get(
    data,
    "organization.organizationType",
    org_types[organizationType]
  );
  const name = _get(data, "organization.name", organizationName || "%orgName%");
  const setings = removeNull(_get(data, "settings[0]", {}));

  return {
    data: {
      talkUploads: null,
      communityAccess: null,
      inviteEmailIntro: emailText(intro, name),
      inviteEmailOutro: emailText(outro, name),
      ...setings
    },
    loading,
    error
  };
};

export default useContractDefaultSettings;
