import { useEffect } from "react";

import useTranslate from "./useTranslate";
import { useFormContext } from "react-hook-form";
import _get from "lodash.get";

import moment from "moment";

const formatDate = date => new Intl.DateTimeFormat("en-US").format(date);

const isDate = date => !isNaN(date.getTime());

// Date values can be any one of:
// strings, dates, or moment objects. yay. :D
const normalizedDateString = value => {
  if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(value)) {
    return value.substr(0, 10);
  }

  if (moment.isMoment(value)) {
    return value.format("yyyy-MM-DD");
  }

  if (isDate(value)) {
    return value.toISOString().substr(0, 10);
  }

  return undefined;
};

// Normalize to a consistent DateTime value for
// comparison and formatting.
const normalizedDateTime = value => {
  const dateString = normalizedDateString(value);
  return new Date(`${dateString}T00:00:00.000-05:00`);
};

const comparisons = {
  gt: (a, b) => a > b,
  gte: (a, b) => a >= b,
  lt: (a, b) => a < b,
  lte: (a, b) => a <= b
};

const useLinkedDateValidation = ({ name, linked, operator }) => {
  const { getValues, watch, trigger } = useFormContext();
  const { translate } = useTranslate();
  const linkedValue = watch(linked);

  // Trigger a validation for the target field
  // if the linked value changes.
  useEffect(() => {
    if (linkedValue) {
      trigger(name);
    }
  }, [linkedValue, name, trigger]);

  const compareDates = value => {
    const linkedDate = normalizedDateTime(getValues(linked));
    const date = normalizedDateTime(value);

    if (!isDate(date)) {
      return translate("validation.invalidDate");
    }

    if (!isDate(linkedDate)) {
      return translate("validation.inavlidDateField", { fieldName: linked });
    }

    const key = Object.keys(comparisons).includes(operator) ? operator : "gte";
    const message = `validation.dateCompare_${key}`;

    return (
      comparisons[key](date, linkedDate) ||
      translate(message, { date: formatDate(linkedDate) })
    );
  };

  return compareDates;
};

export default useLinkedDateValidation;
