import { CREATE, CRUD_CREATE, CRUD_UPDATE, UPDATE } from "react-admin";
import { USERS } from "resources/types";

const basePath = `/${USERS}`;
const resource = USERS;

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESEND_INVITE = "RESEND_INVITE";
export const RESEND_MANY_INVITES = "RESEND_MANY_INVITES";

export const CANCEL_PENDING_INVITES = "CANCEL_PENDING_INVITES";
export const RESEND_PENDING_INVITES = "RESEND_PENDING_INVITES";

export const networkErrorNotifcation = {
  notification: {
    body: "ra.notification.http_error",
    level: "warning"
  }
};

export const basicFetchMeta = fetch => ({
  resource,
  fetch,
  onSuccess: {
    notification: {
      body: `resources.users.notification.${fetch.toLowerCase()}_success`,
      level: "info"
    },
    basePath
  },
  onFailure: {
    notification: {
      body: `resources.users.notification.${fetch.toLowerCase()}_error`,
      level: "warning"
    }
  }
});

export const resetPassword = (_, data) => ({
  type: RESET_PASSWORD,
  payload: { email: data.email, host: "localhost" },
  meta: basicFetchMeta(RESET_PASSWORD)
});

export const resendInviteAction = id => ({
  type: RESEND_INVITE,
  payload: { id },
  meta: basicFetchMeta(RESEND_INVITE)
});

export const creatUserSuccessMsg = {
  body: `resources.users.notification.create_success`,
  level: "info"
};

export const createUserAction = ({ notification, ...data }) => ({
  type: CRUD_CREATE,
  payload: { data },
  meta: {
    fetch: CREATE,
    resource,
    onSuccess: {
      notification: notification || creatUserSuccessMsg,
      basePath,
      redirectTo: basePath
    },
    onFailure: networkErrorNotifcation
  }
});

export const updateUserAction = ({ data, previousData }) => {
  return {
    type: CRUD_UPDATE,
    payload: { id: data.id, data, previousData },
    meta: {
      resource,
      fetch: UPDATE,
      onSuccess: {
        notification: {
          body: "resources.users.notification.update_success",
          level: "info"
        },
        redirectTo: "show",
        basePath,
        refresh: false
      },
      onFailure: networkErrorNotifcation
    }
  };
};
