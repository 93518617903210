import React, { memo, useEffect, useState } from "react";
import md5 from "js-md5";

import Paper from "@material-ui/core/Paper";
import CardHeader from "@material-ui/core/CardHeader";

import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css";

const formatSuhheading = ({ user, createdAt }) => {
  let name = false;

  if (user) {
    const { email, firstName, lastName } = user;
    name = `${firstName} ${lastName} (${email}) - `;
  }

  return (name || "") + new Date(createdAt).toLocaleDateString("en-US");
};

// It is difficult to test this.
// The primary functionality here is actualy for react-video.
export const usePlayerTimecode = player => {
  const [timeCode, setTimecode] = useState(0);

  useEffect(() => {
    const updateTime = ({ currentTime }) => {
      setTimecode(currentTime);
    };

    player && player.subscribeToStateChange(updateTime);
  }, [player]);

  return timeCode;
};

export const VideoCard = ({
  createdAt,
  children,
  title,
  titleId,
  url,
  user
}) => {
  const videoKey = md5(`${(user && user.id) || 0}-${url}`);
  const [startTime, setStarttime] = useState(0);
  const [player, setPlayer] = useState();
  const timeCode = usePlayerTimecode(player);

  useEffect(() => {
    const savedTime = localStorage.getItem(videoKey);
    savedTime && setStarttime(savedTime);
  }, [videoKey]);

  useEffect(() => {
    localStorage.setItem(videoKey, timeCode);
  }, [timeCode, videoKey]);

  return (
    <Paper>
      <CardHeader
        id={titleId}
        title={title}
        subheader={formatSuhheading({ user, createdAt })}
        action={children}
      />
      <Player
        playsInline
        autoPlay
        src={url}
        ref={p => setPlayer(p)}
        startTime={parseFloat(startTime)}
      >
        <BigPlayButton position="center" />
      </Player>
    </Paper>
  );
};

export default memo(VideoCard);
