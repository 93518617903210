import React from "react";
import _get from "lodash.get";

import CancelIcon from "react-feather/dist/icons/x-circle";
import BulkDeleteUsers from "users/bulk-actions/BulkDeleteUsers";

export const BulkCancelInvites = ({ selectedRows, ...props }) => (
  <BulkDeleteUsers
    confirmMsg={"users.cancel_invite_bulk_confirm"}
    Icon={CancelIcon}
    label={"users.bulk_cancel_invite"}
    selectedRows={selectedRows.filter(({ status }) => status === "invited")}
    successMsg={"users.cancel_invite_bulk_success"}
    {...props}
  />
);

BulkCancelInvites.defaultProps = {
  selectedRows: []
};

export default BulkCancelInvites;
