import React from "react";
import _get from "lodash.get";

import ConfirmDialog from "ui/ConfirmDialog";
import MuiButton from "@material-ui/core/Button";

import useModalOpen from "hooks/useModalOpen";

export const ConfirmButton = ({ Button, Dialog, onConfirm, ...props }) => {
  const { isOpen, openModal, closeModal } = useModalOpen();

  return (
    <>
      {React.cloneElement(Button, {
        onClick: openModal
      })}
      {React.cloneElement(Dialog, {
        open: !!isOpen,
        onCancel: closeModal,
        onConfirm: props => {
          closeModal();
          onConfirm(props);
        },
        variant: "caution",
        ...props
      })}
    </>
  );
};

ConfirmButton.defaultProps = {
  Button: <MuiButton />,
  Dialog: <ConfirmDialog />
};

export default ConfirmButton;
