import React, { useState } from "react";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import _uniq from "lodash.uniq";
import _get from "lodash.get";
import gql from "graphql-tag";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Popover from "@material-ui/core/Popover";

import ChevronDown from "ui/icons/ChevronDown";

import { isTEDAdmin } from "resources/users/roles";

import useMenuAnchor from "hooks/useMenuAnchor";
import useSwtichUser from "hooks/useSwitchUser";
import useHistory from "hooks/useHistory";

import LogoutButton from "ui/buttons/LogoutButton";
import UserAccount from "ui/UserAccount";

const sortByOrgName = (a, b) =>
  a.organization.name.localeCompare(b.organization.name);

const useStyles = makeStyles({
  padding: {
    padding: 0
  },
  divider: {
    marginBottom: 14
  },
  menuBox: {
    padding: 10,
    border: "1px solid #ECECEC",
    borderRadius: 4
  },
  linkBtn: {
    justifyContent: "flex-start",
    width: "100%",
    padding: "5px 20px",
    margin: "4px 0",

    "&:hover": {
      color: "inherit",
      textDecoration: "underline",
      background: "none"
    }
  },
  iconBtn: {
    borderRadius: 5,
    padding: 10,
    marginLeft: 10,
    marginRight: 0,
    fontSize: 14,
    lineHeight: 14 / 22,
    fontWeight: 700,
    color: "#fff",

    "&:hover, &.active": {
      color: "inherit",
      textDecoration: "none",
      background: "rgba(0, 0, 0, 0.3)"
    }
  },
  iconUser: {
    marginRight: -2
  }
});

export const GET_CURRENT_USER_ACCOUNT = gql`
  query GetCurrentUserAccount {
    user: loggedInUserQuery {
      id
      role
      email
      adminReadonly
      activeOrgId: activeOrganizationId
      activeOrg: activeOrganization {
        id
        name
      }
      organization {
        id
        name
      }
      associatedUsers {
        id
        role
        organization {
          id
          name
        }
      }
    }
  }
`;

export const UserAccountMenu = ({ className }) => {
  const classes = useStyles();
  const history = useHistory();
  const { isOpen, menuProps, openMenu, closeMenu } = useMenuAnchor();
  const { data } = useQuery(GET_CURRENT_USER_ACCOUNT);
  const { switchUser } = useSwtichUser();

  const currentUser = _get(data, "user", {});
  const associatedUsers = _get(currentUser, "associatedUsers", []);
  const users = _uniq(associatedUsers, "id");
  return (
    <div className={className}>
      <Button
        size="small"
        className={clsx(classes.iconBtn, { active: isOpen })}
        onClick={openMenu}
      >
        {_get(currentUser, "organization.name", "")}
        <ChevronDown />
      </Button>
      <Popover
        {...menuProps}
        id={isOpen ? "change-user-popover" : null}
        classes={{
          paper: classes.menuBox
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        {users.length > 1 && (
          <>
            <List classes={{ padding: classes.padding }}>
              {users
                .sort(sortByOrgName)
                .filter(({ role }) => role !== "user")
                .map(user => (
                  <UserAccount
                    key={user.id}
                    active={currentUser.id === user.id}
                    onClick={async () => {
                      closeMenu();
                      const nextUser = await switchUser(user.id);
                      const nextPath = isTEDAdmin(nextUser)
                        ? "/organizations"
                        : "/dashboard";
                      history.push(nextPath);
                    }}
                    {...user}
                  />
                ))}
            </List>
            <Divider className={classes.divider} />
          </>
        )}
        <ListItem
          component={RouterLink}
          to={`/users/${currentUser.id}`}
          className={classes.linkBtn}
          button
          dense
        >
          {currentUser.email}
        </ListItem>
        <LogoutButton className={classes.linkBtn} />
      </Popover>
    </div>
  );
};

UserAccountMenu.defaultProps = {
  currentUser: {}
};

export default UserAccountMenu;
