import React from "react";
import _get from "lodash.get";
import compose from "recompose/compose";
import { Redirect } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import {
  FormDataConsumer,
  TextField,
  TextInput,
  DisabledInput,
  SelectInput,
  translate,
  email,
  maxLength,
  required
} from "react-admin";

import GroupMembershipInput from "ui/fields/GroupMembershipInput";
import LessonsCompletedCell from "ui/datagrid/LessonsCompletedCell";
import LastActiveField from "ui/fields/LastActiveField";
import RequestShortcodeButton from "ui/RequestShortcodeButton";
import StatusField from "ui/fields/StatusField";
import FormMetaField from "ui/form/FormMetaField";
import FormRow from "ui/form/FormRow";
import UserContracts from "users/UserContracts";

import withCurrentUser from "ui/withCurrentUser";

import ROLES, { getRoleLevelMax } from "resources/users/roles";

export const MetaContent = ({ classes, record }) => (
  <>
    <FormMetaField title={"resources.users.edit.course_progress"}>
      <LessonsCompletedCell
        lessonsCompleted={_get(record, "lessonsCompleted")}
      />
    </FormMetaField>
    <FormMetaField title={"resources.users.edit.last_active"}>
      <LastActiveField
        record={record}
        source="lastActive"
        className={classes.fieldData}
      />
    </FormMetaField>
    <FormMetaField title={"resources.users.edit.partner"}>
      <TextField
        record={record}
        source="groupSize"
        className={classes.fieldData}
      />
    </FormMetaField>
    <UserContracts userId={record.id} />
    <RequestShortcodeButton userId={record.id} />
  </>
);

MetaContent.defaultProps = {
  record: {},
  classes: {
    progressField: "progressField",
    progressLabel: "progressLabel",
    fieldData: "fieldData"
  }
};

export const formatRoleNames = r => `userGroups.${r.name}`;

export const SelectUserRole = props => (
  <SelectInput
    source="role"
    id="role"
    choices={ROLES}
    optionText={formatRoleNames}
    optionValue="name"
    {...props}
  />
);

export const LinkedGroupField = ({ formData, classes, ...props }) => {
  return (
    <GroupMembershipInput
      {...props}
      formData={formData}
      className={classes.fullField}
      source="groupIds"
    />
  );
};

export const UserFields = ({
  classes,
  currentUser,
  editing,
  hidden,
  resource,
  record,
  viewType
}) => {
  const role = _get(currentUser, "role");
  const maxLevel = role && getRoleLevelMax(role);
  const fieldProps = {
    record: record,
    resource: resource,
    disabled: hidden || !editing
  };

  const activeOrgId = _get(currentUser, "activeOrgId");
  const userOrgId = _get(record, "organization.id");

  if (activeOrgId && userOrgId && activeOrgId !== userOrgId) {
    return <Redirect to={"/users"} />;
  }

  return (
    <FormRow
      title={viewType === "create" && "resources.users.create.invite_single"}
      MetaContent={
        viewType === "edit" && (
          <MetaContent
            classes={classes}
            record={record}
            title={"resources.users.edit.title"}
          />
        )
      }
    >
      <DisabledInput className={classes.hiddenField} source="id" id="id" />
      <TextInput
        {...fieldProps}
        className={classes.halfField}
        source={"firstName"}
        id="firstName"
        validate={[required()]}
      />
      <TextInput
        {...fieldProps}
        className={classes.halfField}
        source={"lastName"}
        id="lastName"
        validate={[required()]}
      />
      <TextInput
        {...fieldProps}
        className={classes.fullField}
        source={"email"}
        id="email"
        label={"resources.users.fields.email"}
        disabled={viewType !== "create" || fieldProps.disabled}
        validate={[required(), email(), maxLength(191)]}
      />
      <SelectInput
        {...fieldProps}
        source="role"
        id="role"
        className={classes.halfField}
        choices={ROLES.filter(r => r.level <= maxLevel)}
        optionText={formatRoleNames}
        optionValue="name"
        validate={[required()]}
      />
      <StatusField
        viewType={viewType}
        className={classes.halfField}
        {...fieldProps}
      />
      <FormDataConsumer
        source="organizationId"
        id="organizationId"
        classes={classes}
        {...fieldProps}
      >
        {LinkedGroupField}
      </FormDataConsumer>

      <DisabledInput
        className={classes.hiddenField}
        source="organizationId"
        id="organizationId"
      />
    </FormRow>
  );
};

UserFields.defaultProps = {
  record: {},
  currentUser: {
    role: false
  },
  classes: {
    halfField: "halfField",
    fullField: "fullField",
    hiddenField: "hiddenField"
  },
  viewType: "edit"
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  fullField: {
    width: "100%"
  },
  halfField: {
    width: `50%`
  },
  hiddenField: {
    position: "absolute !important",
    clip: "rect(1px, 1px, 1px, 1px)",
    padding: 0,
    border: 0,
    height: 1,
    width: 1,
    overflow: "hidden"
  },
  progressField: {
    width: 170
  },
  progressLabel: {
    textAlign: "left",
    fontSize: theme.typography.fontSize
  },
  fieldData: {
    color: theme.palette.text.light,
    fontSize: theme.typography.fontSize
  }
});

const enhance = compose(
  withStyles(styles, { name: "UserFields" }),
  translate,
  withCurrentUser
);

export default enhance(UserFields);
