import React from "react";
import _get from "lodash.get";

import FilterByOption from "ui/filters/FilterByOption";

const FilterById = props => (
  <FilterByOption
    optionLabel={({ value }) => _get(value, "name", "")}
    optionValue={({ id }) => id}
    {...props}
  />
);

export default FilterById;
