import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";

export const ConfirmLeaveMessage = ({ Message, history }) => {
  const [open, setOpen] = useState(false);
  const [allowTranstion, setAllowTranstion] = useState(false);
  const [blockedTx, setBlockedTx] = useState(() => undefined);

  useEffect(() => {
    // Returning false in the history.block callback blocks transitions.
    // calling history.block()() unblocks transitions.
    const unBlock = history.block(location => {
      setBlockedTx(location);
      return allowTranstion;
    });

    if (!allowTranstion && blockedTx) {
      setOpen(true);
    }

    if (allowTranstion) {
      unBlock();
      history.push(blockedTx.pathname);
    }

    return () => unBlock();
  }, [allowTranstion, blockedTx, setBlockedTx]);

  return React.cloneElement(Message, {
    isOpen: open,
    open,
    setOpen,
    onCancel: () => setOpen(false),
    onConfirm: () => setAllowTranstion(true)
  });
};

export default withRouter(ConfirmLeaveMessage);
