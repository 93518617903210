import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import useCurrentUser from "hooks/useCurrentUser";

export const ALL_CONTRACTS_QUERY = gql`
  query allContracts($filter: Filter) {
    allContracts(
      sortOrder: "ASC"
      sortField: "name"
      perPage: 10000
      filter: $filter
    ) {
      id
      name
      startDate
      endDate
      organization {
        id
        name
      }
    }
  }
`;

const useContractsList = props => {
  const { filter } = props || {};
  const { activeOrgId, id } = useCurrentUser();
  const { loading, error, data } = useQuery(ALL_CONTRACTS_QUERY, {
    skip: !id,
    displayName: "ALL_CONTRACTS_QUERY",
    variables: {
      filter: filter || (activeOrgId && { organizationId: activeOrgId }) || {}
    }
  });

  return { contracts: (data && data.allContracts) || [], loading, error };
};

export default useContractsList;
