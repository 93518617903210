import React from "react";
import _get from "lodash.get";
import { useLazyQuery } from "@apollo/react-hooks";

import CSVButton from "ui/buttons/CSVButton";

import useCurrentUser from "hooks/useCurrentUser";
import { useGQLNotifications } from "hooks/useNotification";
import useCSVDownload from "hooks/useCSVDownload";

const DownloadCSVButton = ({
  query,
  fields,
  listKey,
  filename,
  name,
  variables
}) => {
  const [getData, { loading, data, called }] = useLazyQuery(query, {
    ...useGQLNotifications(),
    fetchPolicy: "network-only"
  });
  const { activeOrgId } = useCurrentUser();
  const inProgress = called && loading;

  useCSVDownload({
    data: _get(data, listKey),
    fields,
    filename,
    startDownload: called && !!data
  });

  return (
    <CSVButton
      filter={_get(variables, "filter")}
      loading={inProgress}
      name={name}
      onClick={() =>
        getData({
          variables: { activeOrgId, page: 1, perPage: 100000, ...variables }
        })
      }
    />
  );
};

DownloadCSVButton.defaultProps = {
  listKey: "list"
};

export default DownloadCSVButton;
