import React from "react";

import FilterByOption from "ui/filters/FilterByOption";

const FilterByPurchased = props => (
  <FilterByOption
    options={["not_purchased", "purchased", "refunded"]}
    optionLabel={({ translate, value }) => translate(`purchaseStatus.${value}`)}
    {...props}
  />
);

export default FilterByPurchased;
