import { HIDE_SYSTEM_MESSAGE, SHOW_SYSTEM_MESSAGE } from "./actions";

export const initialState = {
  open: false
};

export const systemMessageReducer = (
  state = initialState,
  { type, payload = {} }
) => {
  if (type === SHOW_SYSTEM_MESSAGE) {
    return { ...state, ...payload, open: true };
  }
  if (type === HIDE_SYSTEM_MESSAGE) {
    return { ...state, open: false };
  }

  return state;
};
