import React from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import DataTableHeader from "./DataTableHeader";
import DataTablePagination from "./DataTablePagination";

const DataTable = ({ columns, data, DataTableRow }) => (
  <Paper>
    <TableContainer>
      <Table aria-labelledby="tableTitle">
        <DataTableHeader columns={columns} />
        <TableBody>
          {data && (
            <>
              {DataTableRow &&
                data.map(record =>
                  React.cloneElement(DataTableRow, {
                    ...record,
                    key: record.id
                  })
                )}
            </>
          )}
          {!data && (
            <TableRow>
              <TableCell colSpan={columns.length}>{"table.noRows"}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <DataTablePagination />
  </Paper>
);
DataTable.defaultProps = {
  columns: [],
  data: []
};

export default DataTable;
