import React from "react";
import { useMutation } from "@apollo/react-hooks";
import _get from "lodash.get";

import CSVButton from "ui/buttons/CSVButton";
import { useGQLNotifications } from "hooks/useNotification";
import useTranslate from "hooks/useTranslate";

export const ExportCSVButton = ({ filter, mutation, name }) => {
  const { translate } = useTranslate();
  const [exportCSV] = useMutation(mutation, {
    ...useGQLNotifications({ successMsg: translate("ui.exportStarted") })
  });

  return (
    <CSVButton
      filter={filter}
      name={name}
      onClick={() => {
        exportCSV({ variables: { filter } });
      }}
    />
  );
};

ExportCSVButton.defaultProps = {
  filter: {}
};

export default ExportCSVButton;
