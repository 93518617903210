import { buttonStyles, palette } from "./settings";

export const MuiSelect = {
  select: {
    color: palette.secondary.main,
    backgroundImage: buttonStyles.bgGradient,
    boxShadow: buttonStyles.boxShadow,
    padding: 8,
    fontSize: 14,

    "&$disabled": {
      color: palette.text.disabled,
      backgroundColor: palette.background.disabled,
      cursor: "not-allowed",

      "& ~ $icon": {
        color: palette.text.disabled
      }
    }
  },
  icon: {
    color: "#637381"
  }
};
