import React from "react";
import _get from "lodash.get";

import { SelectInput } from "react-admin";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import useTranslate from "hooks/useTranslate";

export const org_types_enum = {
  corp: 0,
  edu: 1,
  tedx: 2,
  st_leaders: 3,
  ted2020: 4
};

export const org_types = Object.keys(org_types_enum);

export const getOrgTypeId = type => _get(org_types_enum, type);

const OrgTypeSelector = ({ source, ...props }) => {
  const { translate } = useTranslate();
  const choices = Object.keys(org_types_enum).map(type => ({
    id: org_types_enum[type],
    type
  }));

  if (source) {
    return (
      <SelectInput
        source="organizationType"
        choices={choices}
        optionText={({ type }) =>
          translate(`resources.organizations.types.${type}`)
        }
        {...props}
      />
    );
  }

  const label = "Type of Organization";

  return (
    <TextField
      id="select-org-type"
      select
      name={"organizationType"}
      {...props}
      label={props.label || label}
      style={{ minWidth: 150 }}
    >
      {choices.map(({ id, type }) => (
        <MenuItem key={id} value={id}>
          {translate(`resources.organizations.types.${type}`)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default OrgTypeSelector;
