import React from "react";
import _get from "lodash.get";

import DownloadCSVButton from "ui/buttons/DownloadCSVButton";

import { GET_ORG_DATALIST } from "hooks/useOrgQueries";
import useTranslate from "hooks/useTranslate";

export const ExportOrgsButton = ({ filter }) => {
  const { translate } = useTranslate();

  return (
    <DownloadCSVButton
      query={GET_ORG_DATALIST}
      variables={{ filter }}
      filename={"organizations-export"}
      name={"organizations"}
      fields={{
        name: {
          label: translate(`headerCell.organizationName`)
        },
        contractsCount: {
          label: translate(`headerCell.contractsCount`),
          formatValue: contracts =>
            Array.isArray(contracts) ? contracts.length : 0
        },
        organizationType: {
          label: translate(`headerCell.organizationType`),
          formatValue: type =>
            translate(`resources.organizations.types.${type}_short`)
        },
        status: {
          label: translate(`headerCell.status`),
          formatValue: status => translate(`ui.status.${status}`)
        }
      }}
    />
  );
};

export default ExportOrgsButton;
