import React from "react";
import _get from "lodash.get";
import { useFormContext } from "react-hook-form";

import ContractInvitableEmailImport from "./ContractInvitableEmailImport";
import ContractInvitableCount from "./ContractInvitableCount";

import FormRow from "ui/form/FormRow";
import ImageInput from "ui/ImageInput";
import TextInput from "ui/TextInput";
import SelectInput from "ui/SelectInput";
import SwitchInput from "ui/SwitchInput";

import useFormValidation from "hooks/useFormValidation";
import useFormStyles from "hooks/useFormStyles";
import useTranslate from "hooks/useTranslate";

const ContractInviteRequestsFields = ({
  editing,
  id,
  inviteRequestDisabledText,
  inviteRequestsEnabled,
  inviteRequestEmailsRequired,
  inviteRequestIntroText,
  inviteRequestType,
  inviteEmailFooterImagePath
}) => {
  const { watch } = useFormContext();
  const isEnabled = watch("inviteRequestsEnabled", inviteRequestsEnabled);
  const requireEmails = watch(
    "inviteRequestEmailsRequired",
    inviteRequestEmailsRequired
  );
  const { required } = useFormValidation();
  const classes = useFormStyles();
  const { translate } = useTranslate();

  return (
    <>
      <FormRow
        title={translate("contracts.inviteRequests_title")}
        desc={translate("contracts.inviteRequests_desc")}
      >
        <SwitchInput
          name={"inviteRequestsEnabled"}
          label={translate("fields.inviteRequestsEnabled")}
          disabled={!editing}
          className={classes.fullField}
        />

        {isEnabled && (
          <>
            {requireEmails && <ContractInvitableCount contractId={id} />}
            <SwitchInput
              name={"inviteRequestEmailsRequired"}
              label={translate("fields.inviteRequestEmailsRequired")}
              disabled={!editing}
              className={classes.fullField}
            />
            <SelectInput
              name={"inviteRequestType"}
              choices={[
                "educator",
                "non_educator",
                "tedx_organizer",
                "tedx_speaker"
              ]}
              optionText={type => translate(`inviteRequestType.${type}`)}
              label={translate("fields.inviteRequestType")}
              className={classes.fullField}
              validate={{
                required
              }}
              disabled={!editing}
              defaultValue={inviteRequestType}
            />
            <TextInput
              name={"inviteRequestIntroText"}
              label={translate("fields.inviteRequestIntroText")}
              className={classes.fullField}
              validate={{
                required
              }}
              multiline
              disabled={!editing}
              defaultValue={inviteRequestIntroText}
            />
            <TextInput
              name={"inviteRequestDisabledText"}
              label={translate("fields.inviteRequestDisabledText")}
              className={classes.fullField}
              validate={{
                required
              }}
              multiline
              disabled={!editing}
              defaultValue={inviteRequestDisabledText}
            />
            <SwitchInput
              name={"inviteRequestRoleRequired"}
              label={translate("fields.inviteRequestRoleRequired")}
              className={classes.fullField}
              disabled={!editing}
            />
            <ImageInput
              name={"inviteEmailFooterImage"}
              label={translate("fields.inviteEmailFooterImage")}
              className={classes.fullField}
              editing={editing}
              image={inviteEmailFooterImagePath}
            />
          </>
        )}
      </FormRow>
      {isEnabled && requireEmails && (
        <ContractInvitableEmailImport contractId={id} />
      )}
    </>
  );
};

ContractInviteRequestsFields.defaultProps = {
  inviteRequestsEnabled: false
};

export default ContractInviteRequestsFields;
