import { useState } from "react";

const idCounter = {};

/**
 * Taken from: https://github.com/lodash/lodash/blob/3f585df05c68464acbdb63ff3c34ac9efed2a517/uniqueId.js#L20
 * Generates a unique ID. If `prefix` is given, the ID is appended to it.
 */
function uniqId(prefix = "tmca") {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0;
  }

  const id = ++idCounter[prefix];
  if (prefix === "tmca") {
    return `${id}`;
  }

  return `${prefix}${id}`;
}

export const useId = () => {
  const [id] = useState(uniqId("tmca-"));

  return id;
};

export default useId;
