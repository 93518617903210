import React from "react";
import clsx from "clsx";
import { useForm, FormProvider } from "react-hook-form";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import TextInput from "ui/TextInput";

import useFormValidation from "hooks/useFormValidation";
import useStylesUtil from "hooks/useStylesUtil";
import useTranslate from "hooks/useTranslate";

const NameField = props => {
  const { maxLength, required } = useFormValidation();

  return (
    <TextInput
      name={"name"}
      validate={{ required, maxLength: maxLength(100) }}
      {...props}
    />
  );
};

const GroupForm = ({ title, name, onSubmit, SecondaryAction }) => {
  const formMethods = useForm();
  const classes = useStylesUtil();
  const { translate } = useTranslate();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
        <Typography variant={"h4"} className={classes.p4}>
          {title}
        </Typography>
        <Divider />
        <div className={classes.p4}>
          <NameField label={translate("groups.single")} defaultValue={name} />
        </div>
        <Divider />
        <div className={clsx(classes.p4, classes.justifyContentEnd)}>
          {SecondaryAction && React.cloneElement(SecondaryAction)}
          <Button type={"submit"}>{translate("ra.action.save")}</Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default GroupForm;
