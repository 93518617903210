import React from "react";
import _get from "lodash.get";

import CountryCodeInput from "ui/CountryCodeInput";
import ImageInput from "ui/ImageInput";
import { OrgSelect } from "ui/fields/OrgSelectField";
import SelectInput from "ui/SelectInput";
import TextInput from "ui/TextInput";

import FormRow from "ui/form/FormRow";
import OrgTypeSelector, { getOrgTypeId } from "ui/fields/OrgTypeSelector";

import useFormValidation from "hooks/useFormValidation";
import useFormStyles from "hooks/useFormStyles";
import useTranslate from "hooks/useTranslate";

export const OrganizationFields = ({ organization }) => {
  const { maxLength, required } = useFormValidation();
  const classes = useFormStyles();

  const {
    name,
    address1,
    address2,
    countryCode,
    city,
    postalCode,
    administrativeArea,
    organizationType,
    logoPath
  } = organization || {};

  return (
    <>
      <TextInput
        name={"name"}
        label="Organization Name"
        className={classes.fullField}
        validate={{ required, maxLength: maxLength(255) }}
        defaultValue={name}
      />
      <TextInput
        name={"address1"}
        label="Street"
        className={classes.fullField}
        validate={{ required, maxLength: maxLength(255) }}
        defaultValue={address1}
      />
      <TextInput
        label="Apt, suit, etc. (optional)"
        name={"address2"}
        className={classes.fullField}
        validate={{ maxLength: maxLength(255) }}
        defaultValue={address2}
      />
      <TextInput
        label="City"
        name={"city"}
        className={classes.halfField}
        validate={{ required, maxLength: maxLength(255) }}
        defaultValue={city}
      />
      <TextInput
        label="Postal/ZIP code"
        name={"postalCode"}
        className={classes.halfField}
        validate={{ required, maxLength: maxLength(255) }}
        defaultValue={postalCode}
      />
      <div className={classes.halfField}>
        <CountryCodeInput
          label="Choose a country"
          name="countryCode"
          validate={{ required }}
          defaultValue={countryCode}
        />
      </div>
      <TextInput
        name={"administrativeArea"}
        label="State/Province"
        className={classes.halfField}
        validate={{ required, maxLength: maxLength(255) }}
        defaultValue={administrativeArea}
      />
      <SelectInput
        label="Parent Organization"
        name={"parentId"}
        className={classes.fullField}
        Select={
          <OrgSelect
            excludeIds={
              _get(organization, "id") ? [_get(organization, "id")] : []
            }
          />
        }
        defaultValue={_get(organization, "parentOrganization.id")}
      />
      <SelectInput
        name={"organizationType"}
        validate={{ required }}
        Select={<OrgTypeSelector className={classes.fullField} />}
        defaultValue={getOrgTypeId(organizationType)}
      />
      <ImageInput
        name={"logo"}
        label="Organization Logo"
        className={classes.fullField}
        editing={true}
        image={logoPath}
      />
    </>
  );
};

export default OrganizationFields;

export const OrganizationFormMeta = props => {
  const { translate } = useTranslate();

  return (
    <FormRow
      title={translate("resources.organizations.detail.title")}
      desc={translate("resources.organizations.detail.meta")}
      {...props}
    />
  );
};
