import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

export const ExternalLink = ({ classes, children, styles, ...props }) => (
  <Typography
    classes={{ root: classes.link, ...styles }}
    component="a"
    color="secondary"
    {...props}
  >
    {children}
  </Typography>
);

ExternalLink.defaultProps = {
  classes: {
    link: "link"
  }
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  link: {
    fontWeight: 700,
    textDecoration: "none",
    color: theme.palette.text.primary,

    "&:hover, &:active, &:focus": {
      color: theme.palette.secondary.main,
      textDecoration: "underline"
    }
  }
});

export default withStyles(styles, { name: "ExternalLink" })(ExternalLink);
