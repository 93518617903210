import React from "react";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";

export const Fieldbox = ({ classes, className, ...props }) => (
  <div className={classnames(classes.root, className)} {...props}>
    {props.children}
  </div>
);

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    background: theme.palette.common.white,
    padding: 8 * 1.5,
    borderRadius: 3,
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)"
  }
});

export default withStyles(styles, { name: "Fieldbox" })(Fieldbox);
