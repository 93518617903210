import React from "react";
import clsx from "clsx";
import _get from "lodash.get";

import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import IconRemove from "@material-ui/icons/HighlightOff";
import Grid from "@material-ui/core/Grid";

import useTranslate from "hooks/useTranslate";
import useStylesUtil from "hooks/useStylesUtil";

export const FiltersPanel = ({
  activeFilters,
  filter,
  onRemove,
  updateFilter
}) => {
  const utilClasses = useStylesUtil();
  const { translate } = useTranslate();

  if (activeFilters.length < 1) {
    return <></>;
  }

  return (
    <Paper
      classes={{
        root: clsx(utilClasses.border1, utilClasses.mb4)
      }}
    >
      <div className={utilClasses.p3}>
        <Grid container className={utilClasses.w100}>
          {activeFilters.map(FilterField => {
            const key = FilterField.props.filterKey;
            const value =
              filter && typeof filter[key] !== "undefined"
                ? filter[key]
                : undefined;
            const label = _get(FilterField, "props.label", `filters.${key}`);

            return (
              <Grid item key={key} className={utilClasses.mr4}>
                <IconButton
                  size="small"
                  className="hide-filter"
                  onClick={() => {
                    onRemove(key);
                  }}
                  title={"ra.action.remove_filter"}
                >
                  <IconRemove />
                </IconButton>
                {React.cloneElement(FilterField, {
                  filterKey: key,
                  updateFilter,
                  filterFieldProps: {
                    id: `lesson-filter-${key}`,
                    label: translate(label),
                    value,
                    onChange: ({ target }) => {
                      updateFilter(key, target.value);
                    }
                  }
                })}
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Paper>
  );
};

FiltersPanel.defaultProps = {
  activeFilters: [],
  filter: {},
  classes: {
    root: "root"
  }
};

export default FiltersPanel;
