import useTranslate from "./useTranslate";
import { useFormContext } from "react-hook-form";
import _get from "lodash.get";

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// This is based on the `validate` property in
// https://react-hook-form.com/api#register

const isFutureDate = value => {
  const today = new Date().setUTCHours(0, 0, 0, 0);
  const testDate = new Date(value).setUTCHours(0, 0, 0, 0);
  return !isNaN(testDate) && testDate >= today;
};

const futureDate = date => isFutureDate(date);

const positive = value => parseInt(value, 10) > 0;

const useFormValidation = () => {
  const { errors } = useFormContext();
  const { translate } = useTranslate();

  const messages = {
    futureDate: translate("validation.futureDate"),
    positive: translate("validation.positive")
  };

  const required = {
    value: true,
    message: translate("validation.required")
  };

  const maxLength = value => ({
    value,
    message: translate("validation.maxLength", { max: value })
  });

  const email = {
    value: EMAIL_REGEX,
    message: translate("validation.email")
  };

  const fieldErrors = fieldName => {
    const fieldError = _get(errors, fieldName, false);
    if (!errors || !fieldError) {
      return {};
    }

    return {
      error: true,
      helperText: messages[fieldError.type] || fieldError.message || ""
    };
  };

  return {
    email,
    futureDate,
    required,
    maxLength,
    positive,
    fieldErrors
  };
};

export default useFormValidation;
