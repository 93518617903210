export const MuiPaper = {
  root: {
    borderColor: "#eee"
  },
  elevation1: {
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  elevation2: {
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)"
  },
  elevation8: {
    boxShadow:
      "0px 5px 5px rgba(0, 0, 0, 0.05), 0px 8px 10px rgba(0, 0, 0, 0.07), 0px 3px 14px rgba(0, 0, 0, 0.06)"
  }
};
