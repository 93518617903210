import React from "react";
import _get from "lodash.get";

import FilterById from "ui/filters/FilterById";
import useContractsList from "hooks/useContractsList";

const FilterByContract = props => {
  const { contracts } = useContractsList();
  return <FilterById options={contracts} {...props} />;
};

export default FilterByContract;
