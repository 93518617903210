import React from "react";
import _get from "lodash.get";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import UserIcon from "react-feather/dist/icons/user";

import useTranslate from "hooks/useTranslate";

const useStyles = makeStyles(theme => ({
  activeItem: {
    backgroundColor: theme.dropDown.selectedBG,
    color: theme.palette.text.primary,
    opacity: 1
  },
  disabled: {
    "&.activeItem": {
      opacity: 1
    }
  },
  avatar: {
    width: 28,
    height: 28,
    border: `1px solid ${theme.dropDown.selectedBG}`,
    color: "#51677B",
    backgroundColor: theme.palette.common.white
  },
  iconwrap: {
    minWidth: 0,
    paddingRight: 14
  },
  listItem: {
    "&:hover": {
      backgroundColor: theme.dropDown.hoverBg
    }
  },
  textPrimary: {
    fontWeight: 700,
    marginTop: 0
  }
}));

export const UserAccount = ({ active, id, organization, role, onClick }) => {
  const classes = useStyles();
  const { translate } = useTranslate();

  return (
    <ListItem
      button
      dense
      id={`change-account-${id}`}
      disabled={active}
      className={clsx(classes.listItem, {
        [classes.activeItem]: active,
        activeItem: active
      })}
      classes={{
        disabled: classes.disabled
      }}
      onClick={() => onClick(id)}
    >
      <ListItemAvatar classes={{ root: classes.iconwrap }}>
        <Avatar classes={{ root: classes.avatar }}>
          <UserIcon height={18} width={18} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        classes={{
          primary: classes.textPrimary
        }}
        primary={_get(organization, "name", "")}
        secondary={role ? translate(`userGroups.${role}`) : ""}
      />
    </ListItem>
  );
};

export default UserAccount;
