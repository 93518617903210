import { buttonStyles, palette, spacing } from "./settings";

export const MuiSnackbarContent = {
  root: {
    position: "relative",
    display: "flex",
    flexWrap: "initial",
    alignItems: "flex-start",
    padding: spacing * 2,
    background: buttonStyles.bgGradient,
    boxShadow:
      "inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)",
    borderTop: `3px solid ${palette.text.light}`,
    borderRadius: "0 0 3px 3px",
    margin: spacing,
    transition: "box-shadow .2s cubic-bezier(.64,0,.35,1)",
    transitionDelay: ".1s",
    backgroundColor: palette.secondary.lightAlt,
    color: palette.text.primary,
    "@media (min-width: 0)": {
      minWidth: 288,
      maxWidth: 568,
      borderRadius: "0 0 3px 3px",
      flexGrow: "initial"
    }
  },
  message: {
    padding: 0,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%"
  },
  action: {
    margin: 0,
    marginTop: spacing * -1,
    padding: 0,
    paddingLeft: 0
  }
};

export const MuiSnackbar = {
  anchorOriginTopCenter: {
    top: "64px !important"
  }
};
