import { dropDown } from "./settings";

export const MuiMenuItem = {
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0,
    borderRadius: 0,
    fontWeight: 400,
    borderBottom: `1px solid ${dropDown.border}`,
    fontSize: 14,
    lineHeight: 22 / 14,

    "&$selected": {
      backgroundColor: dropDown.selectedBG,
      fontWeight: 500
    },

    "&$selected:hover": {
      backgroundColor: dropDown.hoverBg,
      fontWeight: 500
    }
  }
};
