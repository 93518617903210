import Papa from "papaparse";

export const validateRow = ({ row, idx, headers, last }) => {
  const values = row.filter(v => v);

  // Skip last row if empty.
  if (last && values.length < 1) {
    return values;
  }

  if (values.length < headers.length) {
    return [{ name: "missingColumn", value: "", row: idx }];
  }

  return values
    .map((col, cidx) => {
      const { name, validate } = headers[cidx] || {};
      return (
        (validate && !validate(col) && { name, value: col, row: idx }) || false
      );
    })
    .filter(v => v !== false);
};

export const duplicates = col => {
  return col.filter((value, idx) => {
    return value && col.indexOf(value) !== idx;
  });
};

export const duplicatesInColumn = col => {
  let prevIndex = [];
  return duplicates(col).map(d => {
    const row = col.indexOf(d, prevIndex[d] || col.indexOf(d) + 1);
    prevIndex[d] = row + 1;
    return {
      type: "duplicate",
      value: d,
      row
    };
  });
};

/**
 * @param {Array} rows
 * @param {Object} headers
 */
export const validateUnique = ({ rows, headers, unique }) => {
  return headers
    .filter(h => h.unique)
    .reduce((list, h) => {
      const idx = headers.indexOf(h);
      const col = rows.map(r => r[idx]);
      return list.concat(unique(col));
    }, []);
};

/**
 * @param {Array} rows
 * @param {Object} headers
 */
export const validateRowData = ({ rows, headers, validate }) => {
  return rows.reduce((list, row, idx) => {
    return list.concat(
      validate({ row, headers, idx, last: idx + 1 === rows.length })
    );
  }, []);
};

/**
 * @param {Array} data
 * @param {Object} config
 */
export const validateData = (data, config, validateRows, findDuplicates) => {
  const { headers = [] } = config;

  const rows = data.slice(1);
  const errors = validateRows({ rows, headers, validate: validateRow });
  const dupes = findDuplicates({ rows, headers, unique: duplicatesInColumn });

  return {
    errors: errors.concat(dupes).map(e => ({
      ...e,
      row: e.row + 2 // 0-index plus header row;
    }))
  };
};

/**
 * @param {File} csvFile
 * @param {Object} config
 */
export const validateCSV = (csvFile, config, validate = validateData) => {
  return new Promise((resolve, reject) => {
    Papa.parse(csvFile, {
      complete: function(results) {
        resolve(
          validate(results.data, config, validateRowData, validateUnique)
        );
      },
      error: function(error, file) {
        reject({ error, file });
      }
    });
  });
};
