import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import Button from "@material-ui/core/Button";
import GroupForm from "groups/GroupForm";

import ConfirmButton from "ui/buttons/ConfirmButton";

import useNotifcation from "hooks/useNotification";
import useStylesUtil from "hooks/useStylesUtil";
import useTranslate from "hooks/useTranslate";

export const DELETE_GROUP = gql`
  mutation deleteGroup($id: Int!) {
    deleteGroup(id: $id) {
      id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroup($name: String!, $id: Int!) {
    updateGroup(name: $name, id: $id) {
      id
    }
  }
`;

export const DeleteGroupButton = ({ name, id, mutation, onConfirm }) => {
  const classes = useStylesUtil();
  const { showGraphQLError } = useNotifcation();
  const { translate } = useTranslate();
  const deleteLabel = translate("ra.action.delete");
  const [deleteGroup] = useMutation(mutation, {
    onError: showGraphQLError,
    refetchQueries: ["groupsDataList"]
  });

  return (
    <ConfirmButton
      Button={<Button className={classes.red}>{deleteLabel}</Button>}
      onConfirm={() => {
        deleteGroup({ variables: { id } });
        typeof onConfirm === "function" && onConfirm();
      }}
      title={translate("groups.delete_confirm")}
      message={name}
      confirmLabel={deleteLabel}
    />
  );
};

DeleteGroupButton.defaultProps = {
  mutation: DELETE_GROUP
};

export const GroupEdit = ({ id, mutation, name: groupName, onSubmit }) => {
  const { showGraphQLError } = useNotifcation();
  const { translate } = useTranslate();
  const [updateGroup] = useMutation(mutation, {
    onError: showGraphQLError,
    refetchQueries: ["groupsDataList"]
  });

  return (
    <GroupForm
      title={translate("groups.edit")}
      id={id}
      name={groupName}
      onSubmit={({ name }) => {
        typeof onSubmit === "function" && onSubmit();
        updateGroup({
          variables: {
            id: parseInt(id, 10),
            name
          }
        });
      }}
      SecondaryAction={
        <DeleteGroupButton id={id} name={groupName} onConfirm={onSubmit} />
      }
    />
  );
};

GroupEdit.defaultProps = {
  mutation: UPDATE_GROUP
};

export default GroupEdit;
