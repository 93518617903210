import React from "react";

import Button from "@material-ui/core/Button";
import IconFilter from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import useMenuAnchor from "hooks/useMenuAnchor";
import useTranslate from "hooks/useTranslate";

export const FiltersDropdown = ({ className, filterList, onSelect }) => {
  const { translate } = useTranslate();
  const { closeMenu, menuProps, openMenu } = useMenuAnchor();

  if (!filterList || !filterList.length) {
    return <></>;
  }

  return (
    <>
      <Button className={className} onClick={openMenu} color="inherit">
        <IconFilter /> <b>{translate("ra.action.add_filter")}</b>
      </Button>

      <Menu {...menuProps}>
        {filterList.map(({ props: { filterKey, label } }) => (
          <MenuItem
            key={filterKey}
            onClick={() => {
              closeMenu();
              onSelect(filterKey);
            }}
          >
            {translate(label || `filters.${filterKey}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FiltersDropdown;
