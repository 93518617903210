import { spacing } from "./settings";

export const MuiDialog = {
  paperWidthSm: {
    maxWidth: 640,
    width: "100%"
  }
};

export const MuiDialogActions = {
  root: {
    justifyContent: "flex-start",
    paddingLeft: spacing * 7.5 - spacing,
    paddingBottom: spacing * 2.5
  }
};

export const MuiDialogContent = {
  root: {
    paddingLeft: spacing * 7.5,
    paddingBottom: spacing * 2
  }
};

export const MuiDialogContentText = {
  root: {
    marginTop: spacing * 1.5
  }
};

export const MuiDialogTitle = {
  root: {
    paddingBottom: 2,
    paddingTop: spacing * 2 + 2,
    paddingLeft: spacing * 7.5
  }
};
