import React from "react";
import gql from "graphql-tag";

import * as cols from "ui/datagrid/columnTypes";
import DataList from "ui/DataList";
import ExportCSVButton from "ui/buttons/ExportCSVButton";
import { formatThroughline } from "insights/NominateButton";

const EXPORT_USERS_CSV = gql`
  mutation exportEntries($filter: Filter) {
    exportEntries(filter: $filter) {
      jobId
      status
    }
  }
`;

const GET_THROUGHLINES = gql`
  query GetThroughLines(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: Filter
  ) {
    list: allThroughlineEntries(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      entry
      createdAt: timestamp
      user {
        firstName
        lastName
        email
      }
      __typename
    }
    total: _allThroughlineEntriesMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
      __typename
    }
  }
`;

const columns = [
  { field: "lastName", ...cols.name, headerName: "headerCell.name" },
  {
    field: "createdAt",
    ...cols.timestampString,
    headerName: "headerCell.submitted"
  },
  {
    field: "entry",
    ...cols.baseCol,
    flex: 1,
    headerName: "headerCell.throughlines",
    valueFormatter: ({ value }) => formatThroughline({ entry: value })
  }
];

const ThroughLines = ({ filter }) => (
  <DataList
    sortModel={[
      {
        field: "createdAt",
        sort: "desc"
      }
    ]}
    columns={columns}
    preFilter={filter}
    ExportButton={
      <ExportCSVButton
        mutation={EXPORT_USERS_CSV}
        filter={filter}
        name={"throughlines"}
      />
    }
    query={GET_THROUGHLINES}
    showSearch={false}
    dataGrid
  />
);

export default ThroughLines;
