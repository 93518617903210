import React from "react";
import _get from "lodash.get";

import gql from "graphql-tag";

import BulkUpdate from "ui/BulkUpdate";
import UserXIcon from "react-feather/dist/icons/user-x";

export const DELETE_USER = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const BulkDeleteUsers = ({ selectedRows, ...props }) => {
  const emails = selectedRows.map(({ email }) => email);

  return (
    <BulkUpdate
      message={emails.join(", ")}
      selectedRows={selectedRows}
      {...props}
    />
  );
};

BulkDeleteUsers.defaultProps = {
  Icon: UserXIcon,
  label: "users.bulk_delete_users",
  mutation: DELETE_USER,
  selectedRows: [],
  confirmMsg: "users.delete_confirm",
  successMsg: "users.delete_success"
};

export default BulkDeleteUsers;
