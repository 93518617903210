import React from "react";
import pure from "recompose/pure";
import SvgIcon from "@material-ui/core/SvgIcon";

let DragNDropIcon = ({ viewBox, color, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 20 20">
    <defs>
      <path
        id="a"
        d="M19.317 12.052L14 10.28V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h5.28l1.772 5.317a.998.998 0 0 0 1.843.13l1.85-3.701 3.703-1.851a1 1 0 0 0-.131-1.843zM2 16a1 1 0 1 0-2 0v1a1 1 0 0 0 1 1h1a1 1 0 1 0 0-2M16 2a1 1 0 1 0 2 0V1a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2M6 2h1a1 1 0 1 0 0-2H6a1 1 0 1 0 0 2m5 0h1a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2M2 0H1a1 1 0 0 0-1 1v1a1 1 0 1 0 2 0 1 1 0 1 0 0-2M1 8a1 1 0 0 0 1-1V6a1 1 0 1 0-2 0v1a1 1 0 0 0 1 1m0 5a1 1 0 0 0 1-1v-1a1 1 0 1 0-2 0v1a1 1 0 0 0 1 1m13.553 1.105a1.002 1.002 0 0 0-.447.447l-.921 1.841-1.604-4.812 4.813 1.604-1.841.92zM6 12.002V6h6v3.612l-1.683-.561a.998.998 0 1 0-1.265 1.265L9.612 12H6zM7 16H6a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2M17 5a1 1 0 0 0-1 1v1a1 1 0 1 0 2 0V6a1 1 0 0 0-1-1"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill={color} xlinkHref="#a" />
      <g fill={color} mask="url(#b)">
        <path d="M0 0h21v21H0z" />
      </g>
    </g>
  </SvgIcon>
);

DragNDropIcon.defaultProps = {
  color: "currentColor"
};

DragNDropIcon = pure(DragNDropIcon);
DragNDropIcon.muiName = "SvgIcon";

export default DragNDropIcon;
