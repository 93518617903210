import gql from "graphql-tag";
import _get from "lodash.get";

import { useQuery } from "@apollo/react-hooks";

export const welcomeUrl = "/community_welcome";
export const membersUrl = "https://members.ted.com/";

export const GET_COMMNITY_LINK = gql`
  query getCommunityLink {
    user: loggedInUserQuery {
      id
      communityAccess
      externalId
    }
  }
`;

const useCommunityLink = () => {
  const { data } = useQuery(GET_COMMNITY_LINK);

  if (!_get(data, "user.communityAccess", false)) {
    return null;
  }

  return _get(data, "user.externalId") ? membersUrl : welcomeUrl;
};

export default useCommunityLink;
