import React from "react";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";

import Link from "@material-ui/core/Link";

import EllipsisText from "react-ellipsis-text";

import useStylesUtil from "hooks/useStylesUtil";
import useTranslate from "hooks/useTranslate";

const UserCell = ({ id, firstName, lastName, email }) => {
  const { translate } = useTranslate();
  const classes = useStylesUtil();
  const name =
    firstName || lastName
      ? `${firstName} ${lastName}`
      : translate("users.missing_name");

  return (
    <div className={clsx(classes.flexCol, classes.lineHeight1)}>
      <Link
        component={RouterLink}
        to={`/users/${id}`}
        data-tracking-id="user-page-link"
      >
        <EllipsisText text={name} length={30} />
      </Link>
      {email && <EllipsisText text={email} length={30} />}
    </div>
  );
};

export default UserCell;
