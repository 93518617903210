import React, { useContext } from "react";
import moment from "moment";

import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import EllipsisText from "react-ellipsis-text";

import CreateButton from "ui/buttons/CreateButton";
import DataList, { DataListContext } from "ui/DataList";
import OrgTypeField from "ui/fields/OrgTypeField";
import Link from "ui/Link";

import { org_types_enum } from "ui/fields/OrgTypeSelector";
import FilterByOrganization from "ui/filters/FilterByOrganization";
import FilterByOrganizationType from "ui/filters/FilterByOrganizationType";
import FilterByStatus from "ui/filters/FilterByStatus";

import { GET_CONTRACT_DATALIST } from "hooks/useContractQueries";
import useTranslate from "hooks/useTranslate";
import useCurrentUser from "hooks/useCurrentUser";
import usePermissions from "hooks/usePermissions";
import useStylesUtil from "hooks/useStylesUtil";

const columns = [
  { id: "name", label: "headerCell.id" },
  { id: "organizationName" },
  { id: "invoiceNumber" },
  {
    id: "last_name",
    label: "headerCell.pll"
  },
  { id: "seats", label: "headerCell.avilable_slash_total_seats" },
  { id: "startDate" },
  { id: "endDate" },
  { id: "organizationType" },
  { id: "status" }
];

export const PrimaryContact = ({ firstName, lastName, email }) => (
  <Typography>
    <EllipsisText text={`${firstName} ${lastName}`} length={30} />
    <br />
    <EllipsisText text={email} length={30} />
  </Typography>
);

export const ContractDataRow = ({
  availableSeats,
  endDate,
  id,
  invoiceNumber,
  name,
  organization,
  primaryContact,
  seats,
  startDate,
  status
}) => {
  const { translate } = useTranslate();
  const { updateFilter } = useContext(DataListContext);
  const classes = useStylesUtil();
  const { organizationType } = organization;

  return (
    <TableRow id={`contract-${id}`} tabIndex={-1}>
      <TableCell>
        <Link to={`/contracts/${id}`}>{name}</Link>
      </TableCell>
      <TableCell>
        <Link to={`/organizations/${organization.id}`}>
          {organization.name || "-"}
        </Link>
      </TableCell>
      <TableCell>{invoiceNumber}</TableCell>
      <TableCell>
        {(primaryContact && <PrimaryContact {...primaryContact} />) || "-"}
      </TableCell>
      <TableCell>
        <Link to={"/users"} queryParams={{ filter: { contractId: id } }}>
          {availableSeats} / {seats}
        </Link>
      </TableCell>
      <TableCell>{moment(startDate).format("MM/DD/YY")}</TableCell>
      <TableCell>{moment(endDate).format("MM/DD/YY")}</TableCell>
      <TableCell>
        <Button
          className={classes.bold}
          onClick={() =>
            updateFilter("organizationType", org_types_enum[organizationType])
          }
        >
          <OrgTypeField organizationType={organizationType} />
        </Button>
      </TableCell>
      <TableCell>{translate(`ui.status.${status}`)}</TableCell>
    </TableRow>
  );
};

export const filters = [
  <FilterByStatus
    filterKey="status"
    label={"filters.status"}
    options={["active", "inactive"]}
  />,
  <FilterByOrganization key="organizationId" filterKey="organizationId" />,
  <FilterByOrganizationType
    filterKey="organizationType"
    label={"filters.organizationType"}
  />
];

export const singleOrgFilters = [
  <FilterByStatus
    filterKey="status"
    label={"filters.status"}
    options={["active", "inactive"]}
  />
];

const ContractsList = ({ query }) => {
  const { translate } = useTranslate();
  const { activeOrgId } = useCurrentUser();
  const { canCreateOrgs } = usePermissions();

  return (
    <DataList
      columns={columns.map(col => ({ ...col, label: translate(col.label) }))}
      DataTableRow={<ContractDataRow />}
      CreateButton={
        canCreateOrgs ? (
          <CreateButton
            to={`/contracts/create`}
            text={translate("contracts.create")}
          />
        ) : (
          undefined
        )
      }
      filters={activeOrgId ? singleOrgFilters : filters}
      title={translate("contracts.plural")}
      preFilter={(activeOrgId && { organizationId: activeOrgId }) || undefined}
      searchText={translate("contracts.search")}
      query={query}
    />
  );
};

ContractsList.defaultProps = {
  query: GET_CONTRACT_DATALIST
};

export default ContractsList;
