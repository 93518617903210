import React from "react";
import _get from "lodash.get";
import gql from "graphql-tag";
import { useFormContext } from "react-hook-form";

import FormRow from "ui/form/FormRow";
import { OrgSelect } from "ui/fields/OrgSelectField";
import SelectInput from "ui/SelectInput";

import useFormValidation from "hooks/useFormValidation";
import useFormStyles from "hooks/useFormStyles";
import useTranslate from "hooks/useTranslate";

export const GET_ORG_NAME = gql`
  query GetOrganizationName($id: Int!) {
    organization: Organization(id: $id) {
      id
      name
    }
  }
`;

const ContractOrganization = ({ organizationId }) => {
  const { required } = useFormValidation();
  const { translate } = useTranslate();
  const classes = useFormStyles();

  return (
    <FormRow
      title={translate("resources.organizations.detail.title")}
      desc={translate("resources.organizations.detail.desc")}
    >
      <SelectInput
        name={"organizationId"}
        validate={{ required }}
        label={translate("resources.organizations.single")}
        Select={
          <OrgSelect
            label={translate("fields.organization")}
            className={classes.fullField}
          />
        }
        disabled={!!organizationId}
        defaultValue={organizationId || ""}
      />
    </FormRow>
  );
};

export default ContractOrganization;
