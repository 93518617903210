import React from "react";
import compose from "recompose/compose";
import classNames from "classnames";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";

import {
  showNotification as showNotificationAction,
  translate
} from "react-admin";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { csvUploadAction } from "./actions";
import { DragNDropIcon } from "ui";

export const formatErrors = ({ errors, translate }) =>
  errors.map(({ type, ...e }) => {
    if (type === "duplicate") {
      return translate("import.duplicate", e);
    }

    if (e.name && e.name === "missingColumn") {
      return translate("import.error_missing", e);
    }

    return translate("import.error_row", {
      ...e,
      label: translate(`import.${e.name}`)
    });
  });

export const handleFileDrop = async ({
  afterDrop,
  config,
  csvUploadAction: csvUpload,
  files,
  showNotificationAction: showNotification,
  translate,
  validateCSVFile: validateCSV
}) => {
  const file = files[0];
  const { errors = [] } = (file && (await validateCSV(file, config))) || {};
  csvUpload({ valid: !errors.length });
  if (errors.length > 0) {
    showNotification("import.error", "warning", {
      messageArgs: {
        list: formatErrors({ errors, translate }),
        skipTranslate: true
      }
    });
    return false;
  }
  afterDrop(file);
};

export const BulkUploadField = ({
  accept,
  acceptFile,
  classes,
  file,
  handleDrop,
  isLoading,
  rejectFile,
  uploadText,
  translate,
  ...props
}) => {
  if (isLoading) {
    return (
      <div className={classes.dropZone}>
        <CircularProgress size={32} />
      </div>
    );
  }

  if (file) {
    return <div className={classes.dropZone}>{file.name}</div>;
  }

  return (
    <Dropzone
      className={classes.dropZone}
      multiple={false}
      disabled={!!file}
      accept={accept}
      onDrop={files => handleDrop({ files, translate, ...props })}
    >
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
        <div
          {...getRootProps()}
          className={classNames(classes.dropZone, {
            [classes.dropZoneReject]: isDragReject,
            [classes.dropZoneAccept]: isDragAccept
          })}
        >
          <input {...getInputProps()} />
          {isDragReject && translate(rejectFile)}
          {isDragAccept && translate(acceptFile)}
          {!isDragAccept && !isDragReject && (
            <>
              <DragNDropIcon />
              {translate(uploadText)}
            </>
          )}
        </div>
      )}
    </Dropzone>
  );
};

BulkUploadField.defaultProps = {
  accept: "text/csv",
  acceptFile: "import.accept_file",
  rejectFile: "import.reject_file",
  uploadText: "import.upload_text",
  handleDrop: handleFileDrop,
  classes: {
    dropZone: "dropZone",
    dropZoneReject: "dropZoneReject",
    dropZoneAccept: "dropZoneAccept"
  }
};

/* istanbul ignore next Style Block is covered by Material-UI. */
const styles = theme => ({
  dropZone: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `2px dashed ${theme.palette.secondary.dark}`,
    background: "rgba(179,188,245,0.10)",
    width: "100%",
    height: 220,
    fontSize: 20,
    color: theme.palette.secondary.dark
  },
  dropZoneReject: {
    borderColor: theme.palette.error.border,
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  }
});

export const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0
});

const enhance = compose(
  withStyles(styles, { name: "BulkUploadField" }),
  translate,
  connect(
    mapStateToProps,
    { showNotificationAction, csvUploadAction }
  )
);

export default enhance(BulkUploadField);
