import React, { Component } from "react";
import PropTypes from "prop-types";

import { SelectInput, DisabledInput, translate, required } from "react-admin";

export const statuses_enum = { pending: 0, invited: 3, active: 1, inactive: 2 };

export class StatusField extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    translate: PropTypes.func,
    resource: PropTypes.string
  };

  render() {
    const {
      className,
      disabled,
      translate,
      resource,
      record = {},
      viewType = "edit"
    } = this.props;

    const { status } = record;
    const activated = status === "inactive" || status === "active";
    const enabled = viewType === "edit" && activated;

    return (
      <>
        {!enabled && (
          <DisabledInput
            disabled
            source="status"
            id={"status"}
            className={className}
            input={{ value: status && translate(`ui.status.${status}`) }}
          />
        )}
        {enabled && (
          <SelectInput
            source="status"
            id={"status"}
            resource={resource}
            disabled={disabled}
            record={record}
            className={className}
            choices={[
              { id: "inactive", name: "ui.status.inactive" },
              { id: "active", name: "ui.status.active" }
            ]}
            validate={required()}
          />
        )}
      </>
    );
  }
}

export default translate(StatusField);
