import React from "react";
import _get from "lodash.get";

import TextField from "@material-ui/core/TextField";

const FilterByValue = ({ filterFieldProps, integer, ...props }) => {
  const value = _get(filterFieldProps, "value");
  const { onChange } = filterFieldProps;
  const convertToInt = ({ target }) => {
    onChange({ target: { value: parseInt(target.value, 10) } });
  };

  return (
    <TextField
      {...filterFieldProps}
      onChange={integer ? convertToInt : onChange}
      value={value}
      type={integer ? "number" : undefined}
    />
  );
};

FilterByValue.defaultProps = {
  filterFieldProps: {}
};

export default FilterByValue;
