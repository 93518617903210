import React from "react";
import gql from "graphql-tag";
import _get from "lodash.get";

import * as cols from "ui/datagrid/columnTypes";
import DataList from "ui/DataList";
import ExportCSVButton from "ui/buttons/ExportCSVButton";
import Link from "ui/Link";

import VideoModalBtn from "ui/datagrid/VideoModalButton";

import usePermissions from "hooks/usePermissions";

const EXPORT_VIDEOS_CSV = gql`
  mutation exportVideos($filter: Filter) {
    exportVideos(filter: $filter) {
      jobId
      status
    }
  }
`;

const GET_TALKS = gql`
  query GetTalks(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: Filter
  ) {
    list: allVideos(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      createdAt
      id
      url
      title
      user {
        id
        firstName
        lastName
        email
        throughlineEntry {
          entry
        }
        organization {
          id
          name
        }
      }
    }
    total: _allVideosMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
    }
  }
`;

const columns = [
  { field: "lastName", ...cols.name, headerName: "headerCell.name" },
  {
    field: "createdAt",
    ...cols.date,
    headerName: "headerCell.submitted"
  },
  {
    field: "title",
    flex: 1,
    ...cols.baseCol,
    renderCell: ({ row }) => <VideoModalBtn {...row} />
  },
  {
    field: "organizationName",
    ...cols.organizationName,
    renderCell: ({ row }) => (
      <Link to={`/organizations/${_get(row, "user.organization.id")}`}>
        {_get(row, "user.organization.name", "[ no name ]")}
      </Link>
    )
  }
];

const TalksTable = ({ filter }) => {
  const { isTedAdmin, isEduOrg } = usePermissions();

  return (
    <DataList
      sortModel={[
        {
          field: "createdAt",
          sort: "desc"
        }
      ]}
      columns={columns}
      preFilter={filter}
      ExportButton={
        <ExportCSVButton
          mutation={EXPORT_VIDEOS_CSV}
          filter={filter}
          name={"talks"}
        />
      }
      query={GET_TALKS}
      showSearch={false}
      dataGrid
    />
  );
};

export default TalksTable;
