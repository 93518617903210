import React from "react";
import _get from "lodash.get";

import FilterById from "ui/filters/FilterById";

import useOrgList from "hooks/useOrgList";

const FilterByOrganization = props => {
  const { orgList } = useOrgList();

  return <FilterById options={orgList} {...props} />;
};

export default FilterByOrganization;
