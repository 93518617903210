import { buttonStyles, dropDown, palette } from "./settings";

export const MuiAutocomplete = {
  endAdornment: {
    position: "static"
  },
  popupIndicator: {
    width: 24,
    height: 24
  },
  root: {
    width: "100%"
  },
  clearIndicator: {
    width: 24,
    height: 24
  },
  inputRoot: {
    border: 0,
    fontSize: 14,
    lineHeight: 22 / 14,
    fontWeight: 700,
    padding: "0",
    width: "auto",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    "$root$hasPopupIcon &, $root$hasClearIcon &": {
      paddingRight: 0
    },

    "$root.outlined &": {
      paddingLeft: 12,
      boxSizing: "border-box",
      height: 36,
      border: buttonStyles.border,
      borderRadius: "3px"
    }
  },
  listbox: {
    width: "100%",
    padding: 0
  },
  popper: {
    position: "relative"
  },
  option: {
    fontSize: 14,
    lineHeight: 22 / 14,
    padding: "7px 12px",
    borderBottom: `1px solid ${dropDown.border}`,

    '&[aria-selected="true"]': {
      backgroundColor: dropDown.selectedBG
    },
    "&:active": {
      backgroundColor: dropDown.selectedBG
    }
  }
};
