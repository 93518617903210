import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import GroupForm from "groups/GroupForm";

import useCurrentUser from "hooks/useCurrentUser";
import useNotifcation from "hooks/useNotification";
import useTranslate from "hooks/useTranslate";

export const CREATE_GROUP = gql`
  mutation createGroup($name: String!, $organizationId: Int!) {
    createGroup(name: $name, organizationId: $organizationId) {
      id
    }
  }
`;

export const GroupCreate = ({ mutation, onSubmit }) => {
  const { activeOrgId } = useCurrentUser();
  const { translate } = useTranslate();
  const { showGraphQLError } = useNotifcation();
  const [createGroup] = useMutation(mutation, {
    onError: showGraphQLError,
    refetchQueries: ["groupsDataList"]
  });

  return (
    <GroupForm
      title={translate("groups.create")}
      onSubmit={({ name }) => {
        typeof onSubmit === "function" && onSubmit();
        createGroup({
          variables: {
            organizationId: parseInt(activeOrgId, 10),
            name
          }
        });
      }}
    />
  );
};

GroupCreate.defaultProps = {
  mutation: CREATE_GROUP
};

export default GroupCreate;
