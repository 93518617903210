import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import CreatePage from "resources/CreatePage";
import FormRow from "ui/form/FormRow";
import UserContractFields from "resources/users/userContractFields";
import UserFields from "resources/users/userFields";
import SeatLimitWarning from "ui/SeatLimitWarning";

import CreateUserActions from "./CreateUserActions";
import useCurrentUser from "hooks/useCurrentUser";
import useStylesUtil from "hooks/useStylesUtil";

export const UserCreate = ({ StandardActions, ...props }) => {
  const classes = useStylesUtil();
  const { activeOrgId } = useCurrentUser();
  const [contractId, setContractId] = useState();
  const [available, setAvailable] = useState(false);

  return (
    <CreatePage
      id="create-user-page"
      title={"resources.users.create.title"}
      defaultValue={{ organizationId: activeOrgId }}
      StandardActions={(contractId && available && StandardActions) || false}
      {...props}
    >
      <UserContractFields onChange={id => setContractId(id)} />
      <FormRow fieldBox={false}>
        <SeatLimitWarning
          className={classes.mlauto}
          contractId={contractId}
          setAvailable={setAvailable}
        />
      </FormRow>
      <UserFields
        editing
        hidden={!contractId || !available}
        viewType="create"
      />
    </CreatePage>
  );
};

UserCreate.defaultProps = {
  StandardActions: CreateUserActions
};

export default withRouter(UserCreate);
