import { dropDown, typography } from "./settings";

export const MuiList = {
  root: {
    fontFamily: typography.fontFamily
  },
  padding: {
    padding: 10
  }
};

export const MuiListItem = {
  root: {
    paddingTop: 9,
    paddingBottom: 9,
    margin: "8px 0",
    borderRadius: 4,
    fontWeight: 400
  },
  gutters: {
    paddingLeft: 10,
    paddingRight: 10
  },
  button: {
    "&:hover": {
      backgroundColor: dropDown.hoverBg,
      fontWeight: 500
    },
    "&.active": {
      backgroundColor: dropDown.selectedBG,
      fontWeight: 500
    }
  }
};
