import React, { Fragment } from "react";
import { translate } from "react-admin";

export const Message = ({ translate, message }) => {
  const lines = translate(message).split("\n");
  return (
    <>
      {lines.map((item, idx) => (
        <Fragment key={idx}>
          {item}
          {idx !== lines.length - 1 && <br />}
        </Fragment>
      ))}
    </>
  );
};

export default translate(Message);
